import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, Popover, SvgIcon } from "@mui/material";
import SuiBox from "../../SuiBox";
import SuiTypography from "../../SuiTypography";

function SubscriptionInfoCell({ gift, dual }) {
  return (
    <SuiBox display="flex" alignItems="center" sx={{ padding: 0, gap: 0.2 }}>
      {gift && <IconDisplayCell member={gift} title="Gift Receiver" type="gift" />}
      {dual && <IconDisplayCell member={dual} title="Dual" type="dual" />}
    </SuiBox>
  );
}

export function MemberCompactInfoCell({ member, title }) {
  return (
    <Card variant="outlined" sx={{ minWidth: 155, padding: 2 }}>
      <SuiTypography
        fontWeight="bold"
        textTransform="uppercase"
        sx={{ marginTop: 1, marginBottom: 1, fontSize: 10 }}
        textColor="dark"
      >
        {title}
      </SuiTypography>
      <SuiTypography variant="h6">
        {member.first_name} {member.last_name}
      </SuiTypography>
      <SuiTypography variant="body" textColor="secondary" sx={{ fontSize: 13 }}>
        {member.email}
      </SuiTypography>
      <SuiTypography variant="body" textColor="secondary" sx={{ fontSize: 13 }}>
        {member.member_id}
      </SuiTypography>
      <SuiTypography variant="body" textColor="secondary" sx={{ fontSize: 13 }}>
        {member.phone}
      </SuiTypography>
    </Card>
  );
}

export function IconDisplayCell({ member, title, type }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? type : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <SuiBox display="flex" alignItems="center">
      {type === "gift" ? (
        <GiftIcon handleClick={handleClick} />
      ) : (
        <DualIcon handleClick={handleClick} />
      )}
      <Popover
        id={type}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MemberCompactInfoCell member={member} title={title} />
      </Popover>
    </SuiBox>
  );
}

function GiftIcon({ handleClick }) {
  return (
    <SvgIcon color="primary" onClick={handleClick} sx={{ cursor: "pointer" }}>
      <path d="M18.155 13.539a2.31 2.31 0 0 0 2.309-2.308 2.31 2.31 0 0 0-2.309-2.309 2.31 2.31 0 0 0-2.308 2.309v1.923c0 .212.172.385.385.385zm-1.538-2.308c0-.85.69-1.54 1.538-1.54a1.54 1.54 0 0 1 0 3.078h-1.538Z" />
      <path d="M7.383 11.23c0 1.174.526 2.269 1.443 3.004a.384.384 0 1 0 .481-.6 3.067 3.067 0 0 1-1.154-2.403 3.081 3.081 0 0 1 3.078-3.078 3.081 3.081 0 0 1 3.077 3.078v3.077h-3.077a3.851 3.851 0 0 0-3.848 3.847 3.851 3.851 0 0 0 3.848 3.847 3.826 3.826 0 0 0 3.04-1.488.385.385 0 1 0-.608-.472 3.061 3.061 0 0 1-2.432 1.191 3.081 3.081 0 0 1-3.078-3.078 3.081 3.081 0 0 1 3.078-3.077h3.077v3.077a3.851 3.851 0 0 0 3.847 3.847 3.851 3.851 0 0 0 3.847-3.847 3.831 3.831 0 0 0-1.441-3.003.385.385 0 0 0-.482.6 3.065 3.065 0 0 1 1.154 2.403 3.081 3.081 0 0 1-3.078 3.078 3.081 3.081 0 0 1-3.077-3.078v-3.077h3.077a3.851 3.851 0 0 0 3.847-3.847 3.846 3.846 0 0 0-6.772-2.499.384.384 0 1 0 .584.5 3.077 3.077 0 1 1 2.341 5.076h-3.077v-3.077a3.851 3.851 0 0 0-3.847-3.848 3.852 3.852 0 0 0-3.848 3.848zM14.693 1.228h1.154v5.386a.385.385 0 0 0 .77 0V.844a.385.385 0 0 0-.385-.385h-1.539a.385.385 0 0 0 0 .77z" />
      <path d="M.843 12a.385.385 0 0 0 .385-.385V2.382c0-.636.518-1.154 1.154-1.154H12.77v5.386a.385.385 0 0 0 .77 0V.844a.385.385 0 0 0-.385-.385H2.382C1.322.459.46 1.322.46 2.382v9.233c0 .213.172.385.384.385ZM.843 15.078a.385.385 0 0 0 .385-.385v-1.154h5.386a.385.385 0 0 0 0-.77H.844a.385.385 0 0 0-.385.385v1.539c0 .212.172.385.384.385zM16.232 15.847a.385.385 0 0 0-.385.385v1.923a2.31 2.31 0 0 0 2.308 2.309 2.31 2.31 0 0 0 2.309-2.309 2.31 2.31 0 0 0-2.309-2.308zm3.462 2.308a1.54 1.54 0 0 1-1.539 1.54 1.54 1.54 0 0 1-1.538-1.54v-1.538h1.538c.85 0 1.54.69 1.54 1.538z" />
      <path d="M21.618.459H17.77a.385.385 0 0 0 0 .769h3.847c.636 0 1.154.518 1.154 1.154v19.236c0 .636-.518 1.154-1.154 1.154H2.382a1.156 1.156 0 0 1-1.154-1.154v-5.001h5.386a.385.385 0 0 0 0-.77H.844a.385.385 0 0 0-.385.385v5.386c0 1.06.863 1.923 1.923 1.923h19.236c1.06 0 1.923-.863 1.923-1.923V2.382c0-1.06-.863-1.923-1.923-1.923Z" />
      <path d="M13.154 13.539a.385.385 0 0 0 .385-.385v-1.923a2.31 2.31 0 0 0-2.308-2.309 2.31 2.31 0 0 0-2.309 2.309 2.31 2.31 0 0 0 2.309 2.308zM9.692 11.23c0-.85.69-1.54 1.539-1.54.848 0 1.538.69 1.538 1.54v1.538h-1.538a1.54 1.54 0 0 1-1.54-1.538ZM11.23 15.847a2.31 2.31 0 0 0-2.308 2.308 2.31 2.31 0 0 0 2.309 2.309 2.31 2.31 0 0 0 2.308-2.309v-1.923a.385.385 0 0 0-.385-.385zm1.54 2.308a1.54 1.54 0 0 1-1.54 1.54 1.54 1.54 0 0 1-1.538-1.54c0-.848.69-1.538 1.539-1.538h1.538v1.538z" />
    </SvgIcon>
  );
}

function DualIcon({ handleClick }) {
  return (
    <SvgIcon onClick={handleClick} sx={{ cursor: "pointer", width: 18, height: 18 }}>
      <path
        d="M12 .888C5.863.888.887 5.862.887 12c0 6.137 4.976 11.112 11.113 11.112S23.113 18.137 23.113 12C23.113 5.862 18.137.888 12 .888z"
        fill="#56b48c"
      />
      <path
        d="M15.212 15.472c-.18-.14-.44-.21-.78-.21h-3.426c.11-.166.216-.308.321-.426.245-.298.697-.715 1.355-1.254a44.608 44.608 0 0 0 1.408-1.184c.28-.251.554-.6.823-1.043a2.8 2.8 0 0 0 .404-1.48c0-.345-.065-.679-.194-1a2.673 2.673 0 0 0-.541-.85 2.579 2.579 0 0 0-.807-.578c-.473-.21-1.043-.315-1.713-.315-.555 0-1.04.082-1.453.246-.413.164-.755.38-1.024.647a2.708 2.708 0 0 0-.607.892 2.47 2.47 0 0 0-.203.945c0 .25.072.446.216.59a.738.738 0 0 0 .545.217c.25 0 .439-.079.568-.236.129-.158.245-.383.347-.676.103-.293.177-.472.22-.538.328-.503.779-.755 1.352-.755.271 0 .522.062.751.184.23.123.411.294.545.515.133.221.2.472.2.752 0 .258-.062.521-.187.79a3.04 3.04 0 0 1-.545.788c-.238.256-.537.51-.895.764-.202.136-.536.419-1.004.85-.468.43-.993.948-1.575 1.552-.14.148-.269.353-.387.613s-.177.47-.177.627a.87.87 0 0 0 .275.64c.184.181.44.272.768.272h4.83c.288 0 .504-.074.646-.223a.759.759 0 0 0 .213-.545c0-.24-.09-.43-.27-.57z"
        fill="#fff"
      />
    </SvgIcon>
  );
}
SubscriptionInfoCell.propTypes = {
  gift: PropTypes.object,
  dual: PropTypes.object,
};

export default SubscriptionInfoCell;
