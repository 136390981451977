import React, { useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import SuiBox from "../../../../../components/SuiBox";
import SuiTypography from "../../../../../components/SuiTypography";
import {
  getBoxCategories,
  getCategories,
  showItem,
  updateItem,
} from "../../../../../redux/reducers/shopSlice";
import form from "./schemas/form";
import SuiButton from "../../../../../components/SuiButton";
import useToastContext from "../../../../../context/ToastContext";
import FieldsWrapper from "../../../../../components/FieldsWrapper";
import validation from "./schemas/validation";

const EditItem = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showToast = useToastContext();
  const { id } = useParams();
  const { formId, formField } = form;
  const [data, setData] = useState();
  const categories = useSelector((state) => state.shopReducer.categories);
  const boxCategories = useSelector((state) => state.shopReducer.boxCategories);
  const shopSlider = useSelector((state) => state.shopReducer.shopSlider);

  const updatedFormField = useMemo(
    () => ({
      ...formField,
      group: {
        ...formField.group,
        options: boxCategories,
      },
      shopSlider: {
        ...formField.shopSlider,
        options: shopSlider,
      },
      subCategories: {
        ...formField.subCategories,
        options: [
          ...formField.subCategories.options,
          ...categories?.map((el) => ({
            label: el.name,
            value: el.id,
            subcategories: el.subcategories.map((e) => ({
              label: e.name,
              value: e.id,
            })),
          })),
        ],
      },
    }),
    [formField, boxCategories, categories, shopSlider]
  );

  useEffect(async () => {
    await dispatch(getCategories());
    const getBoxCat = await dispatch(getBoxCategories()).unwrap();
    const getShopSlider = await dispatch(getBoxCategories({ slider: true })).unwrap();

    if (id) {
      dispatch(showItem({ id }))
        .unwrap()
        .then((res) => {
          const temp = {
            ...res,
            images: res?.images?.map((el) => el.url),
            boxcategories: res?.boxcategories
              ?.map((el) => {
                if (!el.slider || el.slider === 0) {
                  return el.id.toString();
                }
                return null;
              })
              .filter((el) => el !== null),
            shopSlider: res?.boxcategories
              ?.map((el) => {
                if (el.slider === 1) {
                  return el.id.toString();
                }
                return null;
              })
              .filter((el) => el !== null),
          };
          setData(temp);
        });
    }
  }, [id]);

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const payload = {
      ...values,
      boxcategories: [...values.boxcategories, ...values.shopSlider],
      subcategories: values.subcategories.map((el) => el.id),
      shopSlider: undefined,
    };
    try {
      await dispatch(updateItem(payload)).unwrap();
      showToast({
        type: "success",
        title: "Success",
        content: `Item ${values.name} was updated successfully`,
      });
      history.push("/shop/items-list");
    } catch (e) {
      console.log(e);
      showToast({
        type: "warning",
        title: "Error",
        content: e.message,
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card p={5}>
          <SuiBox mt={6} mb={5} p={4}>
            <SuiTypography textTransform="capitalize" variant="h3" mb={3}>
              edit item
            </SuiTypography>
            {data && (
              <Formik
                initialValues={data}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={validation}
              >
                {() => (
                  <Form id={formId}>
                    <FieldsWrapper fields={Object.values(updatedFormField)} />
                    <SuiBox mt={5}>
                      <SuiButton type="submit" buttonColor="dark">
                        Save
                      </SuiButton>
                    </SuiBox>
                  </Form>
                )}
              </Formik>
            )}
          </SuiBox>
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
};

export default EditItem;
