/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPageRequest,
  createTicketRequest,
  deletePageRequest,
  getBannersRequest,
  getCarouselRequest,
  getPageRequest,
  getPagesRequest,
  orderBannersRequest,
  orderCarouselRequest,
  slugifyRequest,
  statusPageRequest,
  updatePageRequest,
  updateTicketRequest,
} from "../../http/pagesRequests";
import { addPageMeta, clearObjectKeys } from "../utils";

const initialState = {
  pages: undefined,
  pagesTotal: 0,
  pagesTotalPages: 0,
  banners: undefined,
  bannersTotal: 0,
  bannersTotalPages: 0,
  carousel: undefined,
  carouselTotal: 0,
  carouselTotalPages: 0,
  publications: [],
  status: "idle",
};

export const getPages = createAsyncThunk("cms/getPages", async (data) => {
  const res = await getPagesRequest(data);
  return res;
});

export const getBanners = createAsyncThunk("cms/getBanners", async ({ search }) => {
  const res = await getBannersRequest({ search });
  return res;
});

export const reorderBanners = createAsyncThunk("cms/reorderBanners", async ({ id, index }) => {
  const res = await orderBannersRequest({ id, index });
  return res;
});

export const getCarousel = createAsyncThunk("cms/getCarousel", async (params) => {
  const res = await getCarouselRequest({ search: params.search });
  return res;
});

export const reorderCarousel = createAsyncThunk("cms/reorderCarousel", async ({ id, index }) => {
  const res = await orderCarouselRequest({ id, index });
  return res;
});

export const getPublications = createAsyncThunk("cms/getPublications", async ({ search }) => {
  const res = await getPagesRequest({ search, type: "publication" });
  return res;
});

export const showPage = createAsyncThunk("cms/showPage", async (id) => {
  const res = await getPageRequest({ id });
  return res;
});

export const slugify = createAsyncThunk("cms/slugify", async (data) => {
  const res = await slugifyRequest(data);
  return res;
});

export const createPage = createAsyncThunk("cms/createPage", async (data) => {
  const payload = addPageMeta(data);
  const res = await createPageRequest(payload);
  return res;
});

export const updatePage = createAsyncThunk("cms/updatePage", async (data) => {
  const payload = addPageMeta(data);
  const res = await updatePageRequest({ id: payload.id, data: payload });
  return res;
});

export const deletePage = createAsyncThunk("cms/deletePage", async ({ id }) => {
  const res = await deletePageRequest({ id });
  return res;
});

export const updatePageStatus = createAsyncThunk("cms/updatePageStatus", async (data) => {
  const res = await statusPageRequest(data);
  return res;
});

export const updateTicket = createAsyncThunk("cms/updateTicket", async (data) => {
  const res = await updateTicketRequest(data);
  return res;
});

export const createTicket = createAsyncThunk("cms/createTicket", async (data) => {
  const res = await createTicketRequest(data);
  return res;
});

const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    clearCms: (state, action) => initialState,
    clearPublications: (state, action) => {
      state.publications = [];
    },
    clearShowPage: (state) => {
      state.showPage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPages.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(getBanners.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(getPages.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getBanners.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(showPage.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(reorderBanners.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(reorderCarousel.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(reorderBanners.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(reorderCarousel.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getPublications.fulfilled, (state, { payload }) => {
      state.publications = payload.data.data;
    });
    builder.addCase(reorderBanners.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.banners = payload.data.data;
    });
    builder.addCase(reorderCarousel.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.carousel = payload.data.data;
    });
    builder.addCase(showPage.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.showPage = clearObjectKeys(payload.data.data);
    });
    builder.addCase(updatePage.fulfilled, (state, { payload }) => {
      state.showPage = clearObjectKeys(payload.data.data);
    });
    builder.addCase(getPages.fulfilled, (state, { payload }) => {
      state.pages = payload?.data.data;
      state.pagesTotal = payload?.data.meta.pagination.total;
      state.pagesTotalPages = payload?.data.meta.pagination.total_pages;
      state.status = "fulfilled";
    });
    builder.addCase(getBanners.fulfilled, (state, { payload }) => {
      state.banners = payload?.data.data;
      state.bannersTotal = payload?.data.meta.pagination.total;
      state.bannersTotalPages = payload?.data.meta.pagination.total_pages;
      state.status = "fulfilled";
    });
    builder.addCase(getCarousel.fulfilled, (state, { payload }) => {
      state.carousel = payload?.data.data;
      state.carouselTotal = payload?.data.meta.pagination.total;
      state.carouselTotalPages = payload?.data.meta.pagination.total_pages;
      state.status = "fulfilled";
    });
  },
});

export const { clearCms, clearPublications, clearShowPage } = cmsSlice.actions;
export default cmsSlice.reducer;
