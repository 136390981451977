/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: { image, title, slug, label, summary, moreUrl, showHomepage, showShopBanner },
} = checkout;

export default {
  pageType: "generic",
  [image.name]: undefined,
  [title.name]: "",
  [slug.name]: "",
  [label.name]: "",
  [summary.name]: "",
  [moreUrl.name]: "",
  [showHomepage.name]: true,
  [showShopBanner.name]: true,
};
