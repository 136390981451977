import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProfileRequest, loginRequest } from "../../http/authRequests";

// const userLogoutAction = createAction("USER_LOGOUT");

const initialState = {
  userLoggedIn: false,
  token: null,
  isFetchingAccessToken: false,
  accessTokenError: false,
  user: null,
};

export const getProfile = createAsyncThunk("authReducer/getProfile", async () => {
  const res = await getProfileRequest();
  return res;
});

export const login = createAsyncThunk(
  "authReducer/login",
  async ({ username, password }, { dispatch }) => {
    const res = await loginRequest(username, password);
    return res;
  }
);
const authSlice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setUserLoggedIn: (state, action) => ({ ...state, userLoggedIn: action.payload }),
    userLogout: (state, action) => ({ ...initialState }),
    setAccessToken: (state, action) => ({ ...state, token: action.payload }),
    setFetchingAccessToken: (state, action) => ({
      ...state,
      isFetchingAccessToken: action.payload,
    }),
    setAccessTokenError: (state, action) => ({ ...state, accessTokenError: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.user = payload.data.data;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.userLoggedIn = true;
      state.token = payload.data.access_token;
    });
  },
});

export const {
  setUserLoggedIn,
  userLogout,
  setAccessToken,
  setFetchingAccessToken,
  setAccessTokenError,
} = authSlice.actions;

export default authSlice.reducer;
