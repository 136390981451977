import moment from "moment";
import { getMembersRequest, getSubscribedRequest } from "../../http/membersRequests";
import {
  setMembers,
  setMembersTotal,
  setMembersPagesTotal,
  setSubscriptions,
  setSubscriptionsTotal,
  setSubscriptionsPagesTotal,
  setMembersStatus,
} from "../reducers/membersSlice";

export const getMembers = (params) => (dispatch) => {
  dispatch(setMembersStatus("loading"));
  getMembersRequest(params)
    .then((res) => {
      dispatch(setMembers(res.data.data));
      dispatch(setMembersTotal(res?.data?.meta?.pagination?.total));
      dispatch(setMembersPagesTotal(res?.data?.meta?.pagination?.total_pages));
      dispatch(setMembersStatus("fulfilled"));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setMembersStatus("error"));
    });
};

export const getSubscriptions = (params) => (dispatch) => {
  dispatch(setMembersStatus("loading"));
  getSubscribedRequest(params)
    .then((res) => {
      const data = res.data.data.map((el) => ({
        ...el,
        date: moment(el.created).format("DD/MM/YYYY"),
      }));
      dispatch(setSubscriptions(data));
      dispatch(setSubscriptionsTotal(res?.data?.meta?.pagination?.total));
      dispatch(setSubscriptionsPagesTotal(res?.data?.meta?.pagination?.total_pages));
      dispatch(setMembersStatus("fulfilled"));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setMembersStatus("error"));
    });
};
