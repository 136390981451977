/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { DateTimeCell } from "../../../../../components/TableComponents/DefaultCell";
import { OrderActionCell } from "../../../../../components/TableComponents/ActionCell";
import { MemberInfoCompactCell } from "../../../../../components/TableComponents/Shared";
import OrderStatusCell from "../../../../../components/TableComponents/OrderStatusCell";

export default {
  columns: [
    { Header: "id", accessor: "id", width: "60px" },
    {
      Header: "name - email",
      accessor: "name",
      Cell: ({ row }) => (
        <MemberInfoCompactCell name={row.original.name} email={row.original.email} />
      ),
      width: "250px",
    },
    { Header: "total", accessor: "total" },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ row: { original } }) => <OrderStatusCell item={original} />,
    },
    {
      Header: "order date",
      accessor: "created",
      Cell: ({ value }) => <DateTimeCell value={value} />,
    },
    {
      Header: "",
      id: "actions",
      width: "50px",
      Cell: ({ row, state }) => <OrderActionCell item={row.original} state={state} />,
    },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
};
