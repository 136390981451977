export default {
  weight_threshold: "",
  shipping_costs: [
    {
      cost: "",
      zone_id: 1,
      label: "Zone 99",
    },
    {
      cost: "",
      zone_id: 2,
      label: "Zone 1",
    },
    {
      cost: "",
      zone_id: 3,
      label: "Zone 2",
    },
    {
      cost: "",
      zone_id: 4,
      label: "Zone 21",
    },
    {
      cost: "",
      zone_id: 5,
      label: "Zone 22",
    },
    {
      cost: "",
      zone_id: 6,
      label: "Zone 3",
    },
    {
      cost: "",
      zone_id: 7,
      label: "Zone 31",
    },
    {
      cost: "",
      zone_id: 8,
      label: "Zone 4",
    },
    {
      cost: "",
      zone_id: 9,
      label: "Zone 5",
    },
    {
      cost: "",
      zone_id: 10,
      label: "Zone 6",
    },
    {
      cost: "",
      zone_id: 11,
      label: "Zone 7",
    },
  ],
};
