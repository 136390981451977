/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components
import SuiBadge from "components/SuiBadge";
import { ItemsActionCell } from "../../../../../components/TableComponents/ActionCell";
import { ProductThumbnail } from "../components/ProductCell";
import SuiBox from "../../../../../components/SuiBox";
import SuiTypography from "../../../../../components/SuiTypography";

// Badges
const outOfStock = (
  <SuiBadge
    variant="contained"
    color="error"
    size="extra-small"
    badgeContent="out of stock"
    container
  />
);
const inStock = (
  <SuiBadge
    variant="contained"
    color="success"
    size="extra-small"
    badgeContent="in stock"
    container
  />
);

export default {
  columns: [
    {
      Header: "id - code",
      accessor: "id",
      Cell: ({ row }) => (
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="caption" fontWeight="bold">
            {row.original.id}
          </SuiTypography>
          <SuiTypography variant="caption">{row.original.code}</SuiTypography>
        </SuiBox>
      ),
      width: "5rem",
    },
    {
      Header: "photo",
      accessor: "featured",
      Cell: ({ row, value }) => <ProductThumbnail name={row?.original?.name} img={value} />,
      width: "4rem",
    },
    {
      Header: "name",
      accessor: "name",
      // width: "max-content",
    },
    {
      Header: "quantity",
      accessor: "quantity",
      width: "2rem",
    },
    { Header: "category", accessor: "category", width: "6rem" },
    // {
    //   Header: "final price",
    //   accessor: "pricer",
    //   width: "4rem",
    // },
    // TODO:
    //  pricer is the same as price - we use pricer because sorting needs pricer
    //  but price is everywhere in the project :P
    {
      Header: "price",
      accessor: "pricer",
      width: "4rem",
      Cell: ({ value }) => (Math.round(value * 100) / 100).toFixed(2),
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row, state }) => <ItemsActionCell item={row.original} state={state} />,
      // width: "5rem",
    },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
};
