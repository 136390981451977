/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import { Box, Card, Grid, Icon, Modal } from "@mui/material";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import SuiBox from "../../../SuiBox";
import SuiTypography from "../../../SuiTypography";
import FormField from "../../../FormField";
import SuiButton from "../../../SuiButton";
import FormDropzone from "../../../FormDropzone";
import LanguageToggle from "../../../FieldsWrapper/LanguageToggle";
import DragableList from "../../../DragableList/DragableList";
import { handleUpload } from "../../../SuiDropzone";
import SuiInput from "../../../SuiInput";

const blueprint = {
  image: "",
  image_en: "",
  image_link: "",
  image_link_en: "",
};

const ModalSponsors = ({ open, onClose, values, index }) => {
  const { setFieldValue } = useFormikContext();
  const [language, setLanguage] = useState("el");

  const handleNew = (arrayHelpers) => (image) => {
    const tempImageObject = language === "el" ? { image } : { image_en: image };
    arrayHelpers.push({ ...blueprint, ...tempImageObject });
  };

  const images = useMemo(() => values?.[index]?.images, [values, index]);

  const handleImageEnUpload = (innerIndex) => async () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const imageUrl = await handleUpload(file);
      if (imageUrl) setFieldValue(`cats[${index}].images[${innerIndex}].image_en`, imageUrl);
    };
  };

  return (
    <Modal
      keepMounted={false}
      open={open}
      onBackdropClick={onClose}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <Card sx={{ width: ["80%", "60%"], maxHeight: "90vh", overflowY: "auto" }}>
        <SuiBox p={2}>
          <SuiTypography variant="h5">Sponsor Category</SuiTypography>
        </SuiBox>

        <SuiBox display="flex" flexDirection="column" p={3}>
          <LanguageToggle language={language} setLanguage={setLanguage} />
          <FormField
            name={`cats[${index}].name${language === "en" ? "_en" : ""}`}
            label="Category Name"
          />
          <FieldArray
            name={`cats[${index}].images`}
            render={(arrayHelpers) => (
              <>
                <DragableList
                  listId={`sponsors-list-${index}`}
                  onSave={(list, from, to) => {
                    arrayHelpers.move(from, to);
                  }}
                  items={images}
                  renderItem={({ item: el, index: i, disabled }) => (
                    <SuiBox
                      sx={{
                        boxShadow: disabled
                          ? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                          : undefined,
                        borderRadius: "4px",
                      }}
                      my={2}
                      p={1}
                      backgroundColor="white"
                    >
                      <Grid key={el.image} container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={2}>
                          {language === "en" && !el.image_en ? (
                            <Box
                              maxWidth="200px"
                              maxHeight="200px"
                              bgColor="red"
                              sx={{ overflow: "hidden" }}
                            >
                              <SuiButton
                                buttonColor="info"
                                onClick={handleImageEnUpload(i)}
                                sx={{ marginTop: "20px" }}
                              >
                                Upload Image
                              </SuiButton>
                            </Box>
                          ) : (
                            <img
                              src={language === "el" ? el.image : el.image_en}
                              alt={`sponsors-img-${i}`}
                              width="100%"
                              height="auto"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm>
                          <FormField
                            name={
                              language === "el"
                                ? `cats[${index}].images[${i}].image_link`
                                : `cats[${index}].images[${i}].image_link_en`
                            }
                            label="Image Link"
                            disabled={disabled}
                            // onChange={(e) => setFieldValue(`galleries.${[i]}.caption`, e)}
                          />
                        </Grid>
                        <Grid item sm={1} mt={2}>
                          <SuiBox display="flex" alignItems="flex-end">
                            <Icon onClick={() => !disabled && arrayHelpers.remove(i)}>delete</Icon>
                          </SuiBox>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  )}
                />

                {values?.images?.length > 0 && (
                  <SuiBox
                    sx={{ alignSelf: "center", width: "80%", height: "1px" }}
                    backgroundColor="dark"
                    m={5}
                  />
                )}
                <SuiTypography>Add new item</SuiTypography>
                <SuiBox display="flex" alignItems="center" justifyContent="center" width="50%">
                  <FormDropzone getImageUrl={handleNew(arrayHelpers)} />
                </SuiBox>
                <SuiBox display="flex" justifyContent="flex-end" mt={10}>
                  <SuiButton buttonColor="dark" onClick={onClose}>
                    Save
                  </SuiButton>
                </SuiBox>
              </>
            )}
          />
        </SuiBox>
      </Card>
    </Modal>
  );
};

ModalSponsors.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalSponsors;
