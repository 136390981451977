/* eslint-disable react/prop-types */
/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useState } from "react";
import Box from "@mui/material/Box";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import {
  deletePage,
  getBanners,
  getCarousel,
  getPages,
  updatePageStatus,
} from "../../../redux/reducers/cmsSlice";
import { getItems, getOrders, syncItem, updateItemStatus } from "../../../redux/reducers/shopSlice";
import SuiButton from "../../SuiButton";
import ModalOrderInfo from "../../../layouts/pages/shop/orders/components/ModalOrderInfo";
import { deleteUser, getUsers } from "../../../redux/reducers/usersSlice";
import { getEventPages } from "../../../redux/reducers/eventsSlice";
import useToastContext from "../../../context/ToastContext";
import DeleteModal from "../../DeleteModal";

// eslint-disable-next-line react/prop-types
function ActionCell({
  onShow,
  onLink,
  onEdit,
  onDelete,
  onSync,
  active,
  onStatusChange,
  isStatic,
  wrap,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const openDeleteModal = () => setModalOpen(true);
  const handleDelete = async () => {
    await onDelete();
    setModalOpen(false);
  };
  return (
    <SuiBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap={wrap ? "wrap" : undefined}
      gap={2}
    >
      {onLink && (
        <a href={onLink} target="_blank" rel="noreferrer">
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
            onClick={onShow}
          >
            <Tooltip title="Visit" placement="top">
              <Icon className="" color="secondary">
                visibility
              </Icon>
            </Tooltip>
          </SuiTypography>
        </a>
      )}
      {onShow && (
        <SuiBox>
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
            onClick={onShow}
          >
            <Tooltip title="Show" placement="top">
              <Icon className="" color="secondary">
                visibility
              </Icon>
            </Tooltip>
          </SuiTypography>
        </SuiBox>
      )}
      {onStatusChange && !isStatic && (
        <SuiBox>
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
            onClick={() => !active && onStatusChange()}
          >
            <Tooltip title="List" placement="top">
              <Icon className="" color={!active ? "secondary" : "disabled"}>
                <PlayArrowIcon />
              </Icon>
            </Tooltip>
          </SuiTypography>
        </SuiBox>
      )}
      {onStatusChange && !isStatic && (
        <SuiBox>
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
            onClick={() => active && onStatusChange()}
          >
            <Tooltip title="Pause" placement="top">
              <Icon className="" color={active ? "secondary" : "disabled"}>
                pause
              </Icon>
            </Tooltip>
          </SuiTypography>
        </SuiBox>
      )}
      {onEdit && (
        <SuiBox>
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
            onClick={onEdit}
          >
            <Tooltip title="Edit" placement="top">
              <Icon className="">edit</Icon>
            </Tooltip>
          </SuiTypography>
        </SuiBox>
      )}
      {onDelete && !isStatic && (
        <SuiBox>
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
            onClick={openDeleteModal}
          >
            <Tooltip title="Delete" placement="left">
              <Icon className="">delete</Icon>
            </Tooltip>
          </SuiTypography>
        </SuiBox>
      )}
      {onSync && (
        <SuiBox>
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
            onClick={onSync}
          >
            <Tooltip title="Sync" placement="top">
              <Icon className="" color="secondary">
                sync
              </Icon>
            </Tooltip>
          </SuiTypography>
        </SuiBox>
      )}
      {modalOpen && (
        <DeleteModal modalOpen setModalOpen={setModalOpen} handleDelete={handleDelete} />
      )}
    </SuiBox>
  );
}

export const EventListActionCell = ({ row }) => {
  if (!row.original.page_id) return <></>;
  const history = useHistory();

  const handleShow = () => {
    history.push(`/cms/edit-page/${row.original.page_id}`);
  };
  return <ActionCell onShow={handleShow} />;
};

// eslint-disable-next-line react/prop-types
export const PageActionCell = ({ value, state, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showToast = useToastContext();

  const onEdit = () => {
    // eslint-disable-next-line react/prop-types
    history.push(`/cms/edit-page/${value.id}`);
  };

  const refresh = () => {
    if (type === "pevent") {
      dispatch(
        getEventPages({
          page: state.pageIndex + 1,
          totalPerPage: state.pageSize,
          search: state.globalFilter?.search,
        })
      );
    } else {
      dispatch(
        getPages({
          page: state.pageIndex + 1,
          totalPerPage: state.pageSize,
          search: state.globalFilter?.search,
          type,
        })
      );
    }
  };

  const onDelete = () => {
    // eslint-disable-next-line react/prop-types
    showToast({
      type: "warning",
      title: "Deleting...",
    });
    dispatch(deletePage({ id: value.id })).then(() => {
      showToast({
        type: "success",
        title: "Success",
        content: `${value.title} was deleted successfully`,
      });
      refresh();
    });
  };

  const onStatusChange = () => {
    showToast({
      type: "warning",
      title: "Changing status...",
    });
    const newStatus = value.active ? 0 : 1;
    dispatch(updatePageStatus({ id: value.id, status: newStatus })).then(() => refresh());
  };
  return (
    <ActionCell
      onEdit={onEdit}
      onDelete={onDelete}
      active={value.active}
      onStatusChange={onStatusChange}
      static={value.static}
    />
  );
};

export const ItemsActionCell = ({ item, state }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showToast = useToastContext();

  const refresh = async () => {
    await dispatch(
      getItems({
        page: state.pageIndex + 1,
        totalPerPage: state.pageSize,
        search: state.globalFilter?.search,
        sort: state.sortBy?.[0]?.id,
        order: state.sortBy?.[0]?.desc === false ? "asc" : "desc",
      })
    ).unwrap();
  };
  const onEdit = () => {
    history.push(`/shop/edit-item/${item.id}`);
  };

  const onStatusChange = async () => {
    showToast({
      type: "info",
      title: item.status ? "Deactivating item..." : "Activating item...",
    });
    try {
      const res = await dispatch(
        updateItemStatus({ id: item.id, active: item.active ? 0 : 1 })
      ).unwrap();

      if (res.data.errors || res.data.message) {
        showToast({
          type: "warning",
          title: res.data.errors || res.data.message,
        });
      } else {
        showToast({
          type: "success",
          title: "Item status changed successfully!",
        });
        refresh();
      }
    } catch (e) {
      showToast({
        type: "error",
        title: "There was an error",
        content: e?.message || e || "",
      });
    }
  };

  const handleSync = async () => {
    try {
      await dispatch(syncItem(item.id)).unwrap();
      showToast({
        type: "success",
        title: "Item synced successfully!",
      });
      await dispatch(
        getItems({
          page: state.pageIndex + 1,
          totalPerPage: state.pageSize,
          search: state.globalFilter?.search,
          sort: state.sortBy?.[0]?.id,
          order: state.sortBy?.[0]?.desc === false ? "asc" : "desc",
        })
      ).unwrap();
    } catch (e) {
      showToast({
        type: "error",
        title: "There was an error",
      });
    }
  };

  return (
    <ActionCell
      onEdit={onEdit}
      onLink={`${process.env.REACT_APP_EXTERNAL_URL}/shop/item/${item.id}`}
      active={item.active}
      onStatusChange={onStatusChange}
      onSync={handleSync}
    />
  );
};

export const OrderActionCell = ({ item, state, ticket = false }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(null);

  const refresh = () =>
    dispatch(
      getOrders({
        page: state.pageIndex + 1,
        totalPerPage: state.pageSize,
        search: state.globalFilter?.search,
        sort: state?.sortBy?.[0]?.id ?? undefined,
        order: state?.sortBy?.[0]?.desc === false ? "asc" : undefined,
        [state?.customFiltersOptions?.key]: state?.globalFilter?.customFilters,
      })
    );

  // const onDelete = async () => {
  //   showToast({
  //     type: "warning",
  //     title: "Deleting...",
  //   });
  //   try {
  //     await dispatch(deleteOrder({ id: item.id })).unwrap();
  //     showToast({
  //       type: "success",
  //       title: "Success",
  //       content: `Order ${item.id} was deleted successfully`,
  //     });
  //   } catch (e) {
  //     showToast({
  //       type: "error",
  //       title: "There was an error",
  //       content: e?.message || e || "",
  //     });
  //   } finally {
  //     refresh();
  //   }
  // };

  const onShow = () => {
    setShowModal(item.id);
  };

  const handleCloseModal = () => {
    setShowModal(null);
    refresh();
  };

  return (
    <>
      <ActionCell
        onShow={onShow}
        // onEdit={onEdit}
        // onDelete={onDelete}
        // active={value.active}
        // onStatusChange={onStatusChange}
      />
      <ModalOrderInfo
        open={!!showModal}
        onClose={handleCloseModal}
        id={showModal}
        ticket={ticket}
      />
    </>
  );
};

export const UsersActionCell = ({ row }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleEdit = () => history.push(`/edit-user/${row.original.id}`, { data: row.original });
  const handleDelete = async () => {
    await dispatch(deleteUser({ id: row?.original?.id })).unwrap();
    await dispatch(getUsers());
  };

  return <ActionCell onEdit={handleEdit} onDelete={handleDelete} />;
};

export const BannersActionCell = ({ value, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showToast = useToastContext();

  const onEdit = () => {
    if (type === "banner") {
      history.push(`/cms/edit-banner/${value.id}`);
    } else if (type === "carousel") {
      history.push(`/cms/edit-slider/${value.id}`);
    }
  };

  const refresh = () => {
    if (type === "banner") {
      dispatch(getBanners());
    } else if (type === "carousel") {
      dispatch(getCarousel({}));
    }
  };

  const onDelete = async () => {
    await dispatch(deletePage({ id: value.id })).unwrap();
    showToast({
      type: "success",
      title: "Success",
      content: `${value.title} was deleted successfully`,
    });
    refresh();
  };

  const onStatusChange = () => {
    const newStatus = value.active ? 0 : 1;
    dispatch(updatePageStatus({ id: value.id, status: newStatus })).then(() => refresh());
  };

  return (
    <ActionCell
      onEdit={onEdit}
      onDelete={onDelete}
      active={value.active}
      onStatusChange={onStatusChange}
      wrap
    />
  );
};

export const ButtonsActionCell = ({ row, handleSaveRow, state }) => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    await handleSaveRow(row.original, "delete", state);
    row.setState((e) => ({ ...e, isEditable: false }));
    setLoading(false);
  };
  const handleSave = async () => {
    setLoading(true);
    await handleSaveRow(row.original, null, state);
    row.setState((e) => ({ ...e, isEditable: false }));
    setLoading(false);
  };
  return (
    <>
      {row.state.isEditable ? (
        <SuiBox display="flex">
          <SuiBox mr={1}>
            <SuiButton size="small" buttonColor="dark" onClick={handleSave} disabled={loading}>
              {loading ? "saving..." : "save"}
            </SuiButton>
          </SuiBox>
          <SuiButton
            size="small"
            buttonColor="light"
            disabled={loading}
            onClick={() => row.setState((e) => ({ ...e, isEditable: false }))}
          >
            cancel
          </SuiButton>
        </SuiBox>
      ) : (
        <SuiBox display="flex">
          <SuiBox mr={1}>
            <SuiButton
              size="small"
              buttonColor="dark"
              variant="gradient"
              onClick={() => row.setState((e) => ({ ...e, isEditable: true }))}
              mr={1}
              disabled={loading}
            >
              edit
            </SuiButton>
          </SuiBox>

          <SuiButton
            size="small"
            buttonColor="light"
            variant="gradient"
            onClick={() => setModalOpen(true)}
            disabled={loading}
          >
            delete
          </SuiButton>
        </SuiBox>
      )}
      <DeleteModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        loading={loading}
        handleDelete={handleDelete}
      />
    </>
  );
};

export const DraggableCell = ({ filter, ...dragHandleProps }) => (
  <Box _cursor="pointer" sx={{ opacity: filter ? 0.4 : 1 }} {...dragHandleProps}>
    <Tooltip title={filter ? "Cannot reorder while searching" : ""} placement="top">
      <Icon>
        <OpenWithIcon />
      </Icon>
    </Tooltip>
  </Box>
);

export default ActionCell;
