/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { Form, Formik, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@mui/material";
import WidgetCollapse from "../../Collapse/WidgetCollapse";
import FormField, { DropdownFormField, EditorFormField } from "../../FormField";
import SuiBox from "../../SuiBox";
import initialValues from "./schema/initialValues";
import form from "./schema/form";
import SuiButton from "../../SuiButton";
import SuiTypography from "../../SuiTypography";
import { createTicket, updateTicket } from "../../../redux/reducers/cmsSlice";
import LanguageToggle from "../../FieldsWrapper/LanguageToggle";

const empty = {
  data: undefined,
  order: undefined,
  type: undefined,
};

// eslint-disable-next-line react/prop-types
const TicketWidget = ({ widget = empty, onSubmit, onDelete, disabled, page }) => {
  const dispatch = useDispatch();
  const { data, order, type } = widget;
  const { formId, formField } = form;
  const s1SeriesOptions = useSelector((state) => state.optionsReducer.s1Series);
  const s1ServiceCodesOptions = useSelector((state) => state.optionsReducer.s1ServiceCodes);
  const subscriptionOptions = useSelector((state) => state.optionsReducer.subscriptions);

  const [language, setLanguage] = useState("el");
  const [s1SeriesValue, setS1SeriesValue] = useState(data?.s1_series || initialValues.s1_series);
  const [filteredSubs, setFilteredSubs] = useState([
    { label: "Select One", value: "" },
    ...subscriptionOptions,
  ]);

  const { title, menuTitle, description, eventPage, s1Series, s1ServiceCode, options } = formField;

  const formRef = useRef();

  useEffect(() => {
    if (subscriptionOptions.length > 0) {
      setFilteredSubs([{ label: "Select One", value: "" }, ...subscriptionOptions]);
    }
  }, [subscriptionOptions]);

  const handleSubmit = async (values, actions) => {
    const submit = (temp) => {
      onSubmit(
        {
          widget: {
            data: temp,
            order: order ?? null,
            type: type ?? null,
          },
          widgetId: values.id ?? null,
        },
        actions
      );
    };

    actions.setSubmitting(true);
    const ticketPayload = {
      ...values,
      page_id: page?.id,
      soevent_id: page?.soevent_id,
      tickets: values.options,
      s1_series: values.s1_series?.value ? values.s1_series.value : values.s1_series,
    };

    if (!ticketPayload?.tickets?.some((el) => el.id)) {
      const res = await dispatch(createTicket(ticketPayload)).unwrap();
      const temp = { ...ticketPayload, options: [...res.data.data] };
      submit(temp);
    } else {
      const res = await dispatch(updateTicket(ticketPayload)).unwrap();
      const temp = { ...values, options: [...res.data.data] };
      submit(temp);
    }
  };

  const handleDelete = (values) => {
    onDelete({ data: values, order: order ?? null, type: type ?? null });
  };

  const getS1ServiceCodesOptions = useMemo(() => {
    switch (s1SeriesValue?.id ?? s1SeriesValue.value ?? s1SeriesValue) {
      case "4220":
        return [{ value: 13246, label: s1ServiceCodesOptions[13246] }];
      case "4221":
        return [
          { value: 12512, label: s1ServiceCodesOptions[12512] },
          { value: 13880, label: s1ServiceCodesOptions[13880] },
        ];
      case "4224":
        return [{ value: 27056, label: s1ServiceCodesOptions[27056] }];
      case "4231":
        return [
          { value: 12512, label: s1ServiceCodesOptions[12512] },
          { value: 13880, label: s1ServiceCodesOptions[13880] },
        ];
      default:
        return [{ label: "Select", value: "" }];
    }
  }, [s1SeriesValue, s1ServiceCodesOptions]);

  return (
    <Formik
      initialValues={data || initialValues}
      // validationSchema={currentValidation}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue, setSubmitting }) => (
        <Form id={formId} autoComplete="off">
          <WidgetCollapse
            title={`Ticket Widget${values.title ? ` - ${values.title}` : ""}`}
            disabled={disabled}
            onDelete={() => handleDelete(values, setSubmitting)}
          >
            <SuiBox mt={1.625}>
              <LanguageToggle language={language} setLanguage={setLanguage} />

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={title.type}
                    label={title.label}
                    name={language === "en" ? `${title.name}_en` : title.name}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormField
                    type={menuTitle.type}
                    label={menuTitle.label}
                    name={language === "en" ? `${menuTitle.name}_en` : menuTitle.name}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <EditorFormField
                    type={description.type}
                    label={description.label}
                    name={language === "en" ? `${description.name}_en` : description.name}
                  />
                </Grid>
                {/* FIXME: Dropdown search for event pages - might need tweeking before production */}
                {/* <Grid item xs={12} sm={12}> */}
                {/*  <SearchDropdownFormField */}
                {/*    label={eventPage.label} */}
                {/*    name={eventPage.name} */}
                {/*    value={values.eventPage} */}
                {/*    setValue={(e) => { */}
                {/*      setFieldValue(eventPage.name, e); */}
                {/*    }} */}
                {/*    getOptions={eventPage.getOptions} */}
                {/*    defaultOptions={false} */}
                {/*    cacheOptions={false} */}
                {/*  /> */}
                {/* </Grid> */}
                <Grid item xs={12} sm={6}>
                  <DropdownFormField
                    options={s1SeriesOptions}
                    label={s1Series.label}
                    name={s1Series.name}
                    value={values[s1Series.name]}
                    defaultValue={{
                      label: "4220 - ΠΑΡΑΓΓΕΛΙΑ ΣΥΜΜΕΤΟΧΗ ΣΕ ΔΙΑΛΕΞΗ",
                      value: "4220",
                    }}
                    setValue={(e) => {
                      setFieldValue(s1Series.name, e.value);
                      setS1SeriesValue(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropdownFormField
                    options={getS1ServiceCodesOptions}
                    label={s1ServiceCode.label}
                    name={s1ServiceCode.name}
                    value={values[s1ServiceCode.name]}
                    setValue={(e) => {
                      setFieldValue(s1ServiceCode.name, e.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SuiBox mb={2}>
                    <SuiTypography component="label" variant="h6" fontWeight="bold">
                      Tickets
                    </SuiTypography>
                  </SuiBox>
                  <FieldArray name="options">
                    {(arrayHelpers) => (
                      <Grid>
                        {values?.options?.map((el, i) => (
                          <Grid container spacing={3} key={`ticket-item-${i}`}>
                            <Grid item xs={12} sm={6}>
                              <DropdownFormField
                                exactValue
                                label="subscription"
                                name="subscription"
                                value={el.subscription_code ?? { label: "Select One", value: "" }}
                                options={filteredSubs}
                                setValue={(e) => {
                                  setFieldValue(`options[${i}].subscription`, e.label);
                                  setFieldValue(`options[${i}].subscription_code`, e.value);
                                  setFieldValue(`options[${i}].subscription_mtrl`, e.mtrl ?? 0);
                                  if (e.value === 0) {
                                    setFieldValue(`options[${i}].label`, "");
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <FormField
                                type="text"
                                label="price (€)"
                                name={`options[${i}].price`}
                                // value={values.options[i].price}
                                // onChange={(e) => setFieldValue(`options[${i}].price`, e)}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={1}
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-end"
                              pb={1.5}
                            >
                              <SuiTypography onClick={() => arrayHelpers.remove(i)}>
                                <Icon>close</Icon>
                              </SuiTypography>
                            </Grid>
                            {values.options[i].subscription_code === 0 ? (
                              <Grid item xs={12} md={6} mt={-3}>
                                <FormField
                                  type="text"
                                  label="Label"
                                  name={
                                    language === "en"
                                      ? `options[${i}].label_en`
                                      : `options[${i}].label`
                                  }
                                  // value={values.options[i].label}
                                  // onChange={(e) =>
                                  //   setFieldValue(
                                  //     language === "en"
                                  //       ? `options[${i}].label_en`
                                  //       : `options[${i}].label`,
                                  //     e
                                  //   )
                                  // }
                                />
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        ))}
                        <SuiBox mt={4}>
                          <SuiTypography
                            component="label"
                            variant="h6"
                            onClick={() =>
                              arrayHelpers.push({
                                subscription: "",
                                subscription_code: "",
                                price: "",
                              })
                            }
                          >
                            + Add Ticket
                          </SuiTypography>
                        </SuiBox>
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
              <SuiBox mt={5}>
                <SuiButton
                  disabled={isSubmitting}
                  variant="gradient"
                  buttonColor="dark"
                  type="submit"
                >
                  save
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </WidgetCollapse>
        </Form>
      )}
    </Formik>
  );
};

export default TicketWidget;
