import axiosInstanceProxy from "./config";

export const getShippingRequest = ({ search, sort, order, totalPerPage, status, closed }) =>
  axiosInstanceProxy.get("/admin/shop/shipping", {
    params: {
      search,
      sort,
      order,
      totalPerPage,
      status,
      closed,
    },
  });

export const createShippingRequest = (values) =>
  axiosInstanceProxy.post("/admin/shop/shipping/create", values);

export const updateShippingRequest = (values) =>
  axiosInstanceProxy.post("/admin/shop/shipping/update", values);

export const deleteShippingRequest = (value) =>
  axiosInstanceProxy.post("/admin/shop/shipping/destroy", { weight_threshold: value });
