import Basic from "./layouts/authentication/sign-in/basic";
import NewMember from "./layouts/pages/members/members-list/newMember";
import NewEvent from "./layouts/pages/events/events-list/new-event";
import NewPage from "./layouts/pages/cms/pages/new-page";
import NewBanner from "./layouts/pages/cms/Banners/edit-banner-generic";
import UserPage from "./layouts/pages/settings/profile";
import UsersPage from "./layouts/pages/settings/users";
import EditPage from "./layouts/pages/cms/pages/edit-page";
import EditItem from "./layouts/pages/shop/items-list/edit-item";

const otherRoutes = [
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: Basic,
  },
  {
    name: "New Member",
    key: "new",
    route: "/members/new",
    component: NewMember,
  },
  {
    name: "New Event",
    key: "new-event",
    route: "/events/new-event/:id",
    component: NewEvent,
  },
  {
    name: "New Page",
    key: "new-page",
    route: "/cms/new-page",
    component: NewPage,
  },
  {
    name: "Edit Page",
    key: "edit-page",
    route: "/cms/edit-page/:id",
    component: EditPage,
  },
  {
    name: "New Banner",
    key: "new-banner",
    route: "/cms/new-banner",
    component: NewBanner,
  },
  {
    name: "New Banner",
    key: "new-banner",
    route: "/cms/edit-banner/:id",
    component: NewBanner,
  },
  {
    name: "New Slider",
    key: "new-slider",
    route: "/cms/new-slider",
    component: NewBanner,
  },
  {
    name: "New Slider",
    key: "new-slider",
    route: "/cms/edit-slider/:id",
    component: NewBanner,
  },
  {
    name: "Users",
    key: "users",
    route: "/users",
    component: UsersPage,
  },
  {
    name: "New User",
    key: "new-user",
    route: "/new-user",
    component: UserPage,
  },
  {
    name: "Edit User",
    key: "edit-user",
    route: "/edit-user/:id",
    component: UserPage,
  },
  {
    name: "Profile",
    key: "profile",
    route: "/profile",
    component: UserPage,
  },
  {
    name: "Edit Item",
    key: "edit-item",
    route: "/shop/edit-item/:id",
    component: EditItem,
  },
];

export default otherRoutes;
