import checkout from "./form";

const {
  formField: { title, description, subscription },
} = checkout;

export default {
  [title.name]: "",
  [`${title.name}_en`]: "",
  [description.name]: "",
  [`${description.name}_en`]: "",
  [subscription.name]: { value: null, label: "Select" },
};
