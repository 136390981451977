/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useCallback } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import Checkbox from "@mui/material/Checkbox";
import SuiInput from "../../SuiInput";
import SuiSelect from "../../SuiSelect";
import SuiBox from "../../SuiBox";

function DefaultCell({ value, suffix }) {
  return (
    <SuiTypography variant="caption" fontWeight="medium" textColor="text">
      {value}
      {suffix && (
        <SuiTypography variant="caption" fontWeight="medium" textColor="secondary">
          &nbsp;&nbsp;{suffix}
        </SuiTypography>
      )}
    </SuiTypography>
  );
}

export const DateCell = ({ value, suffix }) => (
  <SuiTypography variant="caption" fontWeight="medium" textColor="text">
    {new Date(value).toLocaleDateString("el-GR", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })}
    {suffix && (
      <SuiTypography variant="caption" fontWeight="medium" textColor="secondary">
        &nbsp;&nbsp;{suffix}
      </SuiTypography>
    )}
  </SuiTypography>
);
export const DateTimeCell = ({ value, suffix }) => (
  <SuiBox display="flex" flexDirection="column">
    <DateCell value={value} suffix={suffix} />
    <SuiTypography sx={{ fontSize: 11 }} textColor="secondary">
      {new Date(value).toLocaleTimeString("el-GR", { hour: "2-digit", minute: "2-digit" })}
    </SuiTypography>
  </SuiBox>
);

export const EditableCell = ({
  initialValue,
  row: { index, state },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  type = "text",
  options,
  styleProps,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  // We'll only update the external data when the input is blurred
  const onBlur = useCallback(() => {
    if (value === "" && initialValue) {
      setValue(initialValue);
      return;
    }
    const tempValue = type === "number" ? parseFloat(value) : value;
    updateMyData(index, id, tempValue);
  }, [initialValue, value, updateMyData, index, id]);

  const onDropdownSelect = useCallback(
    (e) => {
      updateMyData(index, id, e);
    },
    [initialValue, value, updateMyData, index, id]
  );

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const renderInput = useCallback(() => {
    switch (type) {
      case "text":
      case "number":
        return <SuiInput type={type} value={value} onChange={onChange} onBlur={onBlur} />;
      case "dropdown":
        return (
          <SuiSelect
            defaultValue={{ label: initialValue, value: initialValue }}
            options={options}
            onChange={(e) => {
              onDropdownSelect(e.value);
            }}
          />
        );
      default:
        return <SuiInput value={value} onChange={onChange} onBlur={onBlur} />;
    }
  }, [type, value]);

  return (
    <>
      {state.isEditable ? (
        renderInput()
      ) : (
        <SuiTypography variant="caption" fontWeight="medium" textColor="text" {...styleProps}>
          {value}
        </SuiTypography>
      )}
    </>
  );
};

export const CheckboxCell = ({ value, updateValue, row: { index, state }, column: { id } }) => {
  const onChange = (e) => {
    updateValue(index, id, e.target.checked);
  };
  return (
    <SuiBox sx={{ width: "20px", height: "20px" }}>
      <Checkbox checked={value} onChange={onChange} disabled={!state.isEditable} />
    </SuiBox>
  );
};

// Setting default values for the props of DefaultCell
DefaultCell.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DefaultCell;
