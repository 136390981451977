import axios from "axios";
import { getStore } from "../redux/store";
import { setAccessToken, setFetchingAccessToken, userLogout } from "../redux/reducers/authSlice";

axios.defaults.baseURL = process.env.REACT_APP_MAIN_URL;

const axiosInstanceProxy = axios.create();

export const axiosLoginProxy = axios.create();

const refreshRequest = () => axiosInstanceProxy.post("/admin/auth/refresh");

axiosLoginProxy.interceptors.request.use(
  async (config) => config,
  (error) => Promise.reject(error.response)
);

axiosLoginProxy.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(new Error(error.response.data.error))
);

axiosInstanceProxy.interceptors.request.use(
  async (config) => {
    const { store } = getStore();
    if (!store) {
      return config;
    }
    const { token } = store.getState().authReducer;

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error.response)
);

axiosInstanceProxy.interceptors.response.use(
  (response) => response,
  (error) => {
    // handle the response error
    const { config, response } = error;
    const originalRequest = config;

    if (response?.status === 401 && !config?.url?.includes("refresh")) {
      const { store } = getStore();
      if (
        !store.getState().authReducer.isFetchingAccessToken &&
        !store.getState().authReducer.accessTokenError
      ) {
        store.dispatch(setFetchingAccessToken(true));
        refreshRequest()
          .then((res) => {
            store.dispatch(setAccessToken(res.data.access_token));
            store.dispatch(setFetchingAccessToken(false));
          })
          .catch((err) => {
            // console.log(err);
            store.dispatch(setFetchingAccessToken(false));
            store.dispatch(userLogout());
          });
        return axiosInstanceProxy(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstanceProxy;
