export default {
  formId: "text-widget",
  formField: {
    featured: {
      name: "featured",
      label: "Title Image",
      type: "featured",
    },
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    menuTitle: {
      name: "menu_title",
      label: "Menu Title",
      type: "text",
    },
    infoBox: {
      name: "info_box",
      label: "Info Box",
      type: "checkbox",
    },
    collapsableBox: {
      name: "collapsable_box",
      label: "Collapsable Box",
      type: "checkbox",
    },
    text1: {
      name: "text1",
      label: "text",
      type: "html",
      errorMsg: "",
    },
    column: {
      name: "column",
      label: "2 columns",
      type: "checkbox",
    },
    text2: {
      name: "text2",
      label: "text (second column)",
      type: "html",
      errorMsg: "",
      dependant: "column",
    },
    bottomLinkText: {
      name: "bottom_link_text",
      label: "bottom link label",
      type: "text",
      errorMsg: "",
    },
    bottomLinkUrl: {
      name: "bottom_link_url",
      label: "bottom link url",
      type: "text",
      errorMsg: "",
    },
    mapAddress: {
      name: "map_address",
      label: "map address",
      type: "text",
      placeholder: "",
      errorMsg: "",
    },
  },
};
