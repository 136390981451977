/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Skeleton } from "@mui/material";
import { toTitleCase } from "../../../../../../../redux/utils";

const BillingInformation = ({ data, loading, invoice = false }) => {
  const name =
    data?.first_name && data?.last_name
      ? `${data?.first_name} ${data?.last_name} `
      : "*Same as Billing Name";
  const RowItem = ({ value }) => (
    <SuiBox mb={1} lineHeight={0} display="flex">
      <SuiTypography variant="caption" textColor="text" textTransform="capitalize">
        {value?.replace("_", " ")}:&nbsp;&nbsp;&nbsp;
        {!loading && (
          <SuiTypography variant="caption" fontWeight="medium" textTransform="capitalize">
            {data?.[value]}
          </SuiTypography>
        )}
      </SuiTypography>
      {loading && <Skeleton height={20} width={100} />}
    </SuiBox>
  );
  return (
    <>
      <SuiTypography variant="h6" fontWeight="medium">
        {loading ? <Skeleton width={150} height={30} /> : `${toTitleCase(data?.type)} Information`}
      </SuiTypography>
      <SuiBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        backgroundColor="grey-100"
        borderRadius="lg"
        p={3}
        mt={2}
      >
        <SuiBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
          <SuiBox mb={2}>
            <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {!loading ? name : <Skeleton />}
            </SuiTypography>
          </SuiBox>
          <RowItem value="phone" />
          <RowItem value="address" />
          <RowItem value="district" />
          <RowItem value="city" />
          <RowItem value="country" />
          <RowItem value="zip" />

          {invoice && (
            <SuiBox mt={2}>
              <RowItem value="company" />
              <RowItem value="vat_id" />
              <RowItem value="tax_office" />
            </SuiBox>
          )}
        </SuiBox>
      </SuiBox>
    </>
  );
};

export default BillingInformation;
