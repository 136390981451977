/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FieldArray } from "formik";
import { Icon, IconButton, Divider } from "@mui/material";
import SuiBox from "../../../SuiBox";
import SuiTypography from "../../../SuiTypography";
import SuiButton from "../../../SuiButton";
import ModalSponsors from "./ModalSponsors";
import { sponsorsEmptyCategory } from "../schema/initialValues";

function SponsorImages({ value }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [catsIndex, setCatsIndex] = useState(null);

  const handleNew =
    ({ push }) =>
    async () => {
      await push(sponsorsEmptyCategory);
      setCatsIndex(value?.length);
      setModalOpen(true);
    };

  const handleEdit = (index) => () => {
    setCatsIndex(index);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCatsIndex(null);
  };
  return (
    <>
      <SuiTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >
        Categories
      </SuiTypography>
      <FieldArray name="cats">
        {(arrayHelpers) => (
          <>
            {value.map((cat, i) => (
              <SuiBox width="100%">
                <Divider />
                <SuiBox display="flex" alignItems="center">
                  <SuiTypography variant="h6">{cat.name}</SuiTypography>
                  <IconButton size="small" sx={{ marginLeft: 1 }} onClick={handleEdit(i)}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    size="small"
                    sx={{ marginLeft: 1 }}
                    onClick={() => arrayHelpers.remove(i)}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </SuiBox>
                {cat.images?.map((image) => (
                  <img
                    src={image.image}
                    alt={image.image}
                    width="80px"
                    height="80px"
                    style={{ objectFit: "contain", margin: "10px" }}
                  />
                ))}
              </SuiBox>
            ))}
            <Divider margin={2} />

            <SuiButton onClick={handleNew(arrayHelpers)}>Add Category</SuiButton>
          </>
        )}
      </FieldArray>
      <ModalSponsors open={modalOpen} onClose={handleClose} index={catsIndex} values={value} />
    </>
  );
}

export default SponsorImages;
