/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { Card, Grid, Modal } from "@mui/material";
import { Form, Formik } from "formik";
import SuiBox from "../../SuiBox";
import SuiTypography from "../../SuiTypography";
import FieldsWrapper from "../../FieldsWrapper";
import SuiButton from "../../SuiButton";

const WidgetSpecialModal = ({
  open,
  setOpen,
  onSave,
  existingValues,
  clearInitialValues,
  schema,
}) => {
  const { formField, initialValues } = schema;
  const formikRef = useRef(null);

  const onClose = () => {
    formikRef?.current?.resetForm();
    setOpen(false);
    clearInitialValues();
  };

  const handleSubmit = (values) => {
    onSave(values);
    onClose();
  };

  return (
    <Modal
      open={open}
      onBackdropClick={() => setOpen(false)}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // overflowY: "auto",
        // maxHeight: "70vh",
      }}
    >
      <Grid item xs={10} md={6}>
        <Card className="h-100">
          <Formik
            initialValues={existingValues ?? initialValues}
            onSubmit={handleSubmit}
            innerRef={formikRef}
            enableReinitialize
          >
            {() => (
              <Form id="page-widget-modal">
                <SuiBox p={2}>
                  <SuiTypography variant="h5" textTransform="capitalize" mb={2}>
                    add page
                  </SuiTypography>
                  <SuiBox sx={{ maxHeight: "70vh", overflow: "auto", paddingY: 1 }}>
                    <FieldsWrapper fields={Object.values(formField)} />
                  </SuiBox>
                </SuiBox>
                <SuiBox p={2} display="flex" justifyContent="flex-end">
                  <SuiBox>
                    <SuiButton onClick={onClose} buttonColor="secondary">
                      Cancel
                    </SuiButton>
                  </SuiBox>
                  <SuiBox ml={2}>
                    <SuiButton type="submit" buttonColor="dark">
                      {existingValues ? "Save" : "Add"}
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Modal>
  );
};

export default WidgetSpecialModal;
