/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// NewUser page components
import { FieldArray } from "formik";
import { Icon } from "@mui/material";
import React, { useCallback, useState } from "react";
import SuiButton from "../../../../../components/SuiButton";
import FormField from "../../../../../components/FormField";
import DragableList from "../../../../../components/DragableList/DragableList";
import LanguageToggle from "../../../../../components/FieldsWrapper/LanguageToggle";

function CategoriesForm({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { name, subcategory } = formField;
  const { name: nameV, subcategories: subcategoriesV } = values;

  const [language, setLanguage] = useState("el");

  const renderItem = useCallback(
    ({ item: e, index: i, disabled, itemProps }) => (
      <SuiBox mt={1.625}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <SuiBox display="flex">
              <SuiBox flexGrow={1}>
                <FormField
                  type="text"
                  label={`${subcategory.label}`}
                  name={`subcategories[${i}].name${language === "en" ? "_en" : ""}`}
                />
              </SuiBox>
              <SuiBox mt={1} ml={2} onClick={() => itemProps.arrayHelpers.remove(i)}>
                <Icon>close</Icon>
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    ),
    [language]
  );

  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h5" fontWeight="bold">
          Add Category
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <LanguageToggle language={language} setLanguage={setLanguage} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              type="text"
              label="Category Name"
              name={`${name.name}${language === "en" ? "_en" : ""}`}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <FieldArray
        name={subcategory.name}
        render={(arrayHelpers) => {
          const onReorder = async (id, startIndex, endIndex, list) => {
            list.forEach((el, i) => setFieldValue(`subcategories[${el.order}].order`, i));
            arrayHelpers.move(startIndex, endIndex);
          };
          return (
            <SuiBox>
              <DragableList
                renderItem={renderItem}
                items={subcategoriesV}
                itemProps={{ arrayHelpers, values }}
                onSave={onReorder}
                showSwitch={false}
                showOverlay={false}
                sortBy="order"
                sx={{
                  overflowY: "auto",
                  maxHeight: "50vh",
                  border: `1px solid ${subcategoriesV?.length > 0 ? "lightGrey" : "white"}`,
                  borderRadius: "8px",
                }}
              />
              <SuiBox mt={4}>
                <SuiButton
                  onClick={() =>
                    arrayHelpers.push({
                      name: "",
                      name_en: "",
                      order: subcategoriesV?.length || 0,
                    })
                  }
                  buttonColor="dark"
                >
                  Add Subcategory
                </SuiButton>
              </SuiBox>
            </SuiBox>
          );
        }}
      />
    </SuiBox>
  );
}

// typechecking props for UserInfo
CategoriesForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CategoriesForm;
