import checkout from "./form";

const {
  formField: { title, menuTitle, sponsor },
} = checkout;

export default {
  [title.name]: "",
  [`${title.name}_en`]: "",
  [menuTitle.name]: "",
  [`${menuTitle.name}_en`]: "",
  [sponsor.name]: [],
};

export const sponsorsEmptyCategory = {
  images: [],
  name: "",
  name_en: "",
};
