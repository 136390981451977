/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import SuiInput from "../SuiInput";
import SuiSelect from "../SuiSelect";
import SuiTypography from "../SuiTypography";

const EditableCell = ({
  initialValue,
  row: { index, state },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  type = "text",
  options,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = useCallback((e, ee) => {
    if (type === "number") {
      setValue(parseFloat(e.target.value));
      return;
    }
    setValue(e);
  }, []);

  // We'll only update the external data when the input is blurred
  const onBlur = useCallback(
    (e) => {
      if (value === "" && initialValue) {
        setValue(initialValue);
        return;
      }
      updateMyData(index, id, e ?? value);
    },
    [initialValue, value, updateMyData, index, id]
  );

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const renderInput = useCallback(() => {
    switch (type) {
      case "text":
      case "number":
        return (
          <SuiInput
            sx={{ padding: "6px" }}
            type={type}
            value={value}
            onChange={onChange}
            onBlur={() => onBlur()}
          />
        );
      case "dropdown":
        return (
          <SuiSelect
            defaultValue={{ label: initialValue, value: initialValue }}
            options={options}
            onChange={(e) => {
              onChange(e.value);
              onBlur(e.value);
            }}
          />
        );
      default:
        return <SuiInput value={value} onChange={onChange} onBlur={onBlur} />;
    }
  }, [type, value]);
  return (
    <>
      {state?.isEditable ? (
        renderInput()
      ) : (
        <SuiTypography variant="caption" fontWeight="medium" textColor="text" padding={0}>
          {value}
        </SuiTypography>
      )}
    </>
  );
};

const areEqual = (prev, next) => prev.row.state.isEditable === next.row.state.isEditable;
export default EditableCell;
// export default memo(EditableCell, areEqual);
