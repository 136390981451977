import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import SuiBox from "./SuiBox";

const AddButton = ({ width, height, ...rest }) => (
  <SuiBox
    backgroundColor="info"
    borderRadius="50%"
    width={width}
    height={height}
    display="flex"
    justifyContent="center"
    alignItems="center"
    color="white"
    mx="auto"
    backgroundGradient
    customClass="cursor-pointer"
    {...rest}
  >
    <Icon className="" fontSize="small">
      add
    </Icon>
  </SuiBox>
);

AddButton.defaultProps = {
  width: "3rem",
  height: "3rem",
};

AddButton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default AddButton;
