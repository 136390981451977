/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// NewUser page components

// NewUser layout schemas for form and form feilds
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { eventForm } from "../../../cms/pages/new-page/schemas/form";
import { eventFromInitialValues } from "../../../cms/pages/new-page/schemas/initialValues";
import { createPage, updatePage } from "../../../../../redux/reducers/cmsSlice";
import EventInfo from "./components/EventInfo";
import useToastContext from "../../../../../context/ToastContext";

export const transformEventFormValues = (v, eventId) => ({
  soevent_id: eventId,
  id: v.pageId ?? v.id,
  type: v.pageType,
  title: v.title,
  title_en: v.title,
  slug: v.slug,
  featured: v.featured,
  featured_en: v.featured_en,
  suggested: v.suggested,
  homeslider: v.homeslider,
  shop_banner: v.shop_banner,
  widgets: v.widgets || [],
  meta: {
    description: v.description,
    description_en: v.description_en,
    label: v.label,
    label_en: v.label_en,
    date_text: v.date_text,
    date_text_en: v.date_text_en,
    full_image: v.full_image,
    type: v.event_type,
    category_type: v.category_type,
    id: v.pageId ? v.id : undefined,
  },
  events: v.events,
});

function NewEvent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const showToast = useToastContext();

  const { formId, formField } = eventForm;
  const { id } = useParams();
  // const [event, setEvent] = useState();
  //
  // useEffect(async () => {
  //   if (event.id === id) return;
  //   dispatch(getEvent(id))
  //     .unwrap()
  //     .then((res) => {
  //       setEvent((prev) => res.data.data);
  //     });
  // }, [id, event]);

  const submitForm = async (values, actions) => {
    const data = transformEventFormValues(values, id);
    try {
      if (data.id || data.id === 0) {
        await dispatch(updatePage(data)).unwrap();
      } else {
        await dispatch(createPage(data)).unwrap();
      }
      actions.setSubmitting(false);
    } catch (err) {
      showToast({
        type: "error",
        title: "Something went wrong",
        content: err?.message ?? undefined,
      });
      return;
    }
    await actions.resetForm();
    history.push("/events/event-pages");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3} mb={20}>
        <Grid container justifyContent="center" className="h-100">
          <Grid item xs={12} lg={8}>
            <Formik initialValues={eventFromInitialValues()} onSubmit={submitForm}>
              {({ isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card className="h-100">
                    <SuiBox p={2}>
                      <SuiBox>
                        <EventInfo formField={formField} />
                        <SuiBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                          <SuiButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            buttonColor="dark"
                          >
                            save
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default NewEvent;
