/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Categories from "./components/Categories";
import { getBoxCategories, getCategories } from "../../../../redux/reducers/shopSlice";
import BoxCategories from "./components/BoxCategories";

function CategoriesPage() {
  const dispatch = useDispatch();
  const { categories, boxCategories, shopSlider } = useSelector((state) => state.shopReducer);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getBoxCategories());
    dispatch(getBoxCategories({ slider: true }));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card p={5}>
          <Categories categories={categories} />
          <BoxCategories categories={shopSlider} slider />
          <BoxCategories categories={boxCategories} />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default CategoriesPage;
