/* eslint-disable react/prop-types,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useCallback } from "react";
import classNames from "classnames";
import { useInView } from "react-cool-inview";
import {
  RiBold,
  RiItalic,
  RiStrikethrough,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiParagraph,
  RiListOrdered,
  RiListUnordered,
  RiLink,
  RiLinkUnlink,
  RiDoubleQuotesL,
  RiSeparator,
  RiTextWrap,
  RiFormatClear,
  RiUnderline,
  RiImageAddLine,
  RiFileTextLine,
} from "react-icons/ri";

import "./Toolbar.scss";
import { handleUpload } from "../SuiDropzone";

function setLink(editor) {
  const previousUrl = editor.getAttributes("link").href;
  const url = window.prompt("URL", previousUrl);

  // cancelled
  if (url === null) {
    return;
  }

  // empty
  if (url === "") {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();

    return;
  }

  // update link
  editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
}

function Toolbar({ editor }) {
  const isCursorOverLink = editor.getAttributes("link").href;

  const { observe, inView } = useInView({
    rootMargin: "-1px 0px 0px 0px",
    threshold: [1],
  });

  const addImage = useCallback(async () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const imageUrl = await handleUpload(file);

      if (imageUrl) {
        editor.chain().focus().setImage({ src: imageUrl }).run();
      }
    };
  }, [editor]);

  const addFileLink = useCallback(async () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", ".pdf");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const url = await handleUpload(file);

      if (url) {
        // const previousUrl = editor.getAttributes('link').href
        //
        // // cancelled
        // if (url === null) {
        //   return
        // }
        //
        // // empty
        // if (url === '') {
        //   editor.chain().focus().extendMarkRange('link').unsetLink()
        //     .run()
        //
        //   return
        // }

        // update link
        editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
      }
    };
  }, [editor]);

  return (
    <div className={classNames("ToolbarContainer", { sticky: !inView })} ref={observe}>
      <div className="Toolbar">
        <div className="icon" onClick={() => editor.chain().focus().toggleBold().run()}>
          <RiBold />
        </div>
        <div className="icon" onClick={() => editor.chain().focus().toggleItalic().run()}>
          <RiItalic />
        </div>
        <div className="icon" onClick={() => editor.chain().focus().toggleStrike().run()}>
          <RiStrikethrough />
        </div>
        <div className="icon" onClick={() => editor.chain().focus().toggleUnderline().run()}>
          <RiUnderline />
        </div>
        <div className="divider" />
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        >
          <RiH1 />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        >
          <RiH2 />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        >
          <RiH3 />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        >
          <RiH4 />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        >
          <RiH5 />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        >
          <RiH6 />
        </div>
        <div className="icon" onClick={() => editor.chain().focus().setParagraph().run()}>
          <RiParagraph />
        </div>
        <div className="icon" onClick={() => editor.chain().focus().toggleBulletList().run()}>
          <RiListOrdered />
        </div>
        <div className="icon" onClick={() => editor.chain().focus().toggleOrderedList().run()}>
          <RiListUnordered />
        </div>
        <div className="divider" />
        <div className="icon" onClick={() => editor.chain().focus().toggleBlockquote().run()}>
          <RiDoubleQuotesL />
        </div>
        <div className="icon" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <RiSeparator />
        </div>
        <div className="divider" />
        <div className="icon" onClick={() => setLink(editor)}>
          <RiLink />
        </div>
        <div
          className={classNames("icon", { disabled: !isCursorOverLink })}
          onClick={() => setLink(editor)}
        >
          <RiLinkUnlink />
        </div>
        <div className="icon" onClick={addImage}>
          <RiImageAddLine />
        </div>
        <div className="icon" onClick={addFileLink}>
          <RiFileTextLine />
        </div>
        <div className="divider" />
        <div className="icon" onClick={() => editor.chain().focus().setHardBreak().run()}>
          <RiTextWrap />
        </div>
        <div
          className="icon"
          onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
        >
          <RiFormatClear />
        </div>
        {/* <div className="divider" /> */}
        {/* <div className="icon" onClick={() => editor.chain().focus().undo().run()}> */}
        {/*  <RiArrowGoBackLine /> */}
        {/* </div> */}
        {/* <div className="icon" onClick={() => editor.chain().focus().redo().run()}> */}
        {/*  <RiArrowGoForwardLine /> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export { Toolbar };
