/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    firstName,
    lastName,
    description,
    email,
    password,
    repeatPassword,
    address1,
    address2,
    city,
    zip,
    membership,
    expiration,
    phone,
  },
} = checkout;

export default {
  [firstName.name]: "",
  [lastName.name]: "",
  [description.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [zip.name]: "",
  [membership.name]: "",
  [expiration.name]: "",
  [phone.name]: "",
};
