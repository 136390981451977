import { useState } from "react";
import { SvgIcon } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import SuiBox from "../../SuiBox";

function InfoCell(...props) {
  return (
    <SuiBox display="flex" alighItems="center">
      {icons}
    </SuiBox>
  );
}

export const MemberInfoCell = ({ item }) => {
  const [acceptedNotifications, setAcceptedNotifications] = useState(item.accept_notifications);
  const [acceptedTerms, setAcceptedTerms] = useState(item.accept_terms);
  const [accountClosed, setAccountClosed] = useState(item.account_closed);

  // console.log(acceptedNotifications);
  return (
    // console.log("item", item);
    <SuiBox display="flex" alighItems="center">
      {acceptedTerms && (
        <SuiBox sx={{ marginRight: 0.4 }}>
          <Tooltip title="Accepted Terms" placement="top">
            <SvgIcon sx={{ width: "1.6em", height: "1.6em" }}>
              <path
                d="m17.32 7-.005-.005L11.106.787l6.181 6.18c.012.01.022.022.033.033z"
                fill="#d6d5d8"
              />
              <path
                d="M9.516 1.445v6.209c0 .514.417.931.932.931H16.657a.93.93 0 0 0 .931-.907l.001-.024a.926.926 0 0 0-.21-.587c0-.002-.002-.004-.004-.006a.904.904 0 0 0-.055-.06l-.033-.034-6.18-6.18a.907.907 0 0 0-.063-.057l-.018-.014a.933.933 0 0 0-.05-.038l-.02-.012a.93.93 0 0 0-.068-.042h-.002a.94.94 0 0 0-.075-.036l-.02-.008a.948.948 0 0 0-.058-.021L10.709.55a.917.917 0 0 0-.074-.018L10.63.532a.904.904 0 0 0-.08-.012l-.025-.003a.928.928 0 0 0-1.008.928z"
                fill="#c6c5ca"
              />
              <path
                d="M19.45 11.078a6.462 6.462 0 0 0-3.03-.626 6.586 6.586 0 0 0-1.874.347h-.003a6.537 6.537 0 0 0-.218.08l-.03.012-.187.076c-.016.007-.03.014-.047.02l-.166.076a6.92 6.92 0 0 0-.234.116l-.122.064-.09.05-.092.054-.089.053-.091.057a7.917 7.917 0 0 0-.18.118l-.077.053a6.974 6.974 0 0 0-.112.081l-.056.04c-.054.041-.108.083-.16.125l-.053.043c-.037.03-.074.06-.11.092l-.059.05a6.492 6.492 0 0 0-.262.243l-.047.046c-.045.044-.089.09-.132.135l-.014.014c-.05.053-.098.106-.145.16l-.026.029a6.422 6.422 0 0 0-.112.132l-.037.046a6.273 6.273 0 0 0-.171.22c-.021.029-.043.057-.063.086l-.09.129-.05.074a6.49 6.49 0 0 0-.131.204l-.035.06c-.031.052-.062.104-.091.157l-.044.08a6.77 6.77 0 0 0-.33.707 6.685 6.685 0 0 0-.053.142l-.04.108-.033.101a6.445 6.445 0 0 0-.315 1.654l-.006.114a4.756 4.756 0 0 0-.006.267c0 .087.002.172.006.258l.001.042c.004.083.01.166.017.248l.003.04a6.6 6.6 0 0 0 .068.52l.002.013c.015.087.032.173.05.258l.004.017a6.49 6.49 0 0 0 .41 1.247l.003.008a6.666 6.666 0 0 0 .354.68l.014.025c.044.072.089.142.134.212l.02.03c.046.07.094.139.143.207l.016.021a6.51 6.51 0 0 0 5.275 2.693 6.527 6.527 0 0 0 6.519-6.519 6.528 6.528 0 0 0-3.725-5.89zm-.148 5.182-2.645 2.645-.149.149a.928.928 0 0 1-1.317 0l-1.242-1.242a.931.931 0 0 1 1.317-1.317l.583.583.808-.807 1.328-1.329a.931.931 0 0 1 1.317 1.318z"
                fill="#c2fb3b"
              />
              <path
                d="M11.366 20.772a6.463 6.463 0 0 1-.143-.208l-.02-.029a6.53 6.53 0 0 1-.134-.212l-.014-.024a6.49 6.49 0 0 1-.354-.68c0-.004-.002-.006-.003-.009a6.468 6.468 0 0 1-.41-1.247l-.003-.017a6.468 6.468 0 0 1-.05-.258l-.003-.012a6.417 6.417 0 0 1-.088-.809l-.001-.042a6.484 6.484 0 0 1 0-.525l.002-.043H5.48a.931.931 0 0 1 0-1.863h5.03l.026-.07a6.42 6.42 0 0 1 .2-.482l.03-.069.036-.072.042-.084.076-.143.043-.08.09-.156.036-.06a6.36 6.36 0 0 1 .13-.205l.051-.074.09-.129c.02-.029.042-.057.063-.086l.086-.113.03-.04H5.48a.931.931 0 0 1 0-1.862h8.401l.014-.006c.055-.026.11-.051.167-.075l.046-.02c.062-.027.124-.052.186-.077l.031-.012c.072-.027.145-.054.218-.079l.003-.001a6.48 6.48 0 0 1 3.042-.284V7.678a.93.93 0 0 1-.93.907h-6.21a.931.931 0 0 1-.931-.931V1.446a.931.931 0 0 1 .946-.931l-.015-.001H1.755a.931.931 0 0 0-.93.931v18.627c0 .514.416.93.93.93h9.785a6.44 6.44 0 0 1-.158-.209l-.016-.021z"
                fill="#e0e0e2"
              />
              <g fill="#57565c">
                <path d="M5.48 14.794a.931.931 0 0 0 0 1.863h4.665l.004-.07.01-.132a6.39 6.39 0 0 1 .01-.118l.011-.113.016-.126.008-.062.007-.042a5.677 5.677 0 0 1 .066-.368 6.25 6.25 0 0 1 .113-.455l.019-.067.013-.04c.013-.045.028-.088.042-.132l.034-.1.012-.038H5.48ZM5.48 11.069a.931.931 0 0 0 0 1.862h6.06l.055-.066.038-.047c.036-.045.074-.088.112-.132l.025-.03.145-.159.014-.013c.043-.046.087-.091.132-.136l.047-.046a6.121 6.121 0 0 1 .262-.242l.059-.051a6.7 6.7 0 0 1 .11-.092l.053-.043c.053-.042.106-.084.16-.124l.056-.04.112-.082.077-.053a6.497 6.497 0 0 1 .272-.175l.088-.053.093-.053.09-.051.121-.064c.049-.026.098-.05.147-.074.025-.012.049-.025.074-.036H5.48Z" />
              </g>
              <path
                d="M19.302 14.943a.931.931 0 0 0-1.317 0l-1.328 1.328-.808.807-.583-.583a.931.931 0 0 0-1.317 1.317l1.242 1.242a.928.928 0 0 0 1.317 0l.149-.149 2.645-2.645a.931.931 0 0 0 0-1.317z"
                fill="#79ab03"
              />
              <path
                d="M16.508 19.054a.928.928 0 0 1-1.317 0l-1.242-1.242a.931.931 0 0 1 1.317-1.317l.583.583.808-.807v-5.823a6.707 6.707 0 0 0-.932.067c-.406.058-.8.154-1.179.284h-.003a6.537 6.537 0 0 0-.218.08l-.03.012-.187.076c-.016.007-.03.014-.047.02l-.166.076c-.03.013-.058.028-.087.042a6.92 6.92 0 0 0-.147.074l-.122.064-.09.05-.092.054-.089.053-.091.057a7.917 7.917 0 0 0-.18.118l-.077.053a6.712 6.712 0 0 0-.113.081l-.055.04c-.054.041-.108.083-.16.125l-.053.043a6.18 6.18 0 0 0-.11.092l-.059.05a6.492 6.492 0 0 0-.262.243l-.047.046c-.045.044-.089.09-.132.135l-.014.014c-.05.053-.098.106-.145.16l-.026.03a6.422 6.422 0 0 0-.112.13l-.038.047a6.451 6.451 0 0 0-.17.22c-.021.029-.043.057-.063.086l-.09.129-.051.074a6.49 6.49 0 0 0-.13.204l-.035.06c-.031.052-.062.104-.091.157l-.044.08a6.77 6.77 0 0 0-.33.707 6.685 6.685 0 0 0-.053.142l-.04.108-.033.101a6.445 6.445 0 0 0-.315 1.654l-.006.114a4.756 4.756 0 0 0-.006.267c0 .087.002.172.006.258l.001.042c.004.083.01.166.016.248l.004.04a6.6 6.6 0 0 0 .068.52l.002.013c.015.087.032.173.05.258l.004.017a6.49 6.49 0 0 0 .41 1.247c0 .003.002.005.003.008a6.479 6.479 0 0 0 .354.68l.014.025a6.74 6.74 0 0 0 .297.449l.016.021a6.511 6.511 0 0 0 5.275 2.693v-4.581Z"
                fill="#9cdd05"
              />
              <g fill="#a6d864">
                <path d="M14.061 10.988zM14.294 10.891zM11.54 21.003zM10.925 20.072zM10.691 14.34zM10.143 16.7zM14.543 10.8zM10.736 14.242c.022-.048.044-.095.067-.141l-.036.072-.03.069zM10.164 17.555zM15.725 10.515zM11.07 20.323zM10.59 14.581zM10.137 16.967zM11.223 20.565zM11.36 13.17zM10.51 19.14zM10.92 13.874zM10.144 17.267zM11.51 12.971zM11.055 13.638zM10.234 18.088zM10.192 17.812zM11.22 13.373zM10.701 19.618zM10.158 16.455zM10.196 16.098l.015-.104-.007.042-.008.062zM10.39 15.17l.032-.107-.013.041-.019.067zM10.498 14.831l-.034.101.034-.1zM10.358 15.288zM10.3 15.52zM10.18 16.224zM10.251 15.756zM12.429 12.01l-.059.05.059-.05zM13.808 11.105zM12.591 11.874c-.017.015-.034.03-.052.043l.052-.043zM11.633 12.818l-.038.047.038-.047zM12.997 11.575l-.077.053.077-.053zM12.061 12.348l.047-.046-.047.046zM13.357 11.347l-.089.053.089-.053zM12.808 11.71l-.056.04.056-.04zM13.177 11.457zM13.54 11.243l-.09.05.09-.05zM11.77 12.657l-.025.03.025-.03zM12.269 12.15zM11.929 12.484l-.014.013.014-.013zM16.657 18.905l-.149.149z" />
              </g>
              <path fill="#b4de7c" d="m19.302 16.26-2.645 2.645z" />
            </SvgIcon>
          </Tooltip>
        </SuiBox>
      )}
      {acceptedNotifications && (
        <SuiBox sx={{ marginRight: 0.8 }}>
          <Tooltip title="Accepted Notifications" placement="top">
            <SvgIcon sx={{ width: "1.6em", height: "1.6em" }}>
              <path
                d="M21.368 18.43H7.189a.588.588 0 0 1 0-1.176h14.179c.147 0 .266-.143.266-.318V7.064c0-.175-.12-.318-.266-.318H8.044c-.147 0-.267.143-.267.318v7.087a.588.588 0 0 1-1.176 0V7.064c0-.824.647-1.495 1.443-1.495h13.324c.795 0 1.442.671 1.442 1.495v9.872c0 .824-.647 1.495-1.442 1.495Z"
                fill="#507c5c"
              />
              <path
                d="M7.19 7.064c0 .29.13.562.35.733l7.166 5.552 7.166-5.552c.22-.171.35-.443.35-.733 0-.5-.382-.906-.854-.906H8.044c-.472 0-.855.406-.855.906z"
                fill="#cff09e"
              />
              <g fill="#507c5c">
                <path d="M14.706 13.938a.588.588 0 0 1-.36-.124L7.178 8.262a1.52 1.52 0 0 1-.578-1.198c0-.824.647-1.494 1.443-1.494h13.324c.795 0 1.442.67 1.442 1.494 0 .47-.216.917-.578 1.198l-7.166 5.552a.587.587 0 0 1-.36.124zM8.044 6.746c-.147 0-.267.143-.267.318 0 .108.046.208.123.268l6.806 5.273 6.805-5.273a.337.337 0 0 0 .123-.268c0-.175-.12-.318-.266-.318H8.044Z" />
                <path d="M7.13 9.431H1.778a.588.588 0 0 1 0-1.176H7.13a.588.588 0 0 1 0 1.176zM7.13 12.107H3.542a.588.588 0 0 1 0-1.176H7.13a.588.588 0 0 1 0 1.176zM7.13 14.784H5.307a.588.588 0 0 1 0-1.177H7.13a.588.588 0 0 1 0 1.177z" />
              </g>
            </SvgIcon>
          </Tooltip>
        </SuiBox>
      )}
      {accountClosed && (
        <SuiBox>
          <Tooltip title="Account Closed" placement="top">
            <SvgIcon sx={{ width: "1.6em", height: "1.6em" }}>
              <path
                d="M16.042 19.304V13.49h1.91v-1.552H4.138V20.5c0 1.148.93 2.078 2.078 2.078h9.656c1.148 0 2.078-.93 2.078-2.078v-1.197h-1.91zm-4.997-1.195a1.616 1.616 0 1 1 0-3.233 1.616 1.616 0 0 1 0 3.233z"
                fill="#cff09e"
              />
              <g fill="#507c5c">
                <path d="M19.861 12.86h-1.28v-.922a.63.63 0 0 0-.63-.63H4.14a.63.63 0 0 0-.63.63V20.5a2.71 2.71 0 0 0 2.708 2.708h9.656a2.71 2.71 0 0 0 2.708-2.708v-1.197a.63.63 0 0 0-.63-.63h-1.28v-1.647h2.56v1.093a.63.63 0 1 0 1.26 0v-4.63a.63.63 0 0 0-.63-.63zm-2.54 7.074v.567a1.45 1.45 0 0 1-1.448 1.448H6.217a1.45 1.45 0 0 1-1.448-1.448v-7.933h12.553v.292h-1.28a.63.63 0 0 0-.63.63v5.814c0 .348.282.63.63.63h1.28zm-.65-4.167V14.12h2.56v1.647z" />
                <path d="M11.045 14.246A2.249 2.249 0 0 0 8.8 16.493a2.25 2.25 0 0 0 1.616 2.155v1.056a.63.63 0 1 0 1.26 0v-1.056a2.25 2.25 0 0 0 1.616-2.155 2.248 2.248 0 0 0-2.246-2.247zm0 3.233a.988.988 0 0 1 0-1.973.988.988 0 0 1 0 1.973zM15.453 10.112a.63.63 0 0 1-.63-.63V5.83a3.783 3.783 0 0 0-3.778-3.778 3.783 3.783 0 0 0-3.778 3.778v3.653a.63.63 0 1 1-1.26 0V5.83A5.043 5.043 0 0 1 11.045.791a5.044 5.044 0 0 1 5.038 5.038v3.653a.63.63 0 0 1-.63.63z" />
              </g>
            </SvgIcon>
          </Tooltip>
        </SuiBox>
      )}
    </SuiBox>
  );
};
export default InfoCell;
