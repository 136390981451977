import { groupBy } from "loadsh/collection";
import { getDonationsRequest } from "../../http/donationsRequests";
import {
  setItems,
  setCoupons,
  setCouponsTotal,
  setCouponsPagesTotal,
  setDonations,
  setDonationsTotal,
  setDonationsPagesTotal,
  setItemsTotal,
  setItemsPagesTotal,
  setShipping,
  setShopStatus,
} from "../reducers/shopSlice";
import { getShippingRequest } from "../../http/shippingRequests";
import { getCouponsRequest } from "../../http/couponsRequests";
import { getItemsRequest } from "../../http/shopItemsRequests";

export const getDonations = (params) => (dispatch) => {
  getDonationsRequest(params)
    .then((res) => {
      dispatch(setDonations(res.data.data));
      dispatch(setDonationsTotal(res.data.meta.pagination.total));
      dispatch(setDonationsPagesTotal(res?.data?.meta?.pagination?.total_pages));
    })
    .catch((err) => {
      console.log(err);
    });
};

// export const getShipping = () => (dispatch) => {
//   getShippingRequest({})
//     .then((res) => {
//       const data = groupBy(res.data.data, "weight_threshold");
//       const temp = Object.entries(data)
//         .map(([k, v]) => {
//           const zoneObj = v.reduce(
//             (obj, item) => Object.assign(obj, { [item.zone_code]: item.cost }),
//             {}
//           );
//           return { ...zoneObj, weight_threshold: k };
//         })
//         .sort((a, b) => a.weight_threshold - b.weight_threshold);
//       dispatch(setShipping(temp));
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

export const getCoupons = (params) => (dispatch, getState) => {
  dispatch(setShopStatus("loading"));
  getCouponsRequest(params)
    .then((res) => {
      const data = res.data?.data?.map((el) => ({
        ...el,
        active: el.active === 0 ? "Inactive" : "Active",
        once: el.once === 0 ? "Unlimited" : "Once",
        type: el.type === "percentage" ? "%" : "€",
      }));
      dispatch(setCoupons(data));
      dispatch(setCouponsTotal(res.data.meta.pagination.total));
      dispatch(setCouponsPagesTotal(res.data.meta.pagination.total_pages));
      dispatch(setShopStatus("fulfilled"));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setShopStatus("error"));
    });
};

export const getItems = (params) => (dispatch, getState) => {
  getItemsRequest(params)
    .then((res) => {
      const data = res.data?.data;
      dispatch(setItems(data));
      dispatch(setItemsTotal(res.data.meta.pagination.total));
      dispatch(setItemsPagesTotal(res?.data?.meta?.pagination?.total_pages));
    })
    .catch((err) => {
      console.log(err);
    });
};
