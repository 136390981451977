import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOptionsRequest, getSubscriptionsRequest } from "../../http/optionsRequests";

const initialState = {
  subscriptions: [],
  s1Series: [],
  s1ServiceCodes: {},
  exhibitOptions: {
    culture: {},
    kind: {},
    material: {},
    period: {},
  },
  status: "idle",
};

export const getSubscriptions = createAsyncThunk("optionsReducer/getSubscriptions", async () => {
  const res = await getSubscriptionsRequest();
  return res;
});

export const getOptions = createAsyncThunk("optionsReducer/getOptions", async () => {
  const res = await getOptionsRequest();
  return res;
});

const optionsSlice = createSlice({
  name: "optionsReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubscriptions.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getSubscriptions.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(getSubscriptions.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.subscriptions = payload.data.data
        ?.map((el) => ({
          ...el,
          label: el.name,
          value: el.id,
        }))
        .concat([{ label: "Μεμονωμένη Παρακολούθηση", id: 0, value: 0 }]);
    });
    builder.addCase(getOptions.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getOptions.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(getOptions.fulfilled, (state, { payload }) => {
      // try {
      state.status = "fulfilled";
      const data = [...payload.data.data];
      state.s1Series = Object.entries(
        data[data.findIndex((el) => el.key === "s1_series")]?.value
      )?.map(([k, v]) => ({ id: k, label: v, value: k }));
      state.s1ServiceCodes = data[data.findIndex((el) => el.key === "s1_service_code")].value;
      const culture = data[data.findIndex((el) => el.key === "admin_exhibit_culture")].value;
      const kind = data[data.findIndex((el) => el.key === "admin_exhibit_kind")].value;
      const material = data[data.findIndex((el) => el.key === "admin_exhibit_material")].value;
      const period = data[data.findIndex((el) => el.key === "admin_exhibit_period")].value;
      state.exhibitOptions = { culture, kind, material, period };
      // } catch (e) {
      //   console.log(e);
      // }
    });
  },
});

export default optionsSlice.reducer;
