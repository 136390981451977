import { loginRequest, logoutRequest } from "../../http/authRequests";
import { getProfile, setAccessToken, setUserLoggedIn, userLogout } from "../reducers/authSlice";
import { getOptions, getSubscriptions } from "../reducers/optionsSlice";

export const login = (username, password) => async (dispatch) => {
  // dispatch(setLoading(true))
  try {
    const res = await loginRequest(username, password);
  } catch (e) {
    return e;
  }
  // console.log(res);
  // dispatch(setLoading(false))
  dispatch(setAccessToken(res.data.access_token));
  dispatch(setUserLoggedIn(true));
  await dispatch(getProfile());
};

export const logout = () => (dispatch) => {
  logoutRequest()
    .then((res) => {
      dispatch(userLogout());
    })
    .catch((err) => {
      dispatch(userLogout());
      // console.log(err);
    });
};
