/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

export default {
  formId: "user-form",
  formField: {
    avatar: {
      name: "avatar",
      type: "image",
    },
    name: {
      name: "name",
      label: "name",
      type: "text",
      errorMsg: "First name is required.",
    },
    description: {
      name: "description",
      label: "description",
      type: "text",
    },
    username: {
      name: "username",
      label: "username",
      type: "text",
      errorMsg: "Last name is required.",
    },
    email: {
      name: "email",
      label: "email",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    confirmEmail: {
      name: "confirmEmail",
      label: "confirm email",
      type: "email",
    },
    password: {
      name: "password",
      label: "password",
      type: "password",
      placeholder: "******",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    role: {
      name: "role",
      label: "role",
      type: "text",
      placeholder: "",
      errorMsg: "",
    },
  },
};
