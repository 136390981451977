import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import WidgetCollapse from "../../Collapse/WidgetCollapse";
// import FormField, { DropdownFormField, EditorFormField } from "../../FormField";
import SuiBox from "../../SuiBox";
import initialValues from "./schema/initialValues";
import form from "./schema/form";
import SuiButton from "../../SuiButton";
import FieldsWrapper from "../../FieldsWrapper";

const empty = {
  data: undefined,
  order: undefined,
  type: undefined,
};

// eslint-disable-next-line react/prop-types
const SubscriptionsWidget = ({ widget = empty, onSubmit, onDelete, disabled }) => {
  const { data, order, type } = widget;
  const { formId, formField } = form;
  const subscriptionOptions = useSelector((state) => state.optionsReducer.subscriptions);
  const [fields, setFields] = useState(formField);

  useEffect(() => {
    if (subscriptionOptions && subscriptionOptions.length > 0) {
      setFields((p) => ({
        ...p,
        subscription: {
          ...p.subscription,
          options: subscriptionOptions.map((el) => ({ value: el.id, label: el.name })),
        },
      }));
    }
  }, [subscriptionOptions]);

  const handleSubmit = async (values, actions) => {
    onSubmit(
      {
        widget: {
          data: values,
          order: order ?? null,
          type: type ?? null,
        },
        widgetId: values.id ?? null,
      },
      actions
    );
  };

  const handleDelete = (values) => {
    onDelete({ data: values, order: order ?? null, type: type ?? null });
  };
  return (
    <Formik
      initialValues={data || initialValues}
      // validationSchema={currentValidation}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting, setSubmitting }) => (
        <Form id={formId} autoComplete="off">
          <WidgetCollapse
            title={`Subscription Widget${values.title ? ` - ${values.title}` : ""}`}
            disabled={disabled}
            onDelete={() => handleDelete(values, setSubmitting)}
          >
            <SuiBox mt={1.625}>
              <Grid container sx={{ zIndex: 10000 }}>
                <FieldsWrapper fields={Object.values(fields)} />
              </Grid>
              <SuiBox mt={5}>
                <SuiButton
                  disabled={isSubmitting}
                  variant="gradient"
                  buttonColor="dark"
                  type="submit"
                >
                  save
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </WidgetCollapse>
        </Form>
      )}
    </Formik>
  );
};

export default SubscriptionsWidget;
