import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  members: undefined,
  subscriptions: undefined,
  membersTotal: 0,
  membersPagesTotal: 0,
  subscriptionsTotal: 0,
  subscriptionsPagesTotal: 0,
  status: "idle",
};

const membersSlice = createSlice({
  name: "membersReducer",
  initialState,
  reducers: {
    setMembers: (state, action) => ({ ...state, members: action.payload }),
    setSubscriptions: (state, action) => ({ ...state, subscriptions: action.payload }),
    setMembersTotal: (state, action) => ({ ...state, membersTotal: action.payload }),
    setMembersPagesTotal: (state, action) => ({ ...state, membersPagesTotal: action.payload }),
    setSubscriptionsTotal: (state, action) => ({ ...state, subscriptionsTotal: action.payload }),
    setSubscriptionsPagesTotal: (state, action) => ({
      ...state,
      subscriptionsPagesTotal: action.payload,
    }),
    setMembersStatus: (state, action) => ({ ...state, status: action.payload }),
  },
});

export const {
  setMembers,
  setSubscriptions,
  setMembersTotal,
  setMembersPagesTotal,
  setSubscriptionsTotal,
  setSubscriptionsPagesTotal,
  setMembersStatus,
} = membersSlice.actions;
export default membersSlice.reducer;
