/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import { Link } from "react-router-dom";

export const PageLinkCell = ({ value, suffix }) => (
  <Link to={{ pathname: `${process.env.REACT_APP_EXTERNAL_URL}/page/${value}` }} target="_blank">
    <SuiTypography variant="caption" fontWeight="medium" textColor="text">
      {`${process.env.REACT_APP_EXTERNAL_URL}/page/${value}`}
      {suffix && (
        <SuiTypography variant="caption" fontWeight="medium" textColor="secondary">
          &nbsp;&nbsp;{suffix}
        </SuiTypography>
      )}
    </SuiTypography>
  </Link>
);

function LinkCell({ value, suffix }) {
  return (
    <Link to={{ pathname: value }} target="_blank">
      <SuiTypography variant="caption" fontWeight="medium" textColor="text">
        {value}
        {suffix && (
          <SuiTypography variant="caption" fontWeight="medium" textColor="secondary">
            &nbsp;&nbsp;{suffix}
          </SuiTypography>
        )}
      </SuiTypography>
    </Link>
  );
}

// Setting default values for the props of DefaultCell
PageLinkCell.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
PageLinkCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

// Setting default values for the props of DefaultCell
LinkCell.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
LinkCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default LinkCell;
