/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// NewUser page components
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import SuiInput from "../../../../../../components/SuiInput";
import FormField, { DatepickerFormField } from "../../../../../../components/FormField";
// FIXME: add dual subscription checkbox
function UserInfo({ formData }) {
  const [state, setState] = useState("...");

  const { formField, values, errors, touched, setFieldValue } = formData;

  const {
    firstName,
    lastName,
    description,
    email,
    password,
    repeatPassword,
    address1,
    city,
    zip,
    membership,
    expiration,
    phone,
    country,
  } = formField;

  const {
    firstName: firstNameV,
    lastName: lastNameV,
    description: companyV,
    email: emailV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
    address1: address1V,
    city: cityV,
    zip: zipV,
    membership: membershipV,
    expiration: expirationV,
    phone: phoneV,
    country: countryV,
  } = values;

  const handleSetState = (event) => setState(event.target.value);

  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h5" fontWeight="bold">
          Create New Member
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              value={companyV}
              placeholder={description.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={repeatPassword.type}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              placeholder={repeatPassword.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <FormField
              type={address1.type}
              label={address1.label}
              name={address1.name}
              value={address1V}
              placeholder={address1.placeholder}
              error={errors.address1 && touched.address1}
              success={address1V.length > 0 && !errors.address1}
            />
          </Grid>
          <Grid item xs={4}>
            <SuiBox>
              <FormField
                type={phone.type}
                label={phone.label}
                name={phone.name}
                value={phoneV}
                placeholder={phone.placeholder}
              />
            </SuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={city.type}
              label={city.label}
              name={city.name}
              value={cityV}
              placeholder={city.placeholder}
              error={errors.city && touched.city}
              success={cityV.length > 0 && !errors.city}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormField
              type={country.type}
              label={country.label}
              name={country.name}
              value={countryV}
              placeholder={country.placeholder}
              error={errors.country && touched.country}
              success={countryV?.length > 0 && !errors.country}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormField
              type={zip.type}
              label={zip.label}
              name={zip.name}
              value={zipV}
              placeholder={zip.placeholder}
              error={errors.zip && touched.zip}
              success={zipV.length > 0 && !errors.zip}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Membership
              </SuiTypography>
            </SuiBox>
            <Select
              input={<SuiInput />}
              value={membershipV}
              handleChange={(e) => setFieldValue("membership", e)}
            >
              <MenuItem value="none" disabled>
                Membership type
              </MenuItem>
              <MenuItem value="1">YOUNG aged up to 35 / 25 euros (per year)</MenuItem>
              <MenuItem value="2">MEMBER / 80 euros (per year)</MenuItem>
              <MenuItem value="3">DUAL SUBSCRIPTION (2 persons) / 120 euros (per year)</MenuItem>
              <MenuItem value="4">SUPPORTER / 250 euros (per year)</MenuItem>
              <MenuItem value="5">DONOR / 500 euros (per year)</MenuItem>
              <MenuItem value="6">SPONSOR / 1.000 euros (per year)</MenuItem>
              <MenuItem value="7">CORPORATE SUBSCRIPTION* / from 5.000 euros (per year)</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatepickerFormField
              label={expiration.label}
              name={expiration.name}
              value={expirationV}
              setValue={(e) => {
                setFieldValue("expiration", e);
              }}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
