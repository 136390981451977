export default {
  formId: "publication-widget",
  formField: {
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    menuTitle: {
      name: "menu_title",
      label: "Menu Title",
      type: "text",
      errorMsg: "Last name is required.",
    },
    bottomLinkText: {
      name: "bottom_link_text",
      label: "bottom_link_text",
      type: "text",
      errorMsg: "",
    },
    bottomLinkUrl: {
      name: "bottom_link_url",
      label: "bottom_link_url",
      type: "text",
      errorMsg: "",
    },
    publications: {
      name: "publications",
      label: "publications",
      type: "object",
      errorMsg: "",
    },
  },
};
