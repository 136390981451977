import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { SvgIcon } from "@mui/material";
import SuiBox from "../../SuiBox";
import SuiTypography from "../../SuiTypography";

const getStatusColor = (status) => {
  let statusColor;
  switch (status) {
    case "paid":
    case "completed":
      statusColor = "success";
      break;
    case "pending":
      statusColor = "warning";
      break;
    case "cancelled":
    default:
      statusColor = "error";
  }
  return statusColor;
};

function OrderStatusCell({ item }) {
  const [status, setStatus] = useState(item.status);
  const [color, setColor] = useState(getStatusColor(item.status));

  useEffect(() => {
    if (item?.status) {
      setStatus(item?.status);
      setColor(getStatusColor(item.status));
    }
  }, [item]);
  return (
    <SuiBox display="flex" alignItems="center" maxWidth="100px" sx={{ overflow: "hidden" }}>
      <SvgIcon color={color}>
        <circle cx="12" cy="12" r="5" />
      </SvgIcon>
      <SuiTypography
        variant="body"
        fontWeight="medium"
        fontSize={13}
        // textGradient
        textColor={item.status === "paid" ? "secondary" : color}
        textTransform="capitalize"
        // customClass="line-height-0"
        sx={{ textOverflow: "ellipsis" }}
      >
        {status}
      </SuiTypography>
    </SuiBox>
  );
}

// Typechecking props for the StatusCell
OrderStatusCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
};

export default OrderStatusCell;
