/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useCallback, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Modal } from "@mui/material";
import { Form, Formik } from "formik";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import AddButton from "../../../../../components/addButton";
import SuiButton from "../../../../../components/SuiButton";
import CategoriesForm from "./Form";
import form from "../schemas/categories/form";
import initialValues from "../schemas/categories/initialValues";
import WidgetCollapse from "../../../../../components/Collapse/WidgetCollapse";
import DraggableList from "../../../../../components/DragableList/DragableList";
import {
  createCategory,
  deleteCategory,
  getCategories,
  orderCategory,
  updateCategory,
} from "../../../../../redux/reducers/shopSlice";
import useToastContext from "../../../../../context/ToastContext";

function Categories({ categories = [] }) {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const { formId, formField } = form;
  const showToast = useToastContext();

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      if (values.id || values.id === 0) {
        await dispatch(updateCategory(values)).unwrap();
      } else {
        await dispatch(createCategory(values)).unwrap();
      }
      showToast({
        type: "success",
        title: "Categories saved successfully!",
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "There was an error",
        content: e?.message || e || "",
      });
    } finally {
      await dispatch(getCategories());
      setModalOpen(false);
      actions.setSubmitting(false);
      actions.resetForm();
    }
  };

  const handleDeleteCategory = async (id, index) => {
    try {
      await dispatch(deleteCategory({ id })).unwrap();
      showToast({
        type: "success",
        title: "Categories saved successfully!",
      });
      await dispatch(getCategories());
    } catch (e) {
      showToast({
        type: "error",
        title: "There was an error",
        content: e?.message || e || "",
      });
    }
  };

  const renderItem = useCallback(
    ({ item, index, disabled }) => (
      <WidgetCollapse
        title={item?.name}
        disabled={disabled}
        onEdit={() => setModalOpen(index + 1)}
        onDelete={() => handleDeleteCategory(item.id, index)}
      >
        {item?.subcategories?.map((el) => (
          <SuiTypography variant="h6" ml={2}>
            • {el.name}
          </SuiTypography>
        ))}
      </WidgetCollapse>
    ),
    []
  );

  const handleCategoriesReorder = async (id, startIndex, finalindex, list) => {
    try {
      await dispatch(orderCategory({ id, order: finalindex })).unwrap();
      showToast({
        type: "success",
        title: "Categories saved successfully!",
      });
      await dispatch(getCategories());
    } catch (e) {
      showToast({
        type: "error",
        title: "There was an error",
        content: e?.message || e || "",
      });
    }
  };
  const onClose = () => setModalOpen(false);
  return (
    <SuiBox mt={2} mb={8}>
      <Grid container p={3}>
        <SuiBox display="flex" alignItems="center" justifyContent="flex-start" width="90%" mb={5}>
          <SuiBox mr={2} lineHeight={1}>
            <SuiTypography variant="h5" fontWeight="medium">
              Menu Categories
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <AddButton width="2.5rem" height="2.5rem" onClick={() => setModalOpen(true)} />
          </SuiBox>
        </SuiBox>
        <Grid item xs={12} md={6}>
          <DraggableList
            items={categories}
            renderItem={renderItem}
            onSave={handleCategoriesReorder}
          />
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onBackdropClick={onClose}
        onClose={onClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "auto",
        }}
      >
        <Grid item xs={10} md={4}>
          <Formik
            initialValues={
              modalOpen && modalOpen !== true ? categories[modalOpen - 1] : initialValues
            }
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <Card className="h-100">
                  <SuiBox p={2}>
                    <SuiBox>
                      <CategoriesForm
                        formData={{
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                        }}
                      />
                      <SuiBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                        <SuiButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          buttonColor="dark"
                        >
                          save
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                  </SuiBox>
                  <SuiBox sx={{ position: "absolute", top: 12, right: 8 }}>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </SuiBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Modal>
    </SuiBox>
  );
}

Categories.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.any.isRequired,
};

export default React.memo(Categories);
