import { combineReducers } from "redux";
import { persistReducer, purgeStoredState } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import authReducer, { userLogout } from "./authSlice";
import membersReducer from "./membersSlice";
import eventsReducer from "./eventsSlice";
import shopReducer from "./shopSlice";
import usersReducer from "./usersSlice";
import cmsReducer from "./cmsSlice";
import optionsReducer from "./optionsSlice";

const appReducer = combineReducers({
  authReducer,
  membersReducer,
  eventsReducer,
  shopReducer,
  cmsReducer,
  usersReducer,
  optionsReducer,
});

const migrations = {
  0: (state) => undefined,
};

const rootPersistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  version: 0,
  // migrate: createMigrate(migrations, { debug: false }),
  whitelist: ["authReducer"],
};

const rootReducer = (state, action) => {
  if (action.type === userLogout.type) {
    // eslint-disable-next-line no-param-reassign
    state = {};
    purgeStoredState(rootPersistConfig);
  }

  return appReducer(state, action);
};
const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedRootReducer;
