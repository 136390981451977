export default {
  button: "page",
  formId: "page-widget-modal",
  formField: {
    pageUrl: {
      name: "url",
      label: "Insert page url",
      type: "text",
    },
    imageLabel: {
      name: "label",
      label: "image Label",
      type: "text",
      dependant: "image",
    },
    image: {
      name: "image",
      type: "image",
    },
  },
};
