/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React components
import borders from "assets/theme/base/borders";
import { useEffect, useLayoutEffect, useState } from "react";
import { useFormikContext } from "formik";
import pxToRem from "../../../assets/theme/functions/pxToRem";

function WidgetCollapse({ title, open, children, onEdit, onDelete, disabled, ...rest }) {
  const [isOpen, setIsOpen] = useState(open ?? false);
  const { borderWidth, borderColor } = borders;
  const { isSubmitting } = useFormikContext() ?? { isSubmitting: false };

  useEffect(() => {
    if (open !== undefined) setIsOpen(open);
  }, [open]);

  useLayoutEffect(() => {
    if (disabled) setIsOpen(false);
  }, [disabled]);

  const handleEditClick = (e) => {
    e.stopPropagation();
    if (disabled || isSubmitting) return;
    onEdit();
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    if (disabled || isSubmitting) return;
    onDelete();
  };

  const handleClick = () => {
    if (disabled) return;
    if (!isOpen && !children) return;
    if (open === undefined) setIsOpen((pr) => !pr);
  };

  return (
    <SuiBox
      mb={2}
      width="100%"
      border={`${borderWidth[1]} solid ${borderColor}`}
      borderRadius={8}
      backgroundColor="white"
    >
      <SuiBox
        onClick={handleClick}
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        borderBottom={isOpen ? `${borderWidth[1]} solid ${borderColor}` : undefined}
        customClass="cursor-pointer"
      >
        <SuiTypography
          variant="h6"
          textColor={isOpen ? "dark" : "text"}
          customClass="user-select-none"
        >
          {title}
        </SuiTypography>
        <SuiBox display="flex">
          {onEdit ? (
            <SuiBox
              color="dark"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={handleEditClick}
              style={
                onDelete
                  ? { borderRight: "1px solid", paddingRight: pxToRem(10), marginRight: pxToRem(4) }
                  : null
              }
            >
              <Icon className="font-bold" fontSize="small">
                create
              </Icon>
            </SuiBox>
          ) : null}
          {onDelete ? (
            <SuiBox
              color="dark"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={handleDeleteClick}
            >
              <Icon className="font-bold" fontSize="medium">
                close
              </Icon>
            </SuiBox>
          ) : null}
        </SuiBox>
      </SuiBox>
      <Collapse timeout={400} in={isOpen} unmountOnExit mountOnEnter>
        <SuiBox p={2} lineHeight={1}>
          {!disabled && children}
        </SuiBox>
      </Collapse>
    </SuiBox>
  );
}

WidgetCollapse.defaultProps = {
  onEdit: null,
  onDelete: null,
  open: undefined,
  disabled: false,
};

// Typechecking props for the WidgetCollapse
WidgetCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
};

export default WidgetCollapse;
