/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SuiTypography from "../SuiTypography";
import SuiDropzone from "../SuiDropzone";

const FormDropzone = ({ getImageUrl, value, multiple = false, ...rest }) => (
  <Grid container mt={1.625}>
    <Grid item xs={12} sm={6}>
      <SuiTypography component="label" variant="caption" fontWeight="bold">
        {multiple ? "Upload other images" : "Upload Photo"}
      </SuiTypography>
      <SuiDropzone {...rest} getImageUrl={getImageUrl} value={value} multiple={multiple} />
    </Grid>
    <Grid item xs={12} sm={1} height="1rem" />
    <Grid item xs={12} sm={4} />
  </Grid>
);

export default FormDropzone;
