/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import dataTableData from "./data/dataTableData";
import AddButton from "../../../../components/addButton";
import {
  createShipping,
  deleteShipping,
  getShipping,
  updateShipping,
} from "../../../../redux/reducers/shopSlice";
import AddShippingModal from "./components/AddShippingModal";

function ShippingPage() {
  const dispatch = useDispatch();
  const shippingData = useSelector((state) => state.shopReducer?.shipping);
  const status = useSelector((state) => state.shopReducer?.status);
  const [data, setData] = useState(dataTableData);
  const [modalNew, setModalNew] = useState(false);

  const handleUpdate = useCallback(
    (params) => {
      dispatch(getShipping());
    },
    [getShipping]
  );

  useEffect(() => {
    if (shippingData) {
      setData((prev) => ({ ...prev, rows: shippingData }));
    }
  }, [shippingData]);

  const handleSaveRow = useCallback(async (values, action) => {
    if (action === "delete") {
      await dispatch(deleteShipping(values.weight_threshold));
      await dispatch(getShipping());
    } else {
      await dispatch(updateShipping(values));
      await dispatch(getShipping());
    }
  }, []);

  const handleUpdateRow = useCallback((rowIndex, columnId, value) => {
    setData((prev) => {
      const temp = [...prev.rows];
      temp[rowIndex] = { ...temp[rowIndex], [columnId]: value };
      return { ...prev, rows: temp };
    });
  }, []);

  const handleAdd = async (values) => {
    await dispatch(createShipping(values));
    await dispatch(getShipping());
  };

  const closeModal = () => setModalNew(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Shipping
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <AddButton onClick={() => setModalNew(true)} />
            </SuiBox>
          </SuiBox>
          <DataTable
            loading={status === "loading"}
            fetch={handleUpdate}
            showTotalEntries={false}
            table={data}
            editable
            noPagination
            noShorting
            handleUpdateRow={handleUpdateRow}
            handleSaveRow={handleSaveRow}
            // maxWidth
          />
        </Card>
      </SuiBox>
      <AddShippingModal open={modalNew} setOpen={setModalNew} handleSubmit={handleAdd} />
    </DashboardLayout>
  );
}

export default ShippingPage;
