import { searchEventPages } from "../../../../redux/reducers/eventsSlice";

export default {
  formId: "ticket-widget",
  formField: {
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    menuTitle: {
      name: "menu_title",
      label: "Menu Title",
      type: "text",
    },
    description: {
      name: "description",
      label: "description",
      type: "html",
    },
    eventPage: {
      name: "soevent_id",
      label: "events",
      type: "searchDropdown",
      isMulti: false,
      getOptions: (params) => searchEventPages(params),
    },
    s1Series: {
      name: "s1_series",
      label: "s1 series",
      type: "dropdown",
      // options: store.getState().
    },
    s1ServiceCode: {
      name: "s1_service_code",
      label: "s1 service code",
      type: "dropdown",
      // options: store.getState().
    },
    options: {
      name: "options",
      label: "tickets",
    },
  },
};
