/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import dataTableData from "./data/dataTableData";
// import AddButton from "../../../../components/addButton";
import { getOrders, setShowOrder } from "../../../../redux/reducers/shopSlice";
import { getCoupons } from "../../../../redux/Middleware/ShopThunk";

const filters = {
  key: "status",
  options: [
    { label: "show all", value: null },
    { label: "draft", value: "draft" },
    { label: "pending", value: "pending" },
    { label: "paid", value: "paid" },
    { label: "cancelled", value: "cancelled" },
  ],
};
function OrdersPage() {
  const dispatch = useDispatch();
  const ordersData = useSelector((state) => state.shopReducer.orders);
  const couponsData = useSelector((state) => state.shopReducer.coupons);
  const ordersTotal = useSelector((state) => state.shopReducer.ordersTotal);
  const totalPages = useSelector((state) => state.shopReducer.ordersTotalPages);
  const status = useSelector((state) => state.shopReducer.status);
  const showOrder = useSelector((state) => state.shopReducer.showOrder);
  const [data, setData] = useState(dataTableData);

  useEffect(() => {
    if (ordersData) setData((prev) => ({ ...prev, rows: ordersData }));
  }, [ordersData]);

  useEffect(() => {
    dispatch(getCoupons({ totalPerPage: 100 }));
  }, []);

  const handleUpdate = (params) => dispatch(getOrders(params));

  const handleShowOrder = (value) => {
    dispatch(setShowOrder(value));
  };

  const couponsList = useMemo(() => {
    if (couponsData?.length) return [{ id: null, code: "Select Coupon" }, ...couponsData];
    return [{ id: null, code: "Select Coupon" }];
  }, [couponsData]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle="Orders" breadcrumbRoutes={["orders"]} />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Orders List
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {ordersTotal} orders
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              {/* <AddButton component={Link} to="/authentication/sign-up/basic" /> */}
            </SuiBox>
          </SuiBox>
          <DataTable
            loading={status === "loading"}
            table={data}
            canSearch
            fetch={handleUpdate}
            totalEntries={ordersTotal}
            totalPages={totalPages}
            customFilters={filters}
            couponFilters={couponsList}
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default OrdersPage;
