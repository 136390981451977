export default {
  formId: "text-widget",
  formField: {
    column: {
      name: "tabs",
      label: "2 tabs",
      type: "checkbox",
      paired: true,
    },
    collapsable: {
      name: "collapsable",
      label: "collapsable gallery",
      type: "checkbox",
      paired: true,
    },
    title: {
      name: "title",
      label: "Title",
      type: "text",
    },
    menuTitle: {
      name: "menu_title",
      label: "Menu Title",
      type: "text",
    },
    placeHolder0: {
      type: "placeholder",
      placeholder: "Gallery 1",
    },
    label: {
      name: "galleries[0].label",
      label: "label",
      type: "text",
      group: 1,
    },
    description: {
      name: "galleries[0].description",
      label: "description",
      type: "text",
      group: 1,
    },
    text: {
      name: "galleries[0].text",
      label: "gallery text",
      type: "html",
      group: 1,
    },
    images: {
      name: "galleries[0].images",
      label: "gallery",
      type: "gallery",
      galleryIndex: 0,
      group: 1,
    },
    placeHolder1: {
      type: "placeholder",
      placeholder: "Gallery 2",
      dependant: "tabs",
    },
    label1: {
      name: "galleries[1].label",
      label: "label",
      type: "text",
      dependant: "tabs",
      group: 2,
    },
    description1: {
      name: "galleries[1].description",
      label: "description",
      type: "text",
      dependant: "tabs",
      group: 2,
    },
    text1: {
      name: "galleries[1].text",
      label: "gallery text",
      type: "html",
      dependant: "tabs",
      group: 2,
    },
    images1: {
      name: "galleries[1].images",
      label: "gallery",
      type: "gallery",
      dependant: "tabs",
      galleryIndex: 1,
      group: 2,
    },
    bottomLinkText: {
      name: "bottom_link_text",
      label: "bottom link label",
      type: "text",
    },
    bottomLinkUrl: {
      name: "bottom_link_url",
      label: "bottom link url",
      type: "text",
    },
  },
};
