/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// NewUser page components
import React from "react";
import FieldsWrapper from "../../../../../../components/FieldsWrapper";

// FIXME: add dual subscription checkbox
function EventInfo({ formField }) {
  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h5" fontWeight="bold">
          Create New Event
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <FieldsWrapper fields={Object.values(formField)} />
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for EventInfo
EventInfo.propTypes = {
  formField: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EventInfo;
