/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import WidgetCollapse from "../../Collapse/WidgetCollapse";
import SuiBox from "../../SuiBox";
import initialValues from "./schema/initialValues";
import form from "./schema/form";
import SuiButton from "../../SuiButton";
import FieldsWrapper from "../../FieldsWrapper";

const empty = {
  data: undefined,
  order: undefined,
  type: undefined,
};

const ListWidget = ({ widget = empty, onSubmit, onDelete, disabled }) => {
  const { data, order, type } = widget;
  const { formId, formField } = form;

  const handleSubmit = (values, actions) => {
    onSubmit(
      {
        widget: {
          data: values,
          order: order ?? null,
          type: type ?? null,
        },
        widgetId: values.id ?? null,
      },
      actions
    );
  };

  const handleDelete = (values) => {
    onDelete({ data: values, order: order ?? null, type: type ?? null });
  };
  return (
    <Formik
      initialValues={data || initialValues}
      // validationSchema={currentValidation}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting }) => (
        <Form id={formId} autoComplete="off">
          <WidgetCollapse
            title={`List Widget${values.title ? ` - ${values.title}` : ""}`}
            disabled={disabled}
            onDelete={() => handleDelete(values)}
          >
            <SuiBox mt={1.625}>
              <Grid container>
                <FieldsWrapper fields={Object.values(formField)} />
              </Grid>
              <SuiBox mt={5}>
                <SuiButton
                  disabled={isSubmitting}
                  variant="gradient"
                  buttonColor="dark"
                  type="submit"
                >
                  save
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </WidgetCollapse>
        </Form>
      )}
    </Formik>
  );
};

export default ListWidget;
