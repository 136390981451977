import schema from "./form";

const {
  formField: { pageUrl, imageLabel, image },
} = schema;

export default {
  [pageUrl.name]: "",
  [`${pageUrl.name}_en`]: "",
  [imageLabel.name]: "",
  [`${imageLabel.name}_en`]: "",
  [image.name]: "",
  [`${image.name}_en`]: "",
};
