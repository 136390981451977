import axiosInstanceProxy from "./config";

export const getDonationsRequest = ({ search, from, to, sort, order, totalPerPage, page }) =>
  axiosInstanceProxy.get("/admin/donations", {
    params: {
      search,
      from,
      to,
      sort,
      order,
      totalPerPage,
      page,
    },
  });
