import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createUserRequest,
  deleteUserRequest,
  getUserRequest,
  getUsersRequest,
  updateUserRequest,
} from "../../http/userRequests";

const initialState = {
  users: [],
};

export const getUsers = createAsyncThunk("usersReducer/getUsers", async (params) => {
  const res = await getUsersRequest(params);
  return res;
});

export const getUser = createAsyncThunk("usersReducer/getUser", async (params) => {
  const res = await getUserRequest(params);
  return res;
});

export const createUser = createAsyncThunk("usersReducer/createUser", async (params) => {
  const res = await createUserRequest(params);
  return res;
});

export const updateUser = createAsyncThunk("usersReducer/updateUser", async (params) => {
  const res = await updateUserRequest(params);
  return res;
});

export const deleteUser = createAsyncThunk("usersReducer/deleteUser", async (params) => {
  const res = await deleteUserRequest(params);
  return res;
});

const usersSlice = createSlice({
  name: "usersReducer",
  initialState,
  reducers: {
    setUsers: (state, action) => ({ ...state, users: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.users = payload.data.data;
    });
  },
});

export const { setUsers } = usersSlice.actions;
export default usersSlice.reducer;
