/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import { Card, Grid, Icon, Modal } from "@mui/material";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import SuiBox from "../../../SuiBox";
import SuiTypography from "../../../SuiTypography";
import FormField from "../../../FormField";
import SuiButton from "../../../SuiButton";
import FormDropzone from "../../../FormDropzone";
import LanguageToggle from "../../../FieldsWrapper/LanguageToggle";
import DragableList from "../../../DragableList/DragableList";

const blueprint = {
  caption: "",
  caption_en: "",
  image: "",
  image_en: "",
};

const ModalGallery = ({ open, setOpen, values, galleryIndex }) => {
  const [language, setLanguage] = useState("el");
  const handleNew = (e, arrayHelpers) => {
    arrayHelpers.push({ ...blueprint, image: e });
  };

  const images = useMemo(() => values.images, [values.images]);

  return (
    <Modal
      open={open}
      onBackdropClick={() => setOpen(false)}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <Card sx={{ width: "80%", maxHeight: "90vh", overflowY: "auto" }}>
        <SuiBox p={2}>
          <SuiTypography variant="h5">Edit Gallery</SuiTypography>
        </SuiBox>

        <SuiBox display="flex" flexDirection="column" p={3}>
          <LanguageToggle language={language} setLanguage={setLanguage} />
          <FieldArray
            name={`galleries[${galleryIndex}].images`}
            render={(arrayHelpers) => (
              <>
                <DragableList
                  onSave={(list, from, to) => {
                    arrayHelpers.move(from, to);
                  }}
                  items={images}
                  renderItem={({ item: el, index: i, disabled }) => (
                    <SuiBox
                      sx={{
                        boxShadow: disabled
                          ? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                          : undefined,
                        borderRadius: "4px",
                      }}
                      my={2}
                      p={1}
                      backgroundColor="white"
                    >
                      <Grid key={el.image} container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={2}>
                          <img src={el.image} alt={`gallery-img-${i}`} width="100%" height="auto" />
                        </Grid>
                        <Grid item xs={12} sm>
                          <FormField
                            name={`galleries[${galleryIndex}].images[${i}].caption${
                              language === "en" ? "_en" : ""
                            }`}
                            label="Caption"
                            disabled={disabled}
                            // onChange={(e) => setFieldValue(`galleries.${[i]}.caption`, e)}
                          />
                        </Grid>
                        <Grid item sm={1} mt={2}>
                          <SuiBox display="flex" alignItems="flex-end">
                            <Icon onClick={() => !disabled && arrayHelpers.remove(i)}>delete</Icon>
                          </SuiBox>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  )}
                />

                {values?.images?.length > 0 && (
                  <SuiBox
                    sx={{ alignSelf: "center", width: "80%", height: "1px" }}
                    backgroundColor="dark"
                    m={5}
                  />
                )}
                <SuiTypography>Add new item</SuiTypography>
                <SuiBox display="flex" alignItems="center" justifyContent="center" width="50%">
                  <FormDropzone getImageUrl={(e) => handleNew(e, arrayHelpers)} />
                </SuiBox>
                <SuiBox display="flex" justifyContent="flex-end" mt={10}>
                  <SuiButton buttonColor="dark" onClick={() => setOpen(false)}>
                    Save
                  </SuiButton>
                </SuiBox>
              </>
            )}
          />
        </SuiBox>
      </Card>
    </Modal>
  );
};

ModalGallery.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalGallery;
