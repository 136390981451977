/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// NewUser page components
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SuiButton from "../../../../../../components/SuiButton";
import {
  exhibitFormInitialValues,
  exhibitionFormInitialValues,
  genericFormInitialValues,
  publicationFormInitialValues,
  eventFromInitialValues,
} from "../schemas/initialValues";
import {
  exhibitForm,
  exhibitionForm,
  genericPageForm,
  publicationForm,
  eventForm,
} from "../schemas/form";
import { createPage, updatePage } from "../../../../../../redux/reducers/cmsSlice";
import useToastContext from "../../../../../../context/ToastContext";
import FieldsWrapper from "../../../../../../components/FieldsWrapper";
import { transformEventFormValues } from "../../../../events/events-list/new-event";

// eslint-disable-next-line react/prop-types
function Settings({ setStepIndex, pageType, edit }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const showToast = useToastContext();
  const [formValues, setFormValues] = useState({});
  const { culture, kind, material, period } = useSelector(
    (state) => state.optionsReducer.exhibitOptions
  );

  const handleBack = () => {
    if (edit) {
      setStepIndex(false);
    } else {
      setStepIndex((prev) => prev - 1);
    }
  };

  const transformOptions = useCallback(
    (options) => Object.entries(options).map(([k, v]) => ({ label: v, value: k })),
    []
  );

  const form = useMemo(() => {
    switch (pageType?.toLowerCase()) {
      case "exhibit":
        return {
          data: {
            ...exhibitForm,
            formField: {
              ...exhibitForm.formField,

              kind: { ...exhibitForm.formField.kind, options: transformOptions(kind) },
              material: { ...exhibitForm.formField.material, options: transformOptions(material) },
              period: { ...exhibitForm.formField.period, options: transformOptions(period) },
              culture: { ...exhibitForm.formField.culture, options: transformOptions(culture) },
            },
          },
          initialValues: exhibitFormInitialValues(),
        };
      case "exhibition":
        return { data: exhibitionForm, initialValues: exhibitionFormInitialValues() };
      case "publication":
        return { data: publicationForm, initialValues: publicationFormInitialValues() };
      case "generic":
        return { data: genericPageForm, initialValues: genericFormInitialValues() };
      case "pevent":
        return { data: eventForm, initialValues: eventFromInitialValues() };
      default:
        return { data: { formId: null, formField: {} }, initialValues: {} };
    }
  }, [pageType]);

  useEffect(() => {
    if (edit) {
      setFormValues(edit);
    } else {
      setFormValues(form.initialValues);
    }
  }, []);

  // eslint-disable-next-line no-shadow
  const handleSubmit = async (values, actions) => {
    try {
      if (edit) {
        let payload = values;
        if (values.pageType === "pevent") {
          payload = transformEventFormValues(values, values.soevent_id);
        }
        await dispatch(updatePage(payload));
        setStepIndex(false);
      } else {
        const res = await dispatch(createPage(values)).unwrap();
        actions.setSubmitting(false);
        actions.resetForm();
        history.push(`/cms/edit-page/${res.data.data.id}`, { id: res.data.data.id });
      }
    } catch (e) {
      showToast({
        type: "error",
        title: "Error",
        content: e.message,
      });
    }
  };

  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h5" fontWeight="bold">
          {edit ? "Edit Page" : "Create New Page"}
        </SuiTypography>
      </SuiBox>
      <Formik initialValues={formValues} onSubmit={handleSubmit} enableReinitialize>
        {({ isSubmitting }) => (
          <Form id={form?.data?.formId}>
            <SuiBox mt={1.625}>
              <Grid container spacing={1}>
                <FieldsWrapper fields={Object.values(form?.data?.formField)} />
              </Grid>
            </SuiBox>
            <SuiBox mt={6} width="100%" display="flex" justifyContent="space-between">
              <SuiButton variant="gradient" buttonColor="light" onClick={handleBack}>
                back
              </SuiButton>
              <SuiButton
                disabled={isSubmitting}
                variant="gradient"
                buttonColor="dark"
                type="submit"
              >
                {isSubmitting ? (edit ? "saving..." : "creating...") : edit ? "save" : "create"}
              </SuiButton>
            </SuiBox>
          </Form>
        )}
      </Formik>
    </SuiBox>
  );
}

export default Settings;
