/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dataTableData from "./data/dataTableData";
import AddButton from "../../../../../components/addButton";
import { getBanners, reorderBanners } from "../../../../../redux/reducers/cmsSlice";
import useToastContext from "../../../../../context/ToastContext";

function ShopBannersPage() {
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.cmsReducer.banners);
  const bannersTotal = useSelector((state) => state.cmsReducer.bannersTotal);
  const bannersTotalPages = useSelector((state) => state.cmsReducer.bannersTotalPages);
  const status = useSelector((state) => state.cmsReducer.status);
  const showToast = useToastContext();
  const [data, setData] = useState(dataTableData);

  useEffect(() => {
    if (banners) setData((prev) => ({ ...prev, rows: banners }));
  }, [banners]);

  const handleUpdate = (params) => dispatch(getBanners({ ...params }));

  const handleReorder = async (id, index) => {
    try {
      await dispatch(reorderBanners({ id, index })).unwrap();
      showToast({
        type: "success",
        title: "Banner reordered successfully",
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "There was an error",
        content: e?.message || e,
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Shop Banners List
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {bannersTotal} banners
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <AddButton component={Link} to="/cms/new-banner" />
            </SuiBox>
          </SuiBox>

          <DataTable
            table={data}
            canSearch
            // ={false}
            noPagination
            showTotalEntries={false}
            totalEntries={0}
            fetch={handleUpdate}
            totalPages={bannersTotalPages}
            handleReorderRow={handleReorder}
            loading={status === "loading"}
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default ShopBannersPage;
