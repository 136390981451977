/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components

// OrderDetails page components
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Header from "./components/Header";
import BillingInformation from "./components/BillingInformation";
import OrderSummary from "./components/OrderSummary";
import DataTable from "../../../../../examples/Tables/DataTable";

function OrderDetails({ data, dataTable, loading, onStatusChange, onClose }) {
  return (
    <Card
      sx={{
        width: "fit-content",
        minWidth: "70%",
        minHeight: "50vh",
        maxHeight: "94vh",
        overflowY: "auto",
      }}
    >
      <SuiBox pt={2} px={2}>
        <Header data={data} loading={loading} onStatusChange={onStatusChange} />
      </SuiBox>
      <Divider />
      <SuiBox pt={1} pb={3} px={2}>
        <SuiBox mb={3}>
          <DataTable
            table={dataTable}
            loading={loading}
            fetch={() => {}}
            isSorted={false}
            noShorting
            noPagination
            entriesPerPage={false}
            showTotalEntries={false}
          />
        </SuiBox>
        <Divider />
        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <SuiBox>
                <BillingInformation
                  data={data?.addresses?.billing}
                  invoice={data?.invoice}
                  loading={loading}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SuiBox>
                <BillingInformation data={data?.addresses?.shipping} loading={loading} />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={3} className="ml-auto">
              <OrderSummary data={data} loading={loading} />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <SuiBox sx={{ position: "absolute", top: 4, right: 10 }}>
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
          <Icon fontSize="small">close</Icon>
        </IconButton>
      </SuiBox>
    </Card>
  );
}

export default OrderDetails;
