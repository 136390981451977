/* eslint-disable react/prop-types */
import { FieldArray, useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";
import React from "react";
import moment from "moment/moment";
import FormDropzone from "../FormDropzone";
import FormField, {
  CheckboxFormField,
  DatepickerFormField,
  DropdownFormField,
  EditorFormField,
  RadioFormField,
  SearchDropdownFormField,
  SlugFormField,
} from "../FormField";
import SuiTypography from "../SuiTypography";
import Gallery from "../widgets/GalleryWidget/components/Gallery";
import SuiBox from "../SuiBox";
import SuiButton from "../SuiButton";
import { InputWithButton } from "../SuiInput";
import SpecialWidgetField from "./SpecialWidgetField";
import SponsorImages from "../widgets/SponsorsWidget/components/SponsorImages";

const groupStyle = {
  borderLeft: "solid",
  borderLeftWidth: "1px",
  borderLeftColor: "lightgray",
  pl: 2,
};

export const CustomField = ({ item, language = "el" }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    setFieldValue(item.name, e);
  };

  const handleDateChange = (e) => {
    if (Array.isArray(e)) {
      setFieldValue(
        item.name,
        e?.map((el) => moment(el).toISOString(true))
      );
    } else {
      setFieldValue(item.name, e);
    }
  };

  if (item.dependant && !values[item.dependant]) return <></>;

  switch (item.type) {
    case "placeholder":
      return (
        <Grid key={item.placeholder} item xs={12} sm={item.paired ? 6 : 12}>
          <SuiTypography>{item.placeholder}</SuiTypography>
        </Grid>
      );
    case "featured":
    case "image":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <FormDropzone
            value={values[language === "en" ? `${item.name}_en` : item.name]}
            getImageUrl={(e) => setFieldValue(language === "en" ? `${item.name}_en` : item.name, e)}
          />
        </Grid>
      );
    case "image-multiple":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <FormDropzone
            value={values[language === "en" ? `${item.name}_en` : item.name]}
            getImageUrl={(e) => setFieldValue(language === "en" ? `${item.name}_en` : item.name, e)}
            onDelete
            multiple
          />
        </Grid>
      );
    case "slug":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <SlugFormField
            type={item.type}
            label={item.label}
            name={item.name}
            value={values[item.name]}
            dependentValue={values[item.dependentValue]}
            dependentName={item.dependentValue}
            setFieldValue={(e) => setFieldValue(item.name, e)}
            isEdit={!!values?.id}
            // onChange={handleChange}
          />
        </Grid>
      );
    case "text":
    case "number":
    case "textarea":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <FormField
            // as={FormField}
            name={item.type !== "number" && language === "en" ? `${item.name}_en` : item.name}
            disabled={item.disabled}
            sx={item.multiline && { minHeight: "5rem" }}
            multiline={item.multiline}
            type={item.type}
            label={item.label}
            // value={value}
            placeholder={item.placeholder}
            // onChange={(e) => setFieldValue(item.name, e)}
            // onChange={handleChange}
          />
        </Grid>
      );
    case "richtext":
    case "html":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <EditorFormField
            label={item.label}
            name={language === "en" ? `${item.name}_en` : item.name}
            // value={value}
            // setValue={(e) => {
            //   setFieldValue(item.name, e);
            // }}
          />
        </Grid>
      );
    case "select":
    case "radio":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <RadioFormField
            label={item.label}
            name={item.name}
            options={item.options}
            value={values[item.name]}
            // setValue={(e) => setFieldValue(item.name, e)}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <DatepickerFormField
            label={item.label}
            name={item.name}
            value={values[item.name]}
            setValue={handleDateChange}
            options={item.options}
          />
        </Grid>
      );
    case "checkbox":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <CheckboxFormField
            label={item.label}
            name={item.name}
            onChange={(e) => {
              setFieldValue(item.name, e.target.checked);
            }}
          />
        </Grid>
      );
    case "checkboxGroup":
      return (
        <Grid item xs={12} sx={item.group ? { ...groupStyle } : undefined}>
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {item.label}
          </SuiTypography>
          <FieldArray name={item.name}>
            {() => (
              <Grid container pl={3}>
                {item?.options?.map((el) => (
                  <Grid key={el.id} item xs={12} sm={6} md={3}>
                    <CheckboxFormField
                      label={el.name}
                      name={`${item.name}`}
                      value={el.id}
                      checked={values[item.name]?.includes(el.id.toString())}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </FieldArray>
        </Grid>
      );
    case "dropdown":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <DropdownFormField
            label={item.label}
            name={item.name}
            value={values[item.name]}
            setValue={(e) => {
              setFieldValue(item.name, e.value);
            }}
            options={item.options}
          />
        </Grid>
      );
    case "searchDropdown":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <SearchDropdownFormField
            label={item.label}
            name={item.name}
            value={values[item.name]}
            setValue={handleChange}
            isMulti={item.isMulti}
            getOptions={item.getOptions}
            defaultOptions={false}
            cacheOptions={false}
          />
        </Grid>
      );
    case "gallery":
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={item.paired ? 6 : 12}
          sx={item.group ? { ...groupStyle } : undefined}
        >
          <Gallery
            key={item.id}
            item={item}
            value={values.galleries[item.galleryIndex]}
            language={language}
          />
        </Grid>
      );
    case "participations":
      return (
        <FieldArray name={item.name}>
          {(arrayOptions) => (
            <SuiBox pl={2} width="100%" height="100%">
              <SuiBox>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  participations
                </SuiTypography>
              </SuiBox>
              {values[item.name]?.map((el, i) => (
                <Grid container spacing={4} pl={2}>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormField
                          type="number"
                          label="participation year"
                          name={`${item.name}[${i}].year`}
                          value={values[item.name][i].year}
                          onChange={(e) => setFieldValue(`${[item.name]}[${i}].year`, e)}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <FormField
                          type="text"
                          label="participation title"
                          name={`${item.name}[${i}].title`}
                          value={values[item.name][i].title}
                          onChange={(e) => setFieldValue(`${[item.name]}[${i}].title`, e)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <CheckboxFormField
                        label="promise"
                        name={`${item.name}[${i}].promise`}
                        value={values[item.name][i].promise}
                        setValue={(e) => setFieldValue(`${[item.name]}[${i}].promise`, e)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <SuiBox
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      paddingBottom={3}
                      onClick={() => arrayOptions.remove(i)}
                    >
                      <Icon>delete</Icon>
                    </SuiBox>
                  </Grid>
                </Grid>
              ))}
              <SuiBox>
                <SuiButton
                  buttonColor="secondary"
                  onClick={() => arrayOptions.push({ year: "", title: "", promise: false })}
                >
                  add participation
                </SuiButton>
              </SuiBox>
            </SuiBox>
          )}
        </FieldArray>
      );
    case "category":
      return (
        <>
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Categories
            </SuiTypography>
          </SuiBox>
          <FieldArray name={item.name}>
            {(arrayOptions) => (
              <>
                {values.subcategories?.length > 0 &&
                  values.subcategories.map((el, i) => (
                    <Grid container spacing={2} key={el?.id || el || i} sx={{ paddingLeft: 3 }}>
                      <Grid item xs={12} sm={5.5}>
                        <DropdownFormField
                          exactValue
                          label="Category"
                          name="category_id"
                          value={values[item.name][i].category_id}
                          setValue={(e) => {
                            setFieldValue(`${item.name}.[${i}].category_id`, e.value);
                            setFieldValue(`${item.name}.[${i}].id`, undefined);
                          }}
                          options={item.options}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5.5}>
                        <DropdownFormField
                          exactValue
                          label="Subcategory"
                          name="id"
                          value={values[item.name][i].id}
                          setValue={(e) => {
                            setFieldValue(`${item.name}.[${i}].id`, e.value);
                          }}
                          options={[
                            { label: "Please Select", value: undefined },
                            ...(item?.options?.find(
                              (e) => e.value === values[item.name][i].category_id
                            )?.subcategories ?? []),
                          ]}
                        />
                      </Grid>
                      <Grid item>
                        <SuiBox
                          height="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          paddingTop={2}
                          sx={{ cursor: "pointer" }}
                          onClick={() => arrayOptions.remove(i)}
                        >
                          <Icon>delete</Icon>
                        </SuiBox>
                      </Grid>
                    </Grid>
                  ))}
                <Grid item>
                  <SuiButton
                    buttonColor="light"
                    sx={{ marginBottom: 3, marginTop: 1 }}
                    onClick={() => arrayOptions.push({ category_id: undefined, id: undefined })}
                  >
                    Add Category
                  </SuiButton>
                </Grid>
              </>
            )}
          </FieldArray>
        </>
      );
    case "attributes":
      return (
        <>
          <FieldArray name={item.name}>
            {(arrayOptions) => (
              <>
                {values[item.name]?.length > 0 &&
                  values[item.name]?.map((el, i) => (
                    <Grid container spacing={2} key={el?.id || el?.title || i}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          label={item.parentLabel}
                          name={`${item.name}[${i}]${item.parentName}${
                            language === "en" ? "_en" : ""
                          }`}
                          placeholder={item.parentLabel}
                        />
                        <SuiButton
                          variant="text"
                          buttonColor="error"
                          endIcon={<Icon>delete</Icon>}
                          onClick={() => arrayOptions.remove(i)}
                        >
                          remove attribute
                        </SuiButton>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FieldArray name={`${item.name}[${i}]${item.childArrayName}`}>
                          {(nestedArrayOptions) => (
                            <>
                              {values[item.name]?.[i]?.[item.childArrayName]?.length > 0 &&
                                values[item.name]?.[i]?.[item.childArrayName].map(
                                  (childEl, childIndex) => {
                                    const name = `${item.name}[${i}]${
                                      item.childArrayName
                                    }[${childIndex}]${item.childName}${
                                      language === "en" ? "_en" : ""
                                    }`;
                                    return (
                                      <Grid container>
                                        <Grid item xs={11}>
                                          <FormField label={item.childLabel} name={name} />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <SuiBox
                                            height="100%"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            paddingTop={2}
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => nestedArrayOptions.remove(childIndex)}
                                          >
                                            <Icon>delete</Icon>
                                          </SuiBox>
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                )}
                              <SuiButton
                                sx={{ marginBottom: 4, marginTop: 1 }}
                                startIcon={<Icon>add</Icon>}
                                onClick={() => nestedArrayOptions.push({ [item.childName]: "" })}
                              >
                                add Value
                              </SuiButton>
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                    </Grid>
                  ))}
                <SuiButton
                  size="large"
                  buttonColor="dark"
                  sx={{ marginBottom: 2 }}
                  onClick={() =>
                    arrayOptions.push({
                      [item.parentName]: "",
                      [item.childArrayName]: [{ [item.childName]: "" }],
                    })
                  }
                >
                  add attribute
                </SuiButton>
                <SuiBox width="100%" height="1px" backgroundColor="#00000030" marginBottom={2} />
              </>
            )}
          </FieldArray>
        </>
      );
    case "customOptions":
      return (
        <Grid key={item.id} item xs={12} sm={item.paired ? 6 : 12} mb={2}>
          <FieldArray
            name={language === "en" && values[`${item.name}_en`] ? `${item.name}_en` : item.name}
          >
            {(arrayHelpers) => (
              <>
                <InputWithButton
                  label={item.label}
                  type={item.innerType}
                  setValue={(e) => arrayHelpers.push(e)}
                />
                <SuiBox display="flex" flexWrap="wrap">
                  {values[
                    (!item.inputType || item.inputType !== "number") && language === "en"
                      ? `${item.name}_en`
                      : item.name
                  ]?.map((el, i) => (
                    <SuiBox
                      width="max-content"
                      px={1}
                      py={0.6}
                      mr={2}
                      mb={1}
                      display="flex"
                      alignItems="center"
                      sx={{ border: "1px solid #344767", borderRadius: "8px" }}
                    >
                      <SuiTypography variant="subtitle2" mr={1}>
                        {el}
                      </SuiTypography>
                      <SuiBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ cursor: "pointer" }}
                        onClick={() => arrayHelpers.remove(i)}
                      >
                        <Icon>close</Icon>
                      </SuiBox>
                    </SuiBox>
                  ))}
                </SuiBox>
              </>
            )}
          </FieldArray>
        </Grid>
      );
    case "widgetSpecial":
      return <SpecialWidgetField item={item} />;
    case "sponsor":
      return <SponsorImages value={values[item.name]} />;
    default:
      return <></>;
  }
};
