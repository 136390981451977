import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Skeleton } from "@mui/material";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import SuiBox from "../../../../../components/SuiBox";
import { clearShowPage, showPage, updatePage } from "../../../../../redux/reducers/cmsSlice";
import { renderWidgets } from "../new-page/components/utils";
import SuiButton from "../../../../../components/SuiButton";
import AddWidgetModal from "./components/AddWidgetModal";
import { getWidget } from "../../../../../components/widgets/utils";
import DragableList from "../../../../../components/DragableList/DragableList";
import useToastContext from "../../../../../context/ToastContext";
import DeleteModal from "../../../../../components/DeleteModal";
import EditPageComponent from "./components/EditPageComponent";

const getUpdatedPage = (page, widget) => {
  const index = page?.widgets?.findIndex((el) => {
    if (widget.data.id || widget.data.id === 0) return el.data.id === widget.data.id;
    return el.order === widget.order;
  });
  const temp = [...page.widgets];
  temp[index] = widget;
  return { ...page, widgets: temp };
};

const getDeletedWidgetPage = (page, widget) => {
  const index = page?.widgets?.findIndex(
    (el) =>
      // if (widget.data.id || widget.data.id === 0) return el.data.id === widget.data.id;
      el.order === widget.order
  );
  const temp = [...page.widgets];
  temp.splice(index, 1);
  return { ...page, widgets: temp };
};

const EditPage = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [pageData, setPageData] = useState();
  const [addWidgetModal, setAddWidgetModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const status = useSelector((state) => state.cmsReducer.status);
  const page = useSelector((state) => state.cmsReducer.showPage);
  const showToast = useToastContext();

  useEffect(() => {
    if (id) {
      dispatch(showPage(id));
    }
    return () => dispatch(clearShowPage());
  }, [id]);

  useEffect(() => {
    if (page) {
      setPageData(page);
    }
  }, [page]);

  const handleAddWidget = (type) => {
    const newWidget = getWidget(type, pageData?.widgets?.length);
    setPageData((prev) => ({ ...prev, widgets: [...prev.widgets, newWidget] }));
    setAddWidgetModal(false);
  };

  const handleSubmit = async ({ widget, widgetId }, actions) => {
    actions.setSubmitting(true);
    const data = getUpdatedPage(pageData, widget);
    await dispatch(updatePage(data));
    actions.setSubmitting(false);
    showToast({
      type: "success",
      title: "Widget saved successfully!",
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    const widget = { ...deleteModal };
    const data = getDeletedWidgetPage(pageData, widget); // if (widget.data.id) {
    try {
      await dispatch(updatePage(data)).unwrap();
      showToast({
        type: "success",
        title: "Widget deleted successfully!",
      });
    } catch (err) {
      showToast({
        type: "error",
        title: err.message ?? err,
      });
    } finally {
      setLoading(false);
      setDeleteModal(null);
    }
  };

  const handleReorderComplete = async (a, b, c, list) => {
    const data = list.map((el, i) => ({
      ...el,
      order: i,
    }));
    setPageData((p) => ({ ...p, widgets: data }));
    await dispatch(updatePage({ ...pageData, widgets: data }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card p={5}>
          <SuiBox mt={6} mb={5} pl={4}>
            {status === "idle" || status === "loading" || !pageData ? (
              <SuiBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <SuiBox>
                  <Skeleton variant="text" width={450} height={60} />
                  <Skeleton variant="text" width={430} />
                  <Skeleton variant="text" width={400} />
                  <Skeleton variant="text" width={430} />
                  <Skeleton variant="text" width={400} />
                  <Skeleton variant="text" width={430} />
                  <Skeleton variant="text" width={400} />
                </SuiBox>
                <Skeleton variant="rectangular" width={200} height={180} />
                <SuiBox />
              </SuiBox>
            ) : (
              <EditPageComponent item={pageData}>
                <>
                  <Grid item sm={12} md={8} mt={5}>
                    <DragableList
                      onSave={handleReorderComplete}
                      items={pageData?.widgets ?? []}
                      renderItem={(el) =>
                        renderWidgets({
                          widget: { ...el.item },
                          onSubmit: handleSubmit,
                          onDelete: setDeleteModal,
                          page: pageData,
                          ...el,
                        })
                      }
                    />
                  </Grid>
                  <SuiBox mt={4}>
                    <SuiButton
                      size="large"
                      buttonColor="dark"
                      onClick={() => setAddWidgetModal(true)}
                    >
                      add widget
                    </SuiButton>
                  </SuiBox>
                </>
              </EditPageComponent>
            )}
          </SuiBox>
        </Card>
      </SuiBox>
      <AddWidgetModal
        open={addWidgetModal}
        setOpen={setAddWidgetModal}
        handleAddWidget={handleAddWidget}
      />
      <DeleteModal
        handleDelete={handleDelete}
        setModalOpen={setDeleteModal}
        modalOpen={!!deleteModal}
        loading={loading}
      />
    </DashboardLayout>
  );
};

export default EditPage;
