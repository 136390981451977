/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { eventForm, exhibitForm, exhibitionForm, genericPageForm, publicationForm } from "./form";

export const exhibitionFormInitialValues = () => {
  const {
    formField: {
      pageType,
      image,
      title,
      slug,
      description,
      label,
      dateText,
      date,
      type,
      showHomepage,
      showSuggested,
      showShop,
    },
  } = exhibitionForm;

  return {
    [pageType.name]: "exhibition",
    [image.name]: "",
    [`${image.name}_en`]: "",
    [title.name]: "",
    [`${title.name}_en`]: "",
    [slug.name]: "",
    [label.name]: "",
    [`${label.name}_en`]: "",
    [description.name]: "",
    [`${description.name}_en`]: "",
    [date.name]: [],
    [dateText.name]: "",
    [`${dateText.name}_en`]: "",
    [type.name]: "archaeological",
    [showSuggested.name]: false,
    [showHomepage.name]: false,
    [showShop.name]: false,
  };
};

export const exhibitFormInitialValues = () => {
  const {
    formField: {
      pageType,
      image,
      category,
      title,
      collectionNumber,
      slug,
      description,
      creator,
      dimensions,
      dateText,
      dateFrom,
      dateFromEra,
      dateTo,
      dateToEra,
      provenance,
      exhibitionGallery,
      kind,
      period,
      culture,
      material,
      participations,
    },
  } = exhibitForm;

  return {
    [pageType.name]: "exhibit",
    [image.name]: "",
    [`${image.name}_en`]: "",
    [category.name]: "",
    [title.name]: "",
    [`${title.name}_en`]: "",
    [slug.name]: "",
    [collectionNumber.name]: "",
    [description.name]: "",
    [`${description.name}_en`]: "",
    [creator.name]: "",
    [`${creator.name}_en`]: "",
    [dimensions.name]: "",
    [dateText.name]: "",
    [`${dateText.name}_en`]: "",
    [dateFrom.name]: "",
    [dateFromEra.name]: "",
    [dateTo.name]: "",
    [dateToEra.name]: "",
    [provenance.name]: "",
    [`${provenance.name}_en`]: "",
    [exhibitionGallery.name]: "",
    [kind.name]: "",
    [period.name]: "",
    [culture.name]: "",
    [material.name]: "",
    [participations.name]: [],
  };
};

export const publicationFormInitialValues = () => {
  const {
    formField: {
      pageType,
      image,
      slug,
      title,
      subtitle,
      writers,
      description,
      label,
      dimensions,
      pages,
      other,
      shopUrl,
      category,
    },
  } = publicationForm;
  return {
    [pageType.name]: "publication",
    [image.name]: "",
    [`${image.name}_en`]: "",
    [title.name]: "",
    [`${title.name}_en`]: "",
    [slug.name]: "",
    [subtitle.name]: "",
    [`${subtitle.name}_en`]: "",
    [writers.name]: "",
    [`${writers.name}_en`]: "",
    [description.name]: "",
    [`${description.name}_en`]: "",
    [label.name]: "",
    [`${label.name}_en`]: "",
    [dimensions.name]: "",
    [`${dimensions.name}_en`]: "",
    [pages.name]: "",
    [`${pages.name}_en`]: "",
    [other.name]: "",
    [`${other.name}_en`]: "",
    [shopUrl.name]: "",
    [`${shopUrl.name}_en`]: "",
    [category.name]: "",
  };
};

export const genericFormInitialValues = () => {
  const {
    formField: {
      pageType,
      image,
      title,
      subtitle,
      slug,
      summary,
      label,
      date,
      showHomepage,
      showSuggested,
      showShop,
      news,
      newsFriends,
      newsFriendsPublishDate,
      newsPatron,
      newsPatronPublishDate,
      blog,
      blogPublishDate,
      newsYoungFriends,
      newsYoungFriendsPublishDate,
      fullImage,
    },
  } = genericPageForm;
  return {
    [pageType.name]: "generic",
    [image.name]: "",
    [`${image.name}_en`]: "",
    [title.name]: "",
    [`${title.name}_en`]: "",
    [slug.name]: "",
    [subtitle.name]: "",
    [`${subtitle.name}_en`]: "",
    [summary.name]: "",
    [`${summary.name}_en`]: "",
    [label.name]: "",
    [`${label.name}_en`]: "",
    [date.name]: [],
    [showHomepage.name]: false,
    [showSuggested.name]: false,
    [news.name]: false,
    [showShop.name]: false,
    [newsFriends.name]: false,
    [newsFriendsPublishDate.name]: "",
    [newsPatron.name]: false,
    [newsPatronPublishDate.name]: "",
    [blog.name]: false,
    [blogPublishDate.name]: "",
    [newsYoungFriends.name]: false,
    [newsYoungFriendsPublishDate.name]: "",
    [fullImage.name]: false,
  };
};

export const eventFromInitialValues = () => {
  const {
    formField: {
      pageType,
      title,
      smallDescription,
      slug,
      label,
      dateText,
      date,
      events,
      eventType,
      showHomepage,
      showSuggested,
      image,
      fullImage,
    },
  } = eventForm;
  return {
    [pageType.name]: "pevent",
    [image.name]: "",
    [`${image.name}_en`]: "",
    [title.name]: "",
    [`${title.name}_en`]: "",
    [slug.name]: "",
    [smallDescription.name]: "",
    [`${smallDescription.name}_en`]: "",
    [label.name]: "",
    [`${label.name}_en`]: "",
    [dateText.name]: "",
    [`${dateText.name}_en`]: "",
    [date.name]: "",
    [events.name]: [],
    [eventType.name]: "",
    [showHomepage.name]: false,
    [showSuggested.name]: false,
    [fullImage.name]: false,
  };
};
