// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import dataTableData from "./data/dataTableData";
import { getEventPages } from "../../../../redux/reducers/eventsSlice";

function EventPagesPage() {
  const dispatch = useDispatch();
  const eventPagesData = useSelector((state) => state.eventsReducer.eventPages);
  const totalEntries = useSelector((state) => state.eventsReducer.eventPagesTotal);
  const totalPages = useSelector((state) => state.eventsReducer.eventPagesTotalPages);
  const status = useSelector((state) => state.eventsReducer.status);
  const [data, setData] = useState(dataTableData);

  useEffect(() => {
    if (eventPagesData) setData((prev) => ({ ...prev, rows: eventPagesData }));
  }, [eventPagesData]);

  const handleUpdate = useCallback((params) => {
    dispatch(getEventPages(params));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Event Pages
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {totalEntries} pages
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </SuiBox>

          <DataTable
            loading={status === "loading"}
            table={data}
            totalEntries={totalEntries}
            totalPages={totalPages}
            fetch={handleUpdate}
            canSearch
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default EventPagesPage;
