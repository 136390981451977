import schema from "./form";

const {
  formField: { pageUrl, imageLabel, imageTitle, imageDescription, image },
} = schema;

export default {
  [pageUrl.name]: "",
  [`${pageUrl}_en`.name]: "",
  [imageTitle.name]: "",
  [`${imageTitle}_en`.name]: "",
  [imageLabel.name]: "",
  [`${imageLabel}_en`.name]: "",
  [imageDescription.name]: "",
  [`${imageDescription}_en`.name]: "",
  [image.name]: "",
  [`${image}_en`.name]: "",
};
