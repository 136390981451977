/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components

// Images
import { PageActionCell } from "../../../../../components/TableComponents/ActionCell";
import { PageLinkCell } from "../../../../../components/TableComponents/LinkCell";
import DefaultCell, { DateCell } from "../../../../../components/TableComponents/DefaultCell";

export default {
  columns: [
    {
      Header: "title",
      accessor: "title",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category",
      accessor: "type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "URL",
      accessor: "slug",
      Cell: ({ value }) => <PageLinkCell value={value} />,
    },
    {
      Header: "Date Created",
      accessor: "created",
      Cell: ({ value }) => <DateCell value={value} />,
    },
    {
      Header: "action",
      accessor: "action",
      Cell: ({ row, state }) => <PageActionCell value={row.original} state={state} />,
    },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
};
