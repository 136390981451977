export default {
  button: "page",
  formId: "list-widget-modal",
  formField: {
    pageUrl: {
      name: "url",
      label: "Insert page url",
      type: "text",
    },
    imageTitle: {
      name: "title",
      label: "image title",
      type: "text",
      dependant: "image",
    },
    imageLabel: {
      name: "label",
      label: "image label",
      type: "text",
      dependant: "image",
    },
    imageDescription: {
      name: "description",
      label: "image description",
      type: "textarea",
      multiline: true,
      dependant: "image",
    },
    image: {
      name: "image",
      type: "image",
    },
  },
};
