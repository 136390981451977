import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import SuiButton from "../SuiButton";

// eslint-disable-next-line react/prop-types
const DeleteModal = ({ modalOpen = false, setModalOpen, loading = false, handleDelete }) => (
  <Dialog
    open={modalOpen}
    maxWidth="sm"
    fullWidth
    onClose={() => setModalOpen(false)}
    onBackdropClick={() => setModalOpen(false)}
  >
    <DialogTitle>Confirm the action</DialogTitle>
    <Box position="absolute" top={0} right={0} onClick={() => setModalOpen(false)}>
      <IconButton>
        <Close />
      </IconButton>
    </Box>
    <DialogContent>
      <DialogContentText>Are you sure you want to delete this item?</DialogContentText>
    </DialogContent>
    <DialogActions>
      <SuiButton buttonColor="light" variant="gradient" onClick={() => setModalOpen(false)}>
        Cancel
      </SuiButton>
      <SuiButton disabled={loading} buttonColor="dark" variant="gradient" onClick={handleDelete}>
        {loading ? "Deleting..." : "Delete"}
      </SuiButton>
    </DialogActions>
  </Dialog>
);

export default DeleteModal;
