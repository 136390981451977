import * as Yup from "yup";

export default Yup.object().shape({
  weight_threshold: Yup.string().required("Required"),
  shipping_costs: Yup.array().of(
    Yup.object().shape({
      cost: Yup.string().required("Required"),
      zone_id: Yup.number(),
      label: Yup.string(),
    })
  ),
});
