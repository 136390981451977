/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";

// react-router-dom components
import { useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/cycladic_admin_bg.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, login } from "../../../../redux/reducers/authSlice";

// import { login } from "../../../../redux/Middleware/AuthThunk";

function Basic() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.authReducer?.userLoggedIn);
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  useEffect(() => {}, [username, password]);

  const handleSubmit = async () => {
    try {
      const res = await dispatch(login({ username, password })).unwrap();
    } catch (e) {
      if (e.message === "Unauthorized") {
        setErrorMessage("Invalid username or password");
        setError(true);
      }
      return;
    }

    await dispatch(getProfile());
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/dashboard");
    }
    return () => {
      setError(false);
      setErrorMessage("");
    };
  }, [isLoggedIn]);

  return (
    <BasicLayout
      title="Museum of Cycladic Arts"
      description="Welcome to the admin panel of MoCA. In case of lost credentials please contact your system administrator."
      image="https://s3-eu-west-1.amazonaws.com/cycladic-museum/H6ASDi1553014366.jpg"
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            Sign in
          </SuiTypography>
        </SuiBox>
        <SuiBox p={3}>
          <SuiBox component="form" role="form">
            <SuiBox mb={2}>
              <SuiInput
                autoComplete="username"
                type="email"
                placeholder="Email"
                onChange={(e) => setUsername(e.target.value)}
                error={error}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                autoComplete="current-password"
                required
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                error={error}
              />
            </SuiBox>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography variant="body2" fontWeight="light" fontSize="small" textColor="error">
                {errorMessage}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={4} mb={1}>
              <SuiButton variant="gradient" buttonColor="info" fullWidth onClick={handleSubmit}>
                sign in
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
