import SuiBox from "../../SuiBox";
import SuiTypography from "../../SuiTypography";

export function MemberCodeCell({ value }) {
  return (
    <SuiTypography fontWeight="bold" sx={{ fontSize: 12 }}>
      {value}
    </SuiTypography>
  );
}

export function MemberInfoCompactCell({ name, email }) {
  return (
    <SuiBox display="flex" flexDirection="column">
      <SuiTypography fontWeight="medium" sx={{ fontSize: 14 }} textTransform="capitalize">
        <span lang="el">{name}</span>
      </SuiTypography>
      <SuiTypography sx={{ fontSize: 13 }}>{email}</SuiTypography>
    </SuiBox>
  );
}

export function SubscriptionCompactCell({ name, code }) {
  return (
    <SuiBox display="flex" flexDirection="column">
      <SuiTypography sx={{ fontSize: 14 }} textTransform="capitalize" color="text">
        <span lang="el">{name}</span>
      </SuiTypography>
      <SuiTypography sx={{ fontSize: 13 }} textColor="secondary">
        {code}
      </SuiTypography>
    </SuiBox>
  );
}
