/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import dataTableData from "./data/dataTableData";
import AddButton from "../../../../components/addButton";
import { getCoupons } from "../../../../redux/Middleware/ShopThunk";
import { createCoupon, disableCoupon, updateCoupon } from "../../../../redux/reducers/shopSlice";
import SuiInput from "../../../../components/SuiInput";
import SuiButton from "../../../../components/SuiButton";

const filters = {
  key: "active",
  options: [
    { label: "all", value: null },
    { label: "active", value: 1 },
    { label: "inactive", value: 0 },
  ],
};

function CouponsPage() {
  const dispatch = useDispatch();
  const couponsData = useSelector((state) => state.shopReducer.coupons);
  const couponsTotal = useSelector((state) => state.shopReducer.couponsTotal);
  const totalPages = useSelector((state) => state.shopReducer.couponsTotalPages);
  const status = useSelector((state) => state.shopReducer.status);
  const [data, setData] = useState(dataTableData);
  const [newCoupon, setNewCoupon] = useState("");
  const [newCouponModal, setNewCouponModal] = useState(false);

  const handleUpdate = (params) => {
    dispatch(getCoupons(params));
  };

  useEffect(() => {
    if (couponsData) {
      setData((prev) => ({ ...prev, rows: couponsData }));
    }
  }, [couponsData]);

  const handleSaveRow = async (values, action, state) => {
    const params = {
      page: state.pageIndex + 1,
      totalPerPage: state.pageSize,
      search: state.globalFilter?.search,
      [state.customFiltersOptions.key]: state.globalFilter?.customFilters,
      sort: state.sortBy?.[0]?.id ?? undefined,
      order: state.sortBy?.[0]?.desc === false ? "asc" : undefined,
    };
    if (action === "delete") {
      await dispatch(disableCoupon(values));
      await dispatch(getCoupons(params));
    } else {
      await dispatch(updateCoupon(values));
      await dispatch(getCoupons(params));
    }
  };

  const handleUpdateRow = (rowIndex, columnId, value) => {
    setData((prev) => {
      const temp = [...prev.rows];
      temp[rowIndex] = { ...temp[rowIndex], [columnId]: value };
      return { ...prev, rows: temp };
    });
  };

  const handleAddCoupon = async () => {
    await dispatch(createCoupon({ code: newCoupon }));
    setNewCouponModal(false);
    setNewCoupon("");
    await dispatch(getCoupons({}));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Coupons List
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {couponsTotal ?? 0} coupons
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <AddButton onClick={() => setNewCouponModal(true)} />
            </SuiBox>
          </SuiBox>

          <DataTable
            totalEntries={couponsTotal}
            totalPages={totalPages}
            loading={status === "loading"}
            fetch={handleUpdate}
            table={data}
            canSearch
            handleUpdateRow={handleUpdateRow}
            handleSaveRow={handleSaveRow}
            customFilters={filters}
          />
          <Modal
            open={newCouponModal}
            onBackdropClick={() => setNewCouponModal(false)}
            onClose={() => setNewCouponModal(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflowY: "auto",
            }}
          >
            <Card
              sx={{
                minWidth: "30%",
                minHeight: "30%",
                maxHeight: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <SuiBox>
                <SuiTypography variant="h5">Add Coupon Code</SuiTypography>
              </SuiBox>
              <SuiBox>
                <SuiInput
                  placeholder="Coupon Code"
                  value={newCoupon}
                  onChange={(e) => setNewCoupon(e.target.value)}
                />
              </SuiBox>
              <SuiBox>
                <SuiButton
                  buttonColor="dark"
                  disabled={status === "creating"}
                  onClick={handleAddCoupon}
                >
                  {status !== "creating" ? "create" : "creating..."}
                </SuiButton>
              </SuiBox>
            </Card>
          </Modal>
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default CouponsPage;
