export default {
  formId: "donation-widget",
  formField: {
    donationAmountOptions: {
      name: "amount_options",
      label: "Amount Options",
      type: "customOptions",
      inputType: "number",
    },
    donationCategoryOptions: {
      name: "category_options",
      label: "Category Options",
      type: "customOptions",
      inputType: "text",
    },
  },
};
