import checkout from "./form";

const {
  formField: {
    column,
    title,
    menuTitle,
    infoBox,
    collapsableBox,
    text1,
    text2,
    bottomLinkText,
    bottomLinkUrl,
    mapAddress,
    featured,
  },
} = checkout;

export default {
  [title.name]: "",
  [`${title.name}_en`]: "",
  [menuTitle.name]: "",
  [`${menuTitle.name}_en`]: "",
  [infoBox.name]: false,
  [collapsableBox.name]: false,
  [text1.name]: "",
  [`${text1.name}_en`]: "",
  [text2.name]: "",
  [`${text2.name}_en`]: "",
  [column.name]: false,
  [bottomLinkText.name]: "",
  [`${bottomLinkText.name}_en`]: "",
  [bottomLinkUrl.name]: "",
  [`${bottomLinkUrl.name}_en`]: "",
  [mapAddress.name]: "",
  [featured.name]: "",
  [`${featured.name}_en`]: "",
};
