/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { DateCell } from "../../../../../components/TableComponents/DefaultCell";
import { MemberInfoCell } from "../../../../../components/TableComponents/InfoCell";

export default {
  columns: [
    { Header: "id", accessor: "id", width: 70 },
    { Header: "unique id", accessor: "code", width: 80 },
    { Header: "name", accessor: "name" },
    { Header: "email", accessor: "email", width: 200 },
    { Header: "subscription", accessor: "subscription" },
    {
      Header: "expiration date",
      accessor: "expiration_date",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <DateCell value={value} />,
    },
    {
      Header: "",
      id: "memberinfo",
      width: 70,
      Cell: ({ row }) => <MemberInfoCell item={row.original} />,
    },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],

  // rows: [
  //   {
  //     id: "1",
  //     code: "",
  //     name: "",
  //     email: "",
  //     subscription: "",
  //     expiration_date: "",
  //     accept_terms: "",
  //     accept_notifications: "",
  //   },
  //   {
  //     id: "2",
  //     code: "",
  //     name: "",
  //     email: "",
  //     subscription: "",
  //     expiration_date: "",
  //     accept_terms: "",
  //     accept_notifications: "",
  //   },
  //   {
  //     id: "",
  //     code: "",
  //     name: "",
  //     email: "",
  //     subscription: "",
  //     expiration_date: "",
  //     accept_terms: "",
  //     accept_notifications: "",
  //   },
  //   {
  //     id: "",
  //     code: "",
  //     name: "",
  //     email: "",
  //     subscription: "",
  //     expiration_date: "",
  //     accept_terms: "",
  //     accept_notifications: "",
  //   },
  //   {
  //     id: "",
  //     code: "",
  //     name: "",
  //     email: "",
  //     subscription: "",
  //     expiration_date: "",
  //     accept_terms: "",
  //     accept_notifications: "",
  //   },
  //   {
  //     id: "",
  //     code: "",
  //     name: "",
  //     email: "",
  //     subscription: "",
  //     expiration_date: "",
  //     accept_terms: "",
  //     accept_notifications: "",
  //   },
  //   {
  //     id: "",
  //     code: "",
  //     name: "",
  //     email: "",
  //     subscription: "",
  //     expiration_date: "",
  //     accept_terms: "",
  //     accept_notifications: "",
  //   },
  // ],
};
