/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { DateTimeCell } from "../../../../../components/TableComponents/DefaultCell";

export default {
  columns: [
    { Header: "name", accessor: "name" },
    { Header: "surname", accessor: "surname" },
    { Header: "email", accessor: "email" },
    { Header: "phone", accessor: "phone" },
    {
      Header: "created at",
      accessor: "created",
      Cell: ({ value }) => <DateTimeCell value={value} />,
    },
    { Header: "cause", accessor: "cause", style: { textTransform: "capitalize" } },
    { Header: "amount", accessor: "amount" },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
};
