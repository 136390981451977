import checkout from "./form";

const {
  formField: { title, menuTitle, description, eventPage, s1Series, s1ServiceCode, options },
} = checkout;

export default {
  [title.name]: "",
  [`${title.name}_en`]: "",
  [menuTitle.name]: "",
  [`${menuTitle.name}_en`]: "",
  [description.name]: "",
  [`${description.name}_en`]: "",
  [eventPage.name]: "",
  [`${eventPage.name}_en`]: "",
  [s1Series.name]: {
    label: "4220 - ΠΑΡΑΓΓΕΛΙΑ ΣΥΜΜΕΤΟΧΗ ΣΕ ΔΙΑΛΕΞΗ",
    value: "4220",
  },
  [s1ServiceCode.name]: {
    label: "Select",
    value: "",
  },
  [options.name]: [],
};
