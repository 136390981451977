/* eslint-disable react/prop-types */
import {
  BannersActionCell,
  DraggableCell,
} from "../../../../../../components/TableComponents/ActionCell";
import { ProductThumbnail } from "../../../../shop/items-list/components/ProductCell";
import { PageLinkCell } from "../../../../../../components/TableComponents/LinkCell";
import { DateCell } from "../../../../../../components/TableComponents/DefaultCell";
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  columns: [
    {
      Header: "",
      id: "dragHandler",
      width: "14px",
      Cell: ({
        dragHandleProps,
        state: {
          globalFilter: { search },
        },
      }) => <DraggableCell filter={!!search} {...dragHandleProps} />,
    },
    {
      Header: "thumbnail",
      accessor: "featured",
      width: "80px",
      Cell: ({ row: { original } }) => (
        <ProductThumbnail img={original.featured} name={original.title} />
      ),
    },
    {
      Header: "title",
      accessor: "title",
      width: "250px",
    },
    { Header: "category", accessor: "type", width: "100px" },
    {
      Header: "url",
      accessor: "slug",
      Cell: ({ value }) => <PageLinkCell value={value} />,
      width: "250px",
    },
    {
      Header: "date created",
      accessor: "created",
      width: "100px",
      Cell: ({ value }) => <DateCell value={value} />,
    },

    {
      Header: "action",
      accessor: "action",
      // width: "120px",
      Cell: ({ row, state }) => (
        <BannersActionCell value={row.original} state={state} type="banner" />
      ),
    },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
};
