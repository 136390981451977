import { persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import persistedRootReducer from "./reducers";

let store;

export const initStore = () => {
  store = configureStore({
    reducer: persistedRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export const getStore = () => {
  if (!store) {
    initStore();
  }
  const persistor = persistStore(store);
  return { store, persistor };
};
