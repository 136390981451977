/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { groupBy } from "loadsh/collection";
import {
  createCategoryRequest,
  deleteCategoryRequest,
  getBoxCategoriesRequest,
  getCategoriesRequest,
  orderCategoryRequest,
  updateCategoryRequest,
  createBoxCategoryRequest,
  updateBoxCategoryRequest,
  deleteBoxCategoryRequest,
  orderBoxCategoryRequest,
} from "../../http/categoriesRequests";
import {
  createCouponRequest,
  disableCouponRequest,
  updateCouponRequest,
} from "../../http/couponsRequests";
import {
  deleteOrderRequest,
  getItemRequest,
  getItemsRequest,
  getOrderRequest,
  getOrdersRequest,
  syncItemRequest,
  updateItemRequest,
  updateItemStatusRequest,
  updateOrderStatusRequest,
} from "../../http/shopItemsRequests";
import {
  createShippingRequest,
  deleteShippingRequest,
  getShippingRequest,
  updateShippingRequest,
} from "../../http/shippingRequests";
import { clearObjectKeys } from "../utils";

const initialState = {
  items: undefined,
  orders: undefined,
  ordersTotal: 0,
  ordersTotalPages: 0,
  donations: [],
  shipping: undefined,
  coupons: undefined,
  categories: [],
  boxCategories: [],
  shopSlider: [],
  donationsTotal: undefined,
  itemsTotal: 0,
  itemsPagesTotal: 0,
  couponsTotal: undefined,
  couponsTotalPages: undefined,
  donationsPagesTotal: undefined,
  showOrder: undefined,
  showOrderStatus: "idle",
  status: "idle",
};

export const getCategories = createAsyncThunk("shopReducer/getCategories", async () => {
  const res = await getCategoriesRequest({});
  const initial = { ...res };
  initial.data.data = res.data.data.map((el) => ({
    ...el,
    subcategories: el.subcategories?.data?.sort((a, b) => a.order - b.order),
  }));
  return initial;
});

export const getBoxCategories = createAsyncThunk(
  "shopReducer/getBoxCategories",
  async (params, { dispatch }) => {
    const res = await getBoxCategoriesRequest(params ?? {});

    return { slider: params?.slider, data: res.data.data };
  }
);

export const createCategory = createAsyncThunk("shopReducer/createCategory", async (params) => {
  const res = await createCategoryRequest(params);
  return res;
});

export const updateCategory = createAsyncThunk("shopReducer/updateCategory", async (params) => {
  const res = await updateCategoryRequest(params);
  return res;
});

export const deleteCategory = createAsyncThunk("shopReducer/deleteCategory", async (params) => {
  const res = await deleteCategoryRequest(params);
  return res;
});

export const orderCategory = createAsyncThunk("shopReducer/orderCategory", async (params) => {
  const res = await orderCategoryRequest(params);
  return res;
});

export const createBoxCategory = createAsyncThunk(
  "shopReducer/createBoxCategory",
  async (params) => {
    const res = await createBoxCategoryRequest(params);
    return res;
  }
);

export const updateBoxCategory = createAsyncThunk(
  "shopReducer/updateBoxCategory",
  async (params) => {
    const res = await updateBoxCategoryRequest(params);
    return res;
  }
);

export const deleteBoxCategory = createAsyncThunk(
  "shopReducer/deleteBoxCategory",
  async (params) => {
    const res = await deleteBoxCategoryRequest(params);
    return res;
  }
);

export const orderBoxCategory = createAsyncThunk("shopReducer/orderBoxCategory", async (params) => {
  const res = await orderBoxCategoryRequest(params);
  return res;
});

export const getItems = createAsyncThunk("shopReducer/getItems", async (params) => {
  const res = await getItemsRequest(params);
  return res;
});

export const showItem = createAsyncThunk("shopReducer/showItem", async (params) => {
  const res = await getItemRequest(params);
  const temp = clearObjectKeys(res.data.data);
  return temp;
});

export const updateItem = createAsyncThunk("shopReducer/updateItem", async (params) => {
  const res = await updateItemRequest(params);
  return res;
});

export const updateItemStatus = createAsyncThunk("shopReducer/updateItemStatus", async (params) => {
  const res = await updateItemStatusRequest(params);
  return res;
});

export const syncItem = createAsyncThunk("shopReducer/syncItem", async (params) => {
  const res = await syncItemRequest(params);
  return res;
});

export const getOrders = createAsyncThunk("shopReducer/getOrders", async (params) => {
  const res = await getOrdersRequest(params);
  return res;
});

export const getOrder = createAsyncThunk("shopReducer/getOrder", async (params) => {
  const res = await getOrderRequest(params);
  return res;
});

export const updateOrderStatus = createAsyncThunk(
  "shopReducer/updateOrderStatus",
  async (params) => {
    const res = await updateOrderStatusRequest(params);
    return res;
  }
);

export const deleteOrder = createAsyncThunk("shopReducer/deleteOrder", async (params) => {
  const res = await deleteOrderRequest(params);
  return res;
});

export const createCoupon = createAsyncThunk("shopReducer/createCoupon", async (params) => {
  const res = await createCouponRequest(params);
  return res;
});

export const updateCoupon = createAsyncThunk("shopReducer/updateCoupon", async (params) => {
  const res = await updateCouponRequest(params);
  return res;
});

export const disableCoupon = createAsyncThunk("shopReducer/disableCoupon", async (params) => {
  const res = await disableCouponRequest(params);
  return res;
});

export const getShipping = createAsyncThunk("shopReducer/getShipping", async (params) => {
  const res = await getShippingRequest({});
  const result = await new Promise((resolve) => {
    const data = groupBy(res.data.data, "weight_threshold");
    const temp = Object.entries(data)
      .map(([k, v]) => {
        const zoneObj = v.reduce(
          (obj, item) => Object.assign(obj, { [item.zone_id]: item.cost }),
          {}
        );
        return { ...zoneObj, weight_threshold: k };
      })
      .sort((a, b) => a.weight_threshold - b.weight_threshold);
    resolve(temp);
  });
  return result;
});

export const createShipping = createAsyncThunk("shopReducer/createShipping", async (params) => {
  const res = await createShippingRequest(params);
  return res;
});

export const updateShipping = createAsyncThunk("shopReducer/updateShipping", async (params) => {
  const values = await new Promise((resolve) => {
    const temp = Object.entries(params).reduce((result, [k, v]) => {
      if (k !== "weight_threshold") {
        result.push({ zone_id: k, cost: v });
      }
      return result;
    }, []);
    resolve({ weight_threshold: params.weight_threshold, shipping_costs: temp });
  });
  const res = await updateShippingRequest(values);
  return res;
});

export const deleteShipping = createAsyncThunk("shopReducer/deleteShipping", async (params) => {
  const res = await deleteShippingRequest(params);
  return res;
});

const shopSlice = createSlice({
  name: "shopReducer",
  initialState,
  reducers: {
    setItems: (state, action) => ({ ...state, items: action.payload }),
    setDonations: (state, action) => ({ ...state, donations: action.payload }),
    setShipping: (state, action) => ({ ...state, shipping: action.payload }),
    setCoupons: (state, action) => ({ ...state, coupons: action.payload }),
    setDonationsTotal: (state, action) => ({ ...state, donationsTotal: action.payload }),
    setDonationsPagesTotal: (state, action) => ({ ...state, donationsPagesTotal: action.payload }),
    setItemsTotal: (state, action) => ({ ...state, itemsTotal: action.payload }),
    setItemsPagesTotal: (state, action) => ({ ...state, itemsPagesTotal: action.payload }),
    setCouponsTotal: (state, action) => ({ ...state, couponsTotal: action.payload }),
    setCouponsTotalPages: (state, action) => ({ ...state, couponsTotalPages: action.payload }),
    setShopStatus: (state, action) => ({ ...state, status: action.payload }),
    setShowOrder: (state, action) => ({ ...state, showOrder: action.payload }),
    setCouponsPagesTotal: (state, action) => ({ ...state, couponsTotalPages: action.payload }),
    setBoxCategories: (state, action) => ({ ...state, boxCategories: action.payload }),
    setSliderCategories: (state, action) => ({ ...state, shopSlider: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.orders = payload?.data.data;
      state.ordersTotal = payload.data.meta.pagination.total;
      state.ordersTotalPages = payload.data.meta.pagination.total_pages;
    });
    builder.addCase(getItems.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getItems.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.items = payload?.data.data;
      state.itemsTotal = payload.data.meta.pagination.total;
      state.itemsPagesTotal = payload.data.meta.pagination.total_pages;
    });
    builder.addCase(getCategories.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getCategories.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.categories = payload?.data.data;
      state.categoriesTotal = payload.data.meta.pagination.total;
      state.status = "fulfilled";
    });
    builder.addCase(getBoxCategories.fulfilled, (state, { payload }) => {
      if (payload?.slider) {
        state.shopSlider = payload.data;
      } else {
        state.boxCategories = payload.data;
      }
      state.status = "fulfilled";
    });
    builder.addCase(getOrder.pending, (state, { payload }) => {
      state.showOrderStatus = "loading";
    });
    builder.addCase(getOrder.rejected, (state, { payload }) => {
      state.showOrderStatus = "error";
    });
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.showOrderStatus = "fulfilled";
    });
    builder.addCase(updateOrderStatus.pending, (state, { payload }) => {
      state.showOrderStatus = "updating";
    });
    builder.addCase(updateOrderStatus.rejected, (state, { payload }) => {
      state.showOrderStatus = "error";
    });
    builder.addCase(updateOrderStatus.fulfilled, (state, { payload }) => {
      state.showOrderStatus = "fulfilled";
    });
    builder.addCase(createCoupon.pending, (state, { payload }) => {
      state.status = "creating";
    });
    builder.addCase(createCoupon.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(createCoupon.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
    });
    builder.addCase(getShipping.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getShipping.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(getShipping.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.shipping = payload;
    });
  },
});

export const {
  setItems,
  setDonations,
  setShipping,
  setCoupons,
  setItemsTotal,
  setItemsPagesTotal,
  setDonationsTotal,
  setDonationsPagesTotal,
  setCouponsTotal,
  setShopStatus,
  setShowOrder,
  setCouponsPagesTotal,
  setBoxCategories,
  setSliderCategories,
} = shopSlice.actions;

export default shopSlice.reducer;
