/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dataTableData from "./data/dataTableData";
import { getDonations } from "../../../../redux/Middleware/ShopThunk";

function DonationsPage() {
  const dispatch = useDispatch();
  const donationsData = useSelector((state) => state.shopReducer.donations);
  const donationsTotal = useSelector((state) => state.shopReducer.donationsTotal);
  const totalPages = useSelector((state) => state.shopReducer.donationsPagesTotal);
  const status = useSelector((state) => state.shopReducer.status);
  const [data, setData] = useState(dataTableData);

  const handleUpdate = (params) => {
    dispatch(getDonations(params));
  };

  useEffect(() => {
    setData((prev) => ({ ...prev, rows: donationsData }));
  }, [donationsData]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Donations
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {donationsTotal ?? 0} donations
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </SuiBox>

          <DataTable
            table={data}
            canSearch
            fetch={handleUpdate}
            totalEntries={donationsTotal}
            loading={status === "loading"}
            totalPages={totalPages}
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default DonationsPage;
