import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import SuiSnackbar from "../components/SuiSnackbar";

const ToastContext = createContext();

// export default ToastContext;

const useToastContext = () => useContext(ToastContext);

export default useToastContext;

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({
    type: "info",
    title: "",
    content: "",
    show: false,
    close: () => setToast((p) => ({ ...p, show: false })),
  });

  const showToast = useCallback((e) => setToast((p) => ({ ...p, ...e, show: true })), []);

  const clearToast = useCallback(
    () =>
      setToast({
        type: "info",
        title: "",
        content: "",
        show: false,
        close: () => setToast((p) => ({ ...p, show: false })),
      }),
    []
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (toast.show) {
      const timeout = setTimeout(() => clearToast(), 4000);
      return () => clearTimeout(timeout);
    }
  }, [toast]);

  return (
    <ToastContext.Provider value={showToast}>
      <>
        {children}
        <SuiSnackbar
          open={toast.show}
          type={toast.type}
          title={toast.title}
          content={toast.content}
          close={toast.close}
        />
      </>
    </ToastContext.Provider>
  );
};
