import React, { useState } from "react";
import LanguageToggle from "./LanguageToggle";
import { CustomField } from "../CustomFieldWrappers/CustomField";

// eslint-disable-next-line react/prop-types
const FieldsWrapper = ({ fields = [] }) => {
  const [language, setLanguage] = useState("el");

  return (
    <>
      <LanguageToggle language={language} setLanguage={setLanguage} />
      {(Array.isArray(fields) &&
        fields?.map((el) => <CustomField key={el.name} item={el} language={language} />)) || <></>}
    </>
  );
};

export default FieldsWrapper;
