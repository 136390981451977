/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPagesRequest } from "../../http/pagesRequests";
import {
  getEventRequest,
  getEventsRequest,
  searchEventPagesRequest,
} from "../../http/eventsRequests";

const initialState = {
  events: undefined,
  eventPages: undefined,
  tickets: undefined,
  eventsTotal: 0,
  eventsPagesTotal: 0,
  eventPagesTotal: 0,
  eventPagesTotalPages: 0,
  ticketsTotal: 0,
  ticketsPagesTotal: 0,
  status: "idle",
};

export const getEventPages = createAsyncThunk("eventsReducer/getEventPages", async (params) => {
  const res = await getPagesRequest({ ...params, type: "pevent" });
  return res;
});

export const getEvent = createAsyncThunk("eventsReducer/getEvent", async (params) => {
  const res = await getEventRequest(params);
  return res;
});

export const searchEvent = createAsyncThunk("eventsReducer/getEvent", async (params) => {
  const res = await getEventsRequest(params);
  return res;
});

export const searchEventPages = createAsyncThunk(
  "eventsReducer/searchEventPages",
  async (params) => {
    const res = await searchEventPagesRequest(params);
    return res;
  }
);

const eventsSlice = createSlice({
  name: "eventsReducer",
  initialState,
  reducers: {
    setEvents: (state, action) => ({ ...state, events: action.payload }),
    setEventsTotal: (state, action) => ({ ...state, eventsTotal: action.payload }),
    setEventsPagesTotal: (state, action) => ({ ...state, eventsPagesTotal: action.payload }),
    setEventPages: (state, action) => ({ ...state, eventPages: action.payload }),
    setEventPagesTotal: (state, action) => ({ ...state, eventPagesTotal: action.payload }),
    setEventPagesTotalPages: (state, action) => ({
      ...state,
      eventPagesTotalPages: action.payload,
    }),
    setTickets: (state, action) => ({ ...state, tickets: action.payload }),
    setTicketsTotal: (state, action) => ({ ...state, ticketsTotal: action.payload }),
    setTicketsPagesTotal: (state, action) => ({ ...state, ticketsPagesTotal: action.payload }),
    setEventsStatus: (state, action) => ({ ...state, status: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getEventPages.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(getEventPages.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(getEventPages.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.eventPages = payload?.data.data;
      state.eventPagesTotal = payload?.data.meta.pagination.total;
      state.eventPagesTotalPages = payload?.data.meta.pagination.total_pages;
    });
    builder.addCase(searchEventPages.rejected, (state, { payload }) => {
      state.status = "error";
    });
    builder.addCase(searchEventPages.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(searchEventPages.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
    });
  },
});

export const {
  setEvents,
  setEventsTotal,
  setEventsPagesTotal,
  setEventPages,
  setEventPagesTotal,
  setEventPagesTotalPages,
  setTickets,
  setTicketsTotal,
  setTicketsPagesTotal,
  setEventsStatus,
} = eventsSlice.actions;
export default eventsSlice.reducer;
