import axiosInstanceProxy from "./config";

export const getUsersRequest = ({ sort = null, order = null, totalPerPage = null }) =>
  axiosInstanceProxy.get("/admin/users", {
    params: {
      sort,
      order,
      totalPerPage,
    },
  });

export const getUserRequest = (id) => axiosInstanceProxy.get(`/admin/users/${id}/show`);

export const createUserRequest = ({ name, username, email, password, role, description }) =>
  axiosInstanceProxy.post("/admin/user/create", {
    username,
    email,
    password,
    role,
    description,
    name,
  });

export const updateUserRequest = ({
  id,
  name,
  username,
  email,
  password,
  role,
  description,
  avatar,
}) =>
  axiosInstanceProxy.post(`/admin/user/${id}/update`, {
    username,
    email,
    password,
    role,
    description,
    avatar,
    name,
  });

export const deleteUserRequest = ({ id }) => axiosInstanceProxy.post(`/admin/user/${id}/destroy`);
