import axiosInstanceProxy from "./config";

export const getMembersRequest = ({
  search,
  sort = "id",
  order = "desc",
  totalPerPage,
  page,
  status,
  closed,
}) =>
  axiosInstanceProxy.get("/admin/members", {
    params: {
      search,
      sort,
      order,
      totalPerPage,
      page,
      status,
      closed,
    },
  });

export const getSubscribedRequest = ({
  search,
  sort,
  order,
  totalPerPage,
  page,
  status,
  subscription,
}) =>
  axiosInstanceProxy.get("/admin/members/subscribed", {
    params: {
      search,
      sort,
      order,
      totalPerPage,
      page,
      status,
      subscription,
    },
  });
