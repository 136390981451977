import textValues from "./TextWidget/schema/initialValues";
import sponsorValues from "./SponsorsWidget/schema/initialValues";
import pageValues from "./PageWidget/schema/initialValues";
import publicationsValues from "./PublicationsWidget/schema/initialValues";
import listValues from "./ListWidget/schema/initialValues";
import subsValues from "./SubscriptionsWidget/schema/initialValues";
import ticketValues from "./TicketWidget/schema/initialValues";
import videoValues from "./VideoWidget/schema/initialValues";
import galleryValues from "./GalleryWidget/schema/initialValues";
import bioValues from "./BioWidget/schema/initialValues";
import donationValues from "./DonationWidget/schema/initialValues";
import listModalSchema from "./ListWidget/schema/modalSchema/form";
import listModalInitialValues from "./ListWidget/schema/modalSchema/initialValues";
import pageModalSchema from "./PageWidget/schema/modalSchema/form";
import pageModalInitialValues from "./PageWidget/schema/modalSchema/initialValues";

export const getWidget = (type, order) => {
  const widget = { type, order };
  switch (type) {
    case "text":
      return { ...widget, data: textValues };
    case "sponsors":
    case "sponsor":
      return { ...widget, data: sponsorValues };
    case "page":
      return { ...widget, data: pageValues };
    case "publication":
      return { ...widget, data: publicationsValues };
    case "list":
      return { ...widget, data: listValues };
    case "bio":
      return { ...widget, data: bioValues };
    case "gallery":
      return { ...widget, data: galleryValues };
    case "video":
      return { ...widget, data: videoValues };
    case "subscriptions":
      return { ...widget, data: subsValues };
    case "ticket":
      return { ...widget, data: ticketValues };
    case "donation":
      return { ...widget, data: donationValues };
    default:
      return {};
  }
};

export const getSpecialWidgetModalSchema = (type) => {
  switch (type) {
    case "list":
      return { ...listModalSchema, initialValues: { ...listModalInitialValues } };
    case "page":
      return { ...pageModalSchema, initialValues: { ...pageModalInitialValues } };
    default:
      return {};
  }
};
