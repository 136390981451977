import ActionCell, {
  ButtonsActionCell,
} from "../../../../../components/TableComponents/ActionCell";
import EditableCell from "../../../../../components/TableComponents/EditableCell";

/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const cell = {
  // eslint-disable-next-line react/prop-types
  Cell: ({ value, row, column, handleUpdateRow }) => (
    <EditableCell
      initialValue={value}
      row={row}
      column={column}
      updateMyData={handleUpdateRow}
      type="number"
    />
  ),
};

export default {
  columns: [
    { Header: "weight(kg)", accessor: "weight_threshold" },
    {
      Header: "Greece",
      columns: [
        {
          Header: "zone 99",
          accessor: "1",
          // width: "9%",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
      ],
    },
    {
      Header: "Abroad",
      columns: [
        {
          Header: "zone 1",
          accessor: "2",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 2",
          accessor: "3",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 21",
          accessor: "4",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 22",
          accessor: "5",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 3",
          accessor: "6",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 31",
          accessor: "7",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 4",
          accessor: "8",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 5",
          accessor: "9",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 6",
          accessor: "10",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
        {
          Header: "zone 7",
          accessor: "11",
          width: "90px",
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <EditableCell
              initialValue={value}
              row={row}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
      ],
    },
    {
      Header: "actions",
      id: "actions",
      Cell: ({ row, handleSaveRow }) => (
        <ButtonsActionCell row={row} handleSaveRow={handleSaveRow} />
      ),
    },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
};
