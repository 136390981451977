/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Icon, Skeleton, Tooltip } from "@mui/material";

function OrderSummary({ data, loading }) {
  const paymentDetails = data?.payment_method?.data?.label ?? "No Payment Details";
  const shippingDetails = data?.shipping_method?.data?.label ?? "No Shipping Details";
  const giftDetails = data?.gift ? "For Gift" : null;

  return (
    <>
      <SuiBox mb={2}>
        <SuiBox mb={2}>
          <SuiTypography variant="h6" fontWeight="medium">
            Notes
          </SuiTypography>
          <SuiBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            backgroundColor="grey-100"
            borderRadius="lg"
            p={3}
            mt={2}
          >
            <SuiTypography variant="caption" textColor="text">
              {data?.notes ?? " "}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiTypography variant="h6" fontWeight="medium">
          Order Summary
        </SuiTypography>
        <SuiBox display="flex" justifyContent="space-between" mb={0.5}>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Shipping:
          </SuiTypography>
          <SuiBox ml={1}>
            <SuiTypography variant="body2" fontWeight="medium">
              {loading ? (
                <Skeleton width={70} variant="text" height={20} />
              ) : data?.shipping_total ? (
                `€ ${data?.shipping_total}`
              ) : (
                "-"
              )}
            </SuiTypography>
          </SuiBox>
        </SuiBox>

        <SuiBox display="flex" justifyContent="space-between" mb={0.5}>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Coupon:
          </SuiTypography>
          <SuiBox ml={1}>
            <SuiTypography variant="body2" fontWeight="medium">
              {loading ? (
                <Skeleton width={70} variant="text" height={20} />
              ) : data?.coupon?.data?.code ? (
                `${data?.coupon?.data?.code}`
              ) : (
                "-"
              )}
            </SuiTypography>
          </SuiBox>
        </SuiBox>

        <SuiBox display="flex" justifyContent="space-between" mt={3}>
          <SuiTypography variant="body1" fontWeight="light" textColor="text">
            Total:
          </SuiTypography>
          <SuiBox ml={1}>
            <SuiTypography variant="body1" fontWeight="medium">
              {loading ? (
                <Skeleton width={120} variant="text" height={24} />
              ) : data?.total ? (
                `€ ${data?.total}`
              ) : (
                "-"
              )}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox mt={2}>
          <SuiTypography variant="h6" fontWeight="medium">
            Payment Details:
          </SuiTypography>
        </SuiBox>
        {loading ? (
          <Skeleton width={120} variant="text" height={24} />
        ) : (
          <SuiBox display="flex">
            <SuiBox mr={3} sx={{ cursor: "default" }}>
              <Tooltip title={paymentDetails}>
                <Icon class="material-icons-outlined">
                  {data?.payment_method?.data?.icon_url ?? "credit_card"}
                </Icon>
              </Tooltip>
            </SuiBox>
            <SuiBox mr={3} sx={{ cursor: "default" }}>
              <Tooltip title={shippingDetails}>
                <Icon class="material-icons-outlined">
                  {data?.shipping_method?.data?.icon_url ?? "local_shipping"}
                </Icon>
              </Tooltip>
            </SuiBox>
            {giftDetails && (
              <SuiBox sx={{ cursor: "default" }}>
                <Tooltip title={giftDetails}>
                  <Icon class="material-icons-outlined">redeem</Icon>
                </Tooltip>
              </SuiBox>
            )}
          </SuiBox>
        )}
      </SuiBox>
    </>
  );
}

export default OrderSummary;
