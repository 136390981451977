import checkout from "./form";

const {
  formField: { title, menuTitle, bottomLinkText, bottomLinkUrl, columns },
} = checkout;

export default {
  [title.name]: "",
  [`${title.name}_en`]: "",
  [menuTitle.name]: "",
  [`${menuTitle.name}_en`]: "",
  [bottomLinkText.name]: "",
  [`${bottomLinkText.name}_en`]: "",
  [bottomLinkUrl.name]: "",
  [`${bottomLinkUrl.name}_en`]: "",
  [columns.name]: "",
};
