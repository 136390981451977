/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FieldArray, useFormikContext } from "formik";
import { Icon } from "@mui/material";
import SuiButton from "../SuiButton";
import SuiBox from "../SuiBox";
import SuiTypography from "../SuiTypography";
import WidgetSpecialModal from "./components/WidgetSpecialModal";
import { getSpecialWidgetModalSchema } from "../widgets/utils";
import DragableList from "../DragableList/DragableList";

const SpecialWidgetField = ({ item }) => {
  const { values } = useFormikContext();
  const schema = getSpecialWidgetModalSchema(item.widget);
  const [modalOpen, setModalOpen] = useState(false);
  const [initialValue, setInitialValue] = useState();
  const [editIndex, setEditIndex] = useState();

  const handleEdit = (el, index) => () => {
    setInitialValue(el);
    setEditIndex(index);
    setModalOpen(true);
  };

  const clearInitialValues = () => {
    setInitialValue();
    setEditIndex();
  };

  return (
    <FieldArray name={item.name}>
      {(arrayHelpers) => (
        <SuiBox>
          <SuiBox my={2} display="flex" flexDirection="column">
            {/* {values[item.name]?.map((el, i) => ( */}
            <DragableList
              onSave={(list, from, to) => arrayHelpers.move(from, to)}
              items={values[item.name]}
              renderItem={({ item: el, index: i, itemProps, disabled }) => (
                <SuiBox
                  key={el.url}
                  mt={i !== 0 ? 1 : 0}
                  display="flex"
                  alignItems="center"
                  backgroundColor={disabled ? "#00000020" : "transparent"}
                  sx={{
                    padding: 1,
                    borderRadius: "4px",
                    // borderColor: disabled ? "gray" : "transparent",
                  }}
                >
                  <img src={el.image} alt="" width="60px" height="60px" />
                  <SuiTypography ml={1}>{el.label}</SuiTypography>
                  <SuiButton
                    variant="text"
                    size="medium"
                    buttonColor="dark"
                    iconOnly
                    disabled={disabled}
                    onClick={handleEdit(el, i)}
                  >
                    <Icon className="font-bold">edit</Icon>
                  </SuiButton>
                  <SuiButton
                    variant="text"
                    size="medium"
                    iconOnly
                    buttonColor="dark"
                    disabled={disabled}
                    onClick={() => arrayHelpers.remove(i)}
                  >
                    <Icon className="font-bold">delete</Icon>
                  </SuiButton>
                </SuiBox>
              )}
            />

            {/* ))} */}
          </SuiBox>
          <SuiButton variant="text" buttonColor="dark" onClick={() => setModalOpen(true)}>
            + Add {schema.button}
          </SuiButton>
          <WidgetSpecialModal
            open={modalOpen}
            setOpen={setModalOpen}
            onSave={(e) => {
              if (initialValue) {
                arrayHelpers.replace(editIndex, e);
              } else {
                arrayHelpers.push(e);
              }
            }}
            existingValues={initialValue}
            clearInitialValues={clearInitialValues}
            schema={schema}
          />
        </SuiBox>
      )}
    </FieldArray>
  );
};

export default SpecialWidgetField;
