/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, useCallback, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for SuiSelect
import { styles, animation } from "components/SuiSelect/styles";
import AsyncSelect from "react-select/async";
import { useDispatch } from "react-redux";
import { useAsyncDebounce } from "react-table";

const SuiSelect = forwardRef(({ size, error, success, children, ...rest }, ref) => {
  const classes = animation();

  return (
    <Select
      {...rest}
      ref={ref}
      styles={styles(size, error, success)}
      className={classes.menu}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: colors.light.main,
          primary: colors.light.main,
        },
      })}
    >
      {children}
    </Select>
  );
});

export const SuiAsyncSelect = forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ size, error, success, children, getOptions, isMulti, onChange, value, ...rest }, ref) => {
    const classes = animation();
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(isMulti ? [] : "");

    const loadOptions = useCallback(
      useAsyncDebounce(
        (input) =>
          new Promise((resolve, reject) => {
            if (!input || input === "") resolve();
            try {
              dispatch(getOptions({ search: input }))
                .unwrap()
                .then((result) => {
                  resolve(result.data.data.map((e) => ({ label: e.name || e.title, value: e })));
                });
            } catch {
              reject();
            }
          }),
        700
      ),
      []
    );

    useEffect(() => {
      if (value && value.length > 0 && (!selectedOption || selectedOption?.length === 0)) {
        setSelectedOption(value.map((v) => ({ label: v.name, v })));
      }
    }, [value]);

    const handleChange = (e) => {
      if (isMulti) {
        onChange(e.map((el) => el.value));
        setSelectedOption(e);
      } else {
        onChange(e.value);
        setSelectedOption(e);
      }
    };

    return (
      <AsyncSelect
        loadOptions={loadOptions}
        isMulti={isMulti}
        {...rest}
        onChange={handleChange}
        value={selectedOption}
        ref={ref}
        styles={styles(size, error, success)}
        className={classes.menu}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: colors.light.main,
            primary: colors.light.main,
          },
        })}
      >
        {children}
      </AsyncSelect>
    );
  }
);

// Setting default values for the props of SuiSelect
SuiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  children: null,
};

// Typechecking props for the SuiSelect
SuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.node,
};

export default SuiSelect;
