/* eslint-disable react/prop-types */
import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import { debounce } from "loadsh/function";

import { Toolbar } from "./Toolbar";
import "./Tiptap.scss";

const Tiptap = ({ name, value, onChange }) => {
  const extensions = [
    StarterKit,
    Typography,
    Underline,
    Image.configure({
      HTMLAttributes: {
        style: "width: 100%;",
      },
    }),
    Link.configure({
      linkOnPaste: false,
      openOnClick: true,
    }),
  ];

  const editor = useEditor(
    {
      content: value,
      extensions,
      editable: true,
      onUpdate: debounce(({ editor: funcEditor }) => {
        const htmlContent = funcEditor.getHTML();
        if (htmlContent === "<p></p>") {
          onChange({ target: { value: "" } });
        } else {
          onChange({ target: { value: htmlContent } });
        }
      }, 1000),
    },
    [name]
  );

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="WhiteCard">
        <Toolbar editor={editor} />
        <EditorContent editor={editor} />
      </div>
    </>
  );
};

export { Tiptap };
