import axiosInstanceProxy from "./config";

export const getCategoriesRequest = ({ sort = "order", order = "asc", totalPerPage }) =>
  axiosInstanceProxy.get(`admin/shop/categories`, {
    params: {
      sort,
      order,
      totalPerPage,
    },
  });

export const createCategoryRequest = (params) =>
  axiosInstanceProxy.post("admin/shop/categories/create", { ...params });

export const updateCategoryRequest = (params) =>
  axiosInstanceProxy.post(`admin/shop/categories/${params.id}/update`, { ...params });

export const deleteCategoryRequest = ({ id }) =>
  axiosInstanceProxy.post(`admin/shop/categories/${id}/destroy`);

export const orderCategoryRequest = ({ id, order }) =>
  axiosInstanceProxy.post(`admin/shop/categories/${id}/move`, { order });

export const getBoxCategoriesRequest = ({ sort = "id", order = "desc", slider = false }) =>
  axiosInstanceProxy.get(`admin/shop/box-categories`, {
    params: {
      sort,
      order,
      slider: slider ? 1 : 0,
    },
  });

export const createBoxCategoryRequest = (params) =>
  axiosInstanceProxy.post("admin/shop/box-categories/create", { ...params });

export const updateBoxCategoryRequest = (params) =>
  axiosInstanceProxy.post(`admin/shop/box-categories/${params.id}/update`, { ...params });

export const deleteBoxCategoryRequest = ({ id }) =>
  axiosInstanceProxy.post(`admin/shop/box-categories/${id}/destroy`);

export const orderBoxCategoryRequest = ({ id, order }) =>
  axiosInstanceProxy.post(`admin/shop/box-categories/${id}/move`, { order });
