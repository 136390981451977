/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

// @mui material components
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";

import { useSelector } from "react-redux";
import Dropzone from "dropzone";
import otherRoutes from "./OtherRoutes";
import { getOptions, getSubscriptions } from "./redux/reducers/optionsSlice";
import { getStore } from "./redux/store";
import { ToastProvider } from "./context/ToastContext";

export default function App() {
  const { store } = getStore();
  const isLoggedIn = useSelector((state) => state?.authReducer?.userLoggedIn);
  const history = useHistory();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  useEffect(async () => {
    if (isLoggedIn) {
      await store.dispatch(getOptions());
      await store.dispatch(getSubscriptions());
    }
  }, [isLoggedIn]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: false });
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: true });
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => {
    dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch({ type: "LAYOUT", value: "dashboard" });
    } else {
      dispatch({ type: "LAYOUT", value: "page" });
    }
  }, [isLoggedIn, pathname]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (!isLoggedIn) {
      history.replace("/login");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    Dropzone.autoDiscover = false;
  }, []);

  const getRoutes = useCallback(
    (allRoutes) =>
      allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route exact path={route.route} component={route.component} key={route.key} />;
        }

        return null;
      }),
    []
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastProvider>
          {layout === "dashboard" && (
            <>
              <Sidenav
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          <Switch>
            {getRoutes([...routes, ...otherRoutes])}
            {isLoggedIn ? (
              <Redirect from="*" to="/shop/orders" />
            ) : (
              <Redirect from="*" to="/login" />
            )}
          </Switch>
        </ToastProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
