/* eslint-disable react/prop-types */
import React from "react";
import { ProductThumbnail } from "../../items-list/components/ProductCell";
import SuiTypography from "../../../../../components/SuiTypography";
import DefaultCell from "../../../../../components/TableComponents/DefaultCell";

const datatable = {
  columns: [
    {
      Header: "title",
      accessor: (e) => {
        switch (e.type) {
          case "item":
            return e.name;
          case "ticketitem":
            return e?.options?.ticket?.event?.label;
          default:
            return e.name;
        }
      },
    },
    {
      Header: "image",
      accessor: "item_image",
      Cell: ({ value, row }) => <ProductThumbnail name={row.original.name} img={value} />,
    },
    { Header: "code", accessor: "item_code" },
    { Header: "quantity", accessor: "quantity", width: "60px" },
    {
      Header: "Price",
      accessor: "price",
      width: "60px",
      Cell: ({ row: { original } }) => (
        <>
          {original.total !== original.full_price ? (
            <SuiTypography
              sx={{
                fontSize: "16px",
                textDecoration: "line",
                color: "red",
              }}
              style={{ textDecoration: "line-through" }}
            >
              €{original?.full_price}
            </SuiTypography>
          ) : null}
          <SuiTypography
            sx={{
              fontSize: "16px",
            }}
          >
            €{original?.total}
          </SuiTypography>
        </>
      ),
    },
    {
      Header: "options",
      accessor: "options",
      Cell: ({ value, row }) => {
        switch (row.original.type) {
          case "item":
            return (
              <>
                {value?.map((el) => (
                  <DefaultCell value={el.label} suffix={el.value} />
                ))}
              </>
            );
          case "ticketitem":
            return <DefaultCell value={value?.ticket?.event?.category} />;
          default:
            return <></>;
        }
      },
    },
  ],

  rows: [{}, {}],
};

export const datatableTicket = {
  columns: [
    {
      Header: "title",
      // accessor: "name",
      accessor: (e) => e?.options?.ticket?.event?.label || e.name,
    },
    { Header: "quantity", accessor: "quantity", width: "60px" },
    {
      Header: "Price",
      accessor: "price",
      width: "60px",
      Cell: ({ row: { original } }) => (
        <>
          {original.total !== original.full_price ? (
            <SuiTypography
              sx={{
                fontSize: "16px",
                textDecoration: "line-through",
                color: "red",
              }}
            >
              €{original?.full_price}
            </SuiTypography>
          ) : null}
          <SuiTypography
            sx={{
              fontSize: "16px",
            }}
          >
            €{original?.total}
          </SuiTypography>
        </>
      ),
    },
  ],

  rows: [{}, {}],
};

export default datatable;
