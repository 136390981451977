import checkout from "./form";

const {
  formField: { donationAmountOptions, donationCategoryOptions },
} = checkout;

export default {
  [donationAmountOptions.name]: ["250", "500", "1000", "2000"],
  [donationCategoryOptions.name]: [
    "Εκθέσεις",
    "Εκπαιδευτικά προγράμματα",
    "Κοινωνικά προγράμματα",
    "Μουσειοσκευές",
  ],
  [`${donationCategoryOptions.name}_en`]: [
    "Exhibitions",
    "Educational programs",
    "Community programs",
    "Museum kits",
  ],
};
