import axiosInstanceProxy from "./config";

export const getItemsRequest = ({ search, sort = "id", order = "desc", totalPerPage = 10, page }) =>
  axiosInstanceProxy.get(`admin/shop/items`, {
    params: {
      search,
      sort,
      order,
      totalPerPage,
      page,
    },
  });

export const getItemRequest = ({ id }) => axiosInstanceProxy.get(`admin/shop/item/${id}/show`);

export const updateItemRequest = (data) =>
  axiosInstanceProxy.post(`admin/shop/item/${data.id}/update`, {
    ...data,
  });

export const updateItemStatusRequest = (data) =>
  axiosInstanceProxy.post(`admin/shop/item/${data.id}/status`, {
    ...data,
  });

export const syncItemRequest = (id) => axiosInstanceProxy.post(`admin/shop/item/${id}/sync`, {});

export const getOrdersRequest = ({
  search,
  sort = "id",
  order = "desc",
  totalPerPage = 10,
  page,
  status,
  coupon_id,
}) =>
  axiosInstanceProxy.get(`admin/shop/orders`, {
    params: {
      search,
      sort,
      order,
      totalPerPage,
      page,
      status,
      coupon_id,
    },
  });

export const getOrderRequest = ({ id }) => axiosInstanceProxy.get(`/admin/shop/orders/show/${id}`);

export const deleteOrderRequest = ({ id }) =>
  axiosInstanceProxy.post(`/admin/shop/orders/${id}/destroy`);

export const updateOrderStatusRequest = ({ id, status }) =>
  axiosInstanceProxy.post(`/admin/shop/orders/${id}/status`, {
    status,
  });
