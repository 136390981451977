/* eslint-disable */
/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, FastField, Field, useFormikContext } from "formik";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "../SuiDatePicker";
// import SuiEditor from "../SuiEditor";
import SuiSelect, { SuiAsyncSelect } from "../SuiSelect";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAsyncDebounce } from "react-table";
import { slugify } from "../../redux/reducers/cmsSlice";
import { Tiptap } from "../TipTap/Tiptap";

const FormField = ({ label, name, ...rest }) => {
  return (
    <SuiBox mb={1.5}>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <FastField {...rest} name={name} as={SuiInput} />
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" textColor="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

export const SlugFormField = ({
  label,
  value,
  name,
  dependentName = "",
  dependentValue = "",
  setFieldValue,
  isEdit=false,
  ...rest
}) => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { initialValues, touched } = useFormikContext();
  const touchDependent = touched?.[dependentName];

  const handleSlug = useCallback(
    useAsyncDebounce((dependentValue, initialSlug) => {
      return new Promise((resolve, reject) => {
        try {
          if (dependentValue && dependentValue === initialSlug) {
            setFieldValue(initialValues[name]);
            resolve();
            return;
          }
          if (dependentValue?.length > 3) {
            setError(null);
            dispatch(slugify(dependentValue))
              .unwrap()
              .then((res) => {
                setFieldValue(res.data);
                resolve(res.data);
              });
          } else {
            setError("Title must have at least 3 characters");
          }
        } catch (e) {
          reject(e);
          setError(e);
        }
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (!touchDependent || isEdit) return;
    const initialSlug = initialValues?.[name] ?? "";
    handleSlug(dependentValue, initialSlug);
  }, [touchDependent, dependentValue, initialValues]);

  return (
    <SuiBox mb={1.5}>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <FastField {...rest} name={name} as={SuiInput} />
      <SuiBox ml={1}>
        <SuiTypography
          component="div"
          variant="h6"
          fontWeight="regular"
          textColor={error ? "error" : undefined}
        >
          {error}
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" textColor="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

// eslint-disable-next-line react/prop-types
export function TextAreaFormField({ label, name, ...rest }) {
  return (
    <SuiBox mb={1.5}>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <FastField {...rest} name={name} as={SuiInput} textArea />
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" textColor="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

export const RadioFormField = ({
  label,
  name,
  options,
  value,
  // setValue,
  ...rest
}) => (
  <SuiBox mb={1.5}>
    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
      <SuiTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}
      </SuiTypography>
    </SuiBox>
    <SuiBox role="group" display="flex" flexDirection="column">
      {options?.map((el, i) => (
        <SuiTypography
          key={el.label ?? i}
          component="label"
          variant="body2"
          textTransform="capitalize"
        >
          <FastField
            type="radio"
            name={name}
            value={el.value}
            style={{ marginRight: "10px" }}
            checked={value == el.value}
            // onChange={() => setValue(el.value)}
            //{...rest}
          />
          {el.label}
        </SuiTypography>
      ))}
    </SuiBox>
    <SuiBox mt={0.75}>
      <SuiTypography component="div" variant="caption" textColor="error">
        <ErrorMessage name={name} />
      </SuiTypography>
    </SuiBox>
  </SuiBox>
);

export const CheckboxFormField = ({
  label,
  name,
  // value,setValue,
  ...rest
}) => {
  return (
    <SuiBox mb={1.5} mt={1.5} display="flex" direction="row">
      <SuiBox role="group" display="flex" flexDirection="column">
        <FastField
          type="checkbox"
          name={name}
          // onChange={e => setValue(e.target.checked)}
          {...rest}
        />
      </SuiBox>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" textColor="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

export const DatepickerFormField = ({ label, name, value, setValue, options, ...rest }) => {
  return (
    <SuiBox mb={1.5}>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <SuiBox role="group" display="flex" flexDirection="column">
        <FastField
          as={SuiDatePicker}
          name={name}
          value={value}
          onChange={setValue}
          options={options}
          {...rest}
        />
      </SuiBox>
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" textColor="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

export const EditorFormField = ({ label, name }) => (
  <SuiBox mb={1.5}>
    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
      <SuiTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}
      </SuiTypography>
    </SuiBox>
    <SuiBox role="group" display="flex" flexDirection="column">
      <Field name={name}>
        {({ field }) => <Tiptap value={field.value} onChange={field.onChange(field.name)} name={field.name} />}
      </Field>
    </SuiBox>
    <SuiBox mt={0.75}>
      <SuiTypography component="div" variant="caption" textColor="error">
        <ErrorMessage name={name} />
      </SuiTypography>
    </SuiBox>
  </SuiBox>
);

export const DropdownFormField = ({
  label,
  name,
  value,
  setValue,
  options,
  exactValue = false,
  ...rest
}) => {
  return (
    <SuiBox mb={1.5}>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <SuiBox role="group" display="flex" flexDirection="column">
        <Field
          as={SuiSelect}
          name={name}
          value={options?.find((e) => (exactValue ? e.value === value : e.value == value))}
          onChange={setValue}
          options={options}
          {...rest}
        />
      </SuiBox>
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" textColor="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

export const SearchDropdownFormField = ({
  label,
  name,
  value,
  setValue,
  getOptions,
  isMulti,
  ...rest
}) => (
  <SuiBox mb={1.5}>
    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
      <SuiTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}
      </SuiTypography>
    </SuiBox>
    <SuiBox role="group" display="flex" flexDirection="column">
      <Field
        as={SuiAsyncSelect}
        name={name}
        value={value}
        getOptions={getOptions}
        // setFieldValue={setValue}
        isMulti={isMulti}
        onChange={setValue}
        {...rest}
      />
    </SuiBox>
    <SuiBox mt={0.75}>
      <SuiTypography component="div" variant="caption" textColor="error">
        <ErrorMessage name={name} />
      </SuiTypography>
    </SuiBox>
  </SuiBox>
);

DropdownFormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

EditorFormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // setValue: PropTypes.func.isRequired,
  // value: PropTypes.string.isRequired,
};

DatepickerFormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.string), PropTypes.string],
    PropTypes.arrayOf(PropTypes.instanceOf(Date))
  ).isRequired,
};

CheckboxFormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // setValue: PropTypes.func.isRequired,
  // value: PropTypes.bool.isRequired,
};

RadioFormField.propTypes = {
  options: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // setValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
