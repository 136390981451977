/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-banner-form",
  formField: {
    image: {
      name: "featured",
      label: "Image",
      type: "featured",
    },
    title: {
      name: "title",
      label: "title",
      type: "text",
    },
    slug: {
      name: "slug",
      label: "URL",
      type: "slug",
      dependentValue: "title",
    },
    label: {
      name: "label_title",
      label: "label",
      type: "text",
    },
    summary: {
      name: "summary",
      label: "summary",
      type: "text",
      multiline: true,
    },
    moreUrl: {
      name: "more_link",
      label: "More URL",
      type: "text",
    },
    showHomepage: {
      name: "homeslider",
      label: "show in Homepage slider",
      type: "checkbox",
    },
    showShopBanner: {
      name: "shop_banner",
      label: "show in shop banner",
      type: "checkbox",
    },
  },
};
