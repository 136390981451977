import { getEventsRequest } from "../../http/eventsRequests";
import {
  setEvents,
  setEventsPagesTotal,
  setEventsTotal,
  setTickets,
  setTicketsTotal,
  setTicketsPagesTotal,
  setEventsStatus,
} from "../reducers/eventsSlice";
import { getTicketsRequest } from "../../http/ticketsRequests";

export const getEvents = (params) => (dispatch) => {
  dispatch(setEventsStatus("loading"));
  getEventsRequest(params)
    .then((res) => {
      const data = res.data?.data?.map((el) => ({
        ...el,
        date: el.event.start,
      }));
      dispatch(setEvents(data));
      dispatch(setEventsTotal(res.data.meta.pagination.total));
      dispatch(setEventsPagesTotal(res.data.meta.pagination.total_pages));
      dispatch(setEventsStatus("fulfilled"));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setEventsStatus("error"));
    });
};

export const getTickets = (params) => (dispatch) => {
  dispatch(setEventsStatus("loading"));

  getTicketsRequest(params)
    .then((res) => {
      const data = res.data?.data?.map((el) => ({
        ...el,
        event_title: el.ticket?.data?.page,
        price: el.ticket?.data?.price,
        quantity: el.ticket?.data?.quantity,
        email: el.member?.data?.email,
      }));
      dispatch(setTickets(data));
      dispatch(setTicketsTotal(res.data.meta.pagination.total));
      dispatch(setTicketsPagesTotal(res.data.meta.pagination.total_pages));
      dispatch(setEventsStatus("fulfilled"));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setEventsStatus("error"));
    });
};
