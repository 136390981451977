/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useState } from "react";
import { CircularProgress, Skeleton } from "@mui/material";
import SuiSelect from "../../../../../../../components/SuiSelect";
import { toTitleCase } from "../../../../../../../redux/utils";
import SuiBadge from "../../../../../../../components/SuiBadge";

const statusOptions = [
  { value: "cancelled", label: "Cancelled" },
  { value: "pending", label: "Pending" },
  { value: "paid", label: "Paid" },
  { value: "completed", label: "Completed" },
];

function Header({ data, loading, onStatusChange }) {
  const [innerLoading, setInnerLoading] = useState(false);

  const handleStatus = async (e) => {
    if (e.value !== data.status) {
      setInnerLoading(true);
      await onStatusChange(e.value);
      setInnerLoading(false);
    }
  };
  return (
    <SuiBox display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <SuiBox>
        <SuiBox mb={1}>
          <SuiTypography variant="h6" fontWeight="medium">
            Order Details
          </SuiTypography>
          {data?.invoice && (
            <SuiBadge
              variant="contained"
              color="success"
              size="extra-small"
              badgeContent="invoice"
              container
            />
          )}
        </SuiBox>
        {loading ? (
          <SuiBox>
            <Skeleton variant="text" width={180} height={24} />
            <Skeleton variant="text" width={150} height={24} />
            <Skeleton variant="text" width={180} height={24} />
          </SuiBox>
        ) : (
          <>
            <SuiTypography component="p" variant="button" fontWeight="regular" textColor="text">
              Order no. <span className="font-bold">{data?.id}</span>
            </SuiTypography>
            <SuiTypography component="p" variant="button" fontWeight="regular" textColor="text">
              Order date.{" "}
              <span className="font-bold">
                {data?.created &&
                  new Date(data?.created).toLocaleDateString("el-GR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
              </span>
            </SuiTypography>
            <SuiTypography component="p" variant="button" fontWeight="regular" textColor="text">
              Name: <span className="font-bold">{data?.name}</span>
            </SuiTypography>
          </>
        )}
      </SuiBox>
      {data?.status && (
        <SuiBox display="flex" alignItems="center" opacity={loading ? 0 : 1}>
          <SuiBox width="25px" mr={2} display="flex" alignItems="center" justifyContent="center">
            {innerLoading && <CircularProgress color="dark" size={25} />}
          </SuiBox>
          <SuiBox width="15%" minWidth="135px">
            <SuiSelect
              isDisabled={innerLoading || loading}
              style={{ minWidth: "fit-content", width: "100%", flex: 1 }}
              options={statusOptions}
              // success={success}
              size="large"
              onChange={handleStatus}
              defaultValue={{ label: toTitleCase(data?.status), value: data?.status }}
            />
          </SuiBox>
        </SuiBox>
      )}
    </SuiBox>
  );
}

export default Header;
