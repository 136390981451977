/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

export default {
  formId: "box-categories-form",
  formField: {
    title: {
      name: "name",
      label: "title",
      type: "text",
      placeholder: "",
    },
    subtitle: {
      name: "label",
      label: "subtitle",
      type: "text",
      placeholder: "",
    },
    url: {
      name: "custom_url",
      label: "custom url",
      type: "text",
      placeholder: "",
    },
    featured: {
      name: "image",
      label: "featured image",
      type: "featured",
      placeholder: "",
    },
  },
};
