export default {
  formId: "video-widget",
  formField: {
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    menuTitle: {
      name: "menu_title",
      label: "Menu Title",
      type: "text",
      errorMsg: "Last name is required.",
    },
    videoUrl: {
      name: "url",
      label: "Video Url",
      type: "text",
      errorMsg: "Last name is required.",
    },
  },
};
