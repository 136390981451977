import axiosInstanceProxy from "./config";

export const getTicketsRequest = ({
  search,
  sort = "created_at",
  order = "desc",
  totalPerPage,
  page,
  category,
  include,
  date,
}) =>
  axiosInstanceProxy.get("/admin/tickets", {
    params: {
      search,
      sort,
      order,
      page,
      totalPerPage,
      category,
      include,
      date,
    },
  });
