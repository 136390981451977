/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// NewUser page components
import React, { useState } from "react";
import { Field } from "formik";
import { useHistory } from "react-router-dom";
import SuiSelect from "../../../../../../components/SuiSelect";
import SuiButton from "../../../../../../components/SuiButton";

const options = [
  { label: "Exhibit", value: "exhibit" },
  { label: "Exhibition", value: "exhibition" },
  { label: "General Page", value: "generic" },
  { label: "Publication", value: "publication" },
];

function CreatePage({ setStepIndex, handleChange }) {
  const history = useHistory();
  const [state, setState] = useState("...");

  const handleValueChange = (e) => {
    setState(e);
    handleChange(e.value);
  };

  const handleNext = () => {
    setStepIndex((prev) => prev + 1);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <SuiBox>
      <SuiBox lineHeight={0}>
        <SuiTypography variant="h5" fontWeight="bold">
          Create New Page
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1.625}>
        <Grid container mb={20}>
          <Grid item xs={12} sm={6}>
            <SuiTypography variant="h6" mb={1}>
              Select the page type you want to create
            </SuiTypography>
            <SuiSelect value={state} options={options} onChange={handleValueChange} />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={2} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="gradient" buttonColor="light" onClick={handleBack}>
          back
        </SuiButton>
        <SuiButton variant="gradient" buttonColor="dark" onClick={handleNext}>
          next
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserInfo
CreatePage.propTypes = {
  setStepIndex: PropTypes.func.isRequired,
  handleChange: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CreatePage;
