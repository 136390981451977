export default {
  formId: "list-widget",
  formField: {
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    menuTitle: {
      name: "menu_title",
      label: "Menu Title",
      type: "text",
      errorMsg: "Last name is required.",
    },
    items: {
      name: "items",
      type: "widgetSpecial",
      widget: "list",
    },
  },
};
