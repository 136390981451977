/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "categories-form",
  formField: {
    name: {
      name: "name",
      label: "category name",
      type: "text",
      placeholder: "",
      errorMsg: "Category name is required.",
    },
    subcategory: {
      name: "subcategories",
      label: "subcategory name",
      type: "text",
      placeholder: "",
      errorMsg: "SbCategory name is required.",
    },
  },
};
