// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import dataTableData from "./data/dataTableData";
import AddButton from "../../../../components/addButton";
import { getEvents } from "../../../../redux/Middleware/EventsThunk";
import useToastContext from "../../../../context/ToastContext";

const filters = {
  key: "category",
  options: [
    { label: "Choose category", value: null },
    { label: "Εκπαιδευτικό Πρόγραμμα", value: "Εκπαιδευτικό Πρόγραμμα" },
    { label: "Ταξίδι Φίλων", value: "Ταξίδι Φίλων" },
    { label: "Εκδήλωση", value: "Εκδήλωση" },
    { label: "Συντήρηση", value: "Συντήρηση" },
    { label: "Επισκέψεις - Ξεναγήσεις", value: "Επισκέψεις - Ξεναγήσεις" },
    { label: "Φύλαξη", value: "Φύλαξη" },
    { label: "Διάλεξη", value: "Διάλεξη" },
    { label: "Λοιπά Προγράμματα & Δράσεις", value: "Λοιπά Προγράμματα & Δράσεις" },
    { label: "Έκθεση", value: "Έκθεση" },
  ],
};

function EventsListPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const eventsData = useSelector((state) => state.eventsReducer.events);
  const totalEntries = useSelector((state) => state.eventsReducer.eventsTotal);
  const totalPages = useSelector((state) => state.eventsReducer.eventsPagesTotal);
  const status = useSelector((state) => state.eventsReducer.status);
  const [data, setData] = useState(dataTableData);
  const [selected, setSelected] = useState([]);
  const showToast = useToastContext();

  useEffect(() => {
    if (eventsData) setData((prev) => ({ ...prev, rows: eventsData }));
  }, [eventsData]);

  const handleUpdate = useCallback((params) => {
    dispatch(getEvents(params));
  }, []);

  const handleNew = () => {
    if (selected.length === 0) {
      showToast({
        title: "No event selected",
        content: "Please select at least one Event",
        type: "warning",
      });
      return;
    }
    history.push(`/events/new-event/${selected.map((e) => e.original.id).toString()}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Events List
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {totalEntries ?? 0} events
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <AddButton onClick={handleNew} />
            </SuiBox>
          </SuiBox>

          <DataTable
            setSelected={setSelected}
            loading={status === "loading"}
            totalPages={totalPages}
            totalEntries={totalEntries}
            fetch={handleUpdate}
            table={data}
            canSearch
            customFilters={filters}
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default EventsListPage;
