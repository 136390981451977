/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CircularProgress, Switch } from "@mui/material";
import SuiBox from "../SuiBox";
import SuiTypography from "../SuiTypography";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableItem = React.memo(({ renderItem, item, index, dragEnabled, itemProps }) => (
  <Draggable
    isDragDisabled={!dragEnabled}
    key={item.id ?? `item-${index}`}
    draggableId={item?.id?.toString() ?? `item-${index}`}
    index={index}
  >
    {(provided) => (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        {renderItem({ item, index, itemProps, disabled: dragEnabled })}
      </div>
    )}
  </Draggable>
));

const DraggableInnerList = React.memo(({ items, renderItem, dragEnabled, itemProps }) =>
  items?.map((item, index) => (
    <DraggableItem
      renderItem={renderItem}
      item={item}
      index={index}
      key={item.id ?? `item-${index}`}
      dragEnabled={dragEnabled}
      itemProps={itemProps}
    />
  ))
);

const DraggableList = ({
  items = [],
  renderItem,
  onSave,
  itemProps,
  showSwitch = true,
  showOverlay = true,
  sortBy = undefined,
  listId = "list",
  ...rest
}) => {
  const [state, setState] = useState({ items: [...items] });
  const [dragEnabled, setDragEnabled] = useState(!showSwitch);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (items) {
      setState({ items: [...items] });
    }
  }, [items, items.length]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setLoading(true);
    const newItems = reorder(state.items, result.source.index, result.destination.index);
    setState({ items: newItems });
    await onSave(
      state.items[result.source.index]?.id,
      result.source.index,
      result.destination.index,
      newItems
    );
    setLoading(false);
  };
  return (
    <SuiBox {...rest}>
      {showSwitch && items?.length > 1 && (
        <SuiBox display="flex" flexDirection="row" mb={2}>
          <SuiTypography variant="h5" mr={2}>
            Reorder
          </SuiTypography>
          <Switch checked={dragEnabled} onChange={() => setDragEnabled((v) => !v)} />
          {loading && (
            <SuiBox sx={{ display: "flex" }} ml={4}>
              <CircularProgress size={25} color="info" />
            </SuiBox>
          )}
        </SuiBox>
      )}
      <SuiBox
        border={`1px solid ${
          state?.items?.length > 0 && dragEnabled && showOverlay ? "lightGrey" : "white"
        }`}
        borderRadius="8px"
        p={2}
        backgroundColor={
          state?.items?.length > 0 && dragEnabled && showOverlay ? "#00000004" : undefined
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={listId} direction="vertical">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <DraggableInnerList
                  items={state.items}
                  renderItem={renderItem}
                  dragEnabled={dragEnabled}
                  itemProps={itemProps}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </SuiBox>
    </SuiBox>
  );
};

export default DraggableList;
