// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dataTableData from "./data/dataTableData";
import AddButton from "../../../../components/addButton";
import { getPages } from "../../../../redux/reducers/cmsSlice";

const filters = {
  key: "type",
  options: [
    { label: "exhibit", value: "exhibit" },
    { label: "exhibition", value: "exhibition" },
    { label: "general page", value: "generic" },
    { label: "publications", value: "publications" },
  ],
};

const PagesPage = () => {
  const dispatch = useDispatch();
  const pages = useSelector((state) => state.cmsReducer.pages);
  const pagesTotal = useSelector((state) => state.cmsReducer.pagesTotal);
  const pagesTotalPages = useSelector((state) => state.cmsReducer.pagesTotalPages);
  const status = useSelector((state) => state.cmsReducer.status);
  const [data, setData] = useState(dataTableData);

  useEffect(() => {
    if (pages) setData((prev) => ({ ...prev, rows: [...pages] }));
  }, [pages]);

  const handleUpdate = (params) => dispatch(getPages(params));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Pages
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {pagesTotalPages} pages
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <AddButton component={Link} to="/cms/new-page" />
            </SuiBox>
          </SuiBox>

          <DataTable
            loading={status === "loading"}
            table={data}
            canSearch
            fetch={handleUpdate}
            totalEntries={pagesTotal}
            totalPages={pagesTotalPages}
            customFilters={filters}
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
};

export default PagesPage;
