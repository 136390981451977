/* eslint-disable react/prop-types */
import { memo, useCallback, useState } from "react";
// Dropzone components
// import Dropzone from "dropzone";
// Dropzone styles
// import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Custom styles for the SuiDropzone
import styles from "components/SuiDropzone/styles";
import { uploadFile } from "react-s3";
import { useDropzone } from "react-dropzone";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import Icon from "@mui/material/Icon";
import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import SuiTypography from "../SuiTypography";

const LoadingBar = () => (
  <Box
    sx={{
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
    }}
  >
    <LinearProgress color="info" />
  </Box>
);

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

export const handleUpload = async (file) => {
  try {
    const res = await uploadFile(file, config);
    return res.location;
  } catch (err) {
    return Error(err?.message ? err?.message : err);
  }
};

function SuiDropzone({ getImageUrl, value = "", multiple = false, options = {} }) {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        try {
          setLoading(true);
          const res = await handleUpload(acceptedFiles[0]);
          if (multiple) {
            getImageUrl([...value, res]);
          } else {
            getImageUrl(res);
          }
          setLoading(false);
        } catch (e) {
          setError(e?.message || e);
        }
      }
    },
    [multiple, value]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    maxSize: 2097152,
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop,
    onDropAccepted: () => {
      setError("");
    },
    onDropRejected: (fileRejections) => {
      if (fileRejections?.length > 0) {
        setError(fileRejections?.[0]?.errors?.[0]?.message ?? "");
      } else {
        setError("");
      }
    },
    onError: (err) => {
      console.log("error", err);
      setError(err?.message || err);
    },
  });

  const handleRemove = (e) => {
    e.preventDefault(e.stopPropagation());
    getImageUrl("");
  };

  const handleRemoveFromMultiple = (index) => (e) => {
    e.preventDefault(e.stopPropagation());
    const temp = [...value];
    temp.splice(index, 1);
    getImageUrl(temp);
  };

  return (
    <>
      <Box
        className={classes.suiDropzone}
        sx={{
          backgroundColor: isDragActive ? "lightblue" : undefined,
          width: ["300px", "380px", "550px"],
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {value && !multiple ? (
          <Box className="image-container" onClick={handleRemove}>
            <img src={value} alt="featured" width="120px" height="120px" />
            <Box className="image-overlay">
              <Icon color="secondary" size="large">
                <HighlightOffRoundedIcon sx={{ fontSize: 50 }} />
              </Icon>
            </Box>
          </Box>
        ) : null}
        {value && multiple
          ? Array.isArray(value) &&
            value.map((el, i) => {
              let url;
              if (el && typeof el !== "string" && el.url) {
                url = el.url;
              }
              if (el && typeof el === "string") {
                url = el;
              }
              return (
                <Box
                  key={url}
                  className="image-container multiple"
                  onClick={handleRemoveFromMultiple(i)}
                >
                  <img src={url} alt="featured" width="120px" height="120px" />
                  <Box className="image-overlay">
                    <Icon color="secondary" size="large">
                      <HighlightOffRoundedIcon sx={{ fontSize: 50 }} />
                    </Icon>
                  </Box>
                </Box>
              );
            })
          : null}
        {/* eslint-disable-next-line no-nested-ternary */}
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : !(multiple && value.length > 1) ? (
          <p>Drag 'n' drop some files here, or click to select files</p>
        ) : null}
        {!value ? (
          <SuiBox display="flex" flexDirection="column" height="100%" justifyContent="center">
            <SuiTypography component="label" variant="caption">
              Recommended dimensions: 1920x500
            </SuiTypography>
            <SuiTypography component="label" variant="caption">
              Max filesize: 2MB
            </SuiTypography>
            <SuiTypography component="label" variant="caption">
              Accepted files: .png, .jpg, .jpeg
            </SuiTypography>
          </SuiBox>
        ) : null}
        {loading ? <LoadingBar /> : null}
      </Box>
      <SuiTypography variant="caption" textColor="error">
        {error}
      </SuiTypography>
    </>
  );
}

const arePropsEqual = (prev, next) => prev.options === next.options && prev.value === next.value;

export default memo(SuiDropzone, arePropsEqual);
