import React from "react";
import { Avatar } from "@mui/material";
import { UsersActionCell } from "../../../../../components/TableComponents/ActionCell";

export default {
  columns: [
    {
      Header: "",
      accessor: "avatar",
      width: "8%",
      Cell: ({ value }) => <Avatar src={value} />,
    },
    { Header: "name", accessor: "name" },
    { Header: "username", accessor: "username" },
    { Header: "email", accessor: "email" },
    { Header: "description", accessor: "description" },
    { Header: "role", accessor: "role" },
    {
      Header: "",
      id: "actions",
      Cell: ({ row }) => <UsersActionCell row={row} />,
    },
  ],
  rows: [],
};
