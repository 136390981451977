import IdCell from "../../../../../components/TableComponents/IdCell";
import { DateCell } from "../../../../../components/TableComponents/DefaultCell";
import { EventListActionCell } from "../../../../../components/TableComponents/ActionCell";

/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  columns: [
    {
      Header: "id",
      accessor: "id",
      width: "50px",
    },
    { Header: "title", accessor: "name" },
    { Header: "category", accessor: "category" },
    {
      Header: "date",
      accessor: (original) => original?.event?.start,
      style: { color: "blue" },
      width: "80px",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <DateCell value={value} />,
    },
    {
      id: "actions",
      width: "10px",
      Cell: ({ row }) => <EventListActionCell row={row} />,
    },
  ],
  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
};
