import axiosInstanceProxy from "./config";

export const getEventsRequest = ({
  search,
  sort = "id",
  order = "desc",
  totalPerPage,
  page,
  category,
  include,
  date,
  filter,
}) =>
  axiosInstanceProxy.get("/admin/events", {
    params: {
      search,
      sort,
      order,
      page,
      totalPerPage,
      category,
      include,
      date,
      filter,
    },
  });

export const getEventRequest = (id) => axiosInstanceProxy.get(`/admin/event/${id}/show`);

export const searchEventPagesRequest = (params) =>
  axiosInstanceProxy.get("/admin/cms/pages", {
    params: {
      ...params,
      type: "pevent",
      sort: "id",
      order: "desc",
    },
  });
