/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getOrder, updateOrderStatus } from "../../../../../redux/reducers/shopSlice";
import OrderDetails from "../order-details";
import datatable, { datatableTicket } from "../data/modalTableData";

const ModalOrderInfo = ({ open, onClose, id, ticket }) => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.shopReducer.showOrderStatus);
  const [data, setData] = useState();
  const [dataTableData, setDataTableData] = useState(ticket ? datatableTicket : datatable);

  const onRefresh = useCallback(async () => {
    dispatch(getOrder({ id }))
      .unwrap()
      .then((res) => {
        const temp = {
          ...res.data.data,
          addresses: res.data.data.addresses.data.reduce(
            (obj, item) => Object.assign(obj, { [item.type]: item }),
            {}
          ),
        };
        setData(temp);
        setDataTableData((p) => ({ ...p, rows: temp.order_lines.data }));
      });
  }, [id]);

  useEffect(() => {
    if (id || id === 0) {
      onRefresh();
    }
    return () => {
      setData(null);
      setDataTableData(ticket ? datatableTicket : datatable);
    };
  }, [id]);

  const onStatusChange = async (newStatus) => {
    await dispatch(updateOrderStatus({ id: data.id, status: newStatus }));
    await onRefresh();
  };

  return (
    <Modal
      open={open}
      onBackdropClick={onClose}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <>
        <OrderDetails
          dataTable={dataTableData}
          data={data}
          loading={status === "loading"}
          onStatusChange={onStatusChange}
          onClose={onClose}
        />
      </>
    </Modal>
  );
};

export default ModalOrderInfo;
