// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dataTableData from "./data/dataTableData";
import AddButton from "../../../../components/addButton";
import { getUsers } from "../../../../redux/reducers/usersSlice";

const UsersPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(dataTableData);
  const users = useSelector((state) => state.usersReducer.users);
  const status = useSelector((state) => state.usersReducer.status);

  useEffect(() => {
    if (users) {
      setData((prev) => ({ ...prev, rows: [...users] }));
    }
  }, [users]);

  const handleUpdate = (params) => {
    dispatch(getUsers(params));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Users
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <AddButton component={Link} to="/new-user" />
            </SuiBox>
          </SuiBox>

          <DataTable
            fetch={handleUpdate}
            showTotalEntries={false}
            noPagination
            loading={status === "loading"}
            table={data}
            canSearch
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
};

export default UsersPage;
