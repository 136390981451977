import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  clearShowPage,
  createPage,
  showPage,
  updatePage,
} from "../../../../../redux/reducers/cmsSlice";

import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import SuiTypography from "../../../../../components/SuiTypography";
import FieldsWrapper from "../../../../../components/FieldsWrapper";

function NewBanner() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { formId, formField } = form;
  const page = useSelector((state) => state.cmsReducer.showPage);
  const [pageData, setPageData] = useState(initialValues);
  const [title, setTitle] = useState("");
  const [formFields, setFormFields] = useState(formField);

  useEffect(() => {
    const pageTitle = location.pathname.includes("banner")
      ? "shop banner"
      : location.pathname.includes("slider")
      ? "carousel slider"
      : "";

    if (pageTitle === "shop banner") {
      setFormFields((p) => ({ ...p, showHomepage: {} }));
    }
    if (pageTitle === "carousel slider") {
      setFormFields((p) => ({ ...p, showShopBanner: {} }));
    }
    setTitle(pageTitle);
  }, [location]);

  useEffect(() => {
    if (id) {
      dispatch(showPage(id));
    }
    return () => dispatch(clearShowPage());
  }, [id]);

  useEffect(() => {
    if (id && page) {
      setPageData({ ...page, pageType: page.type, pageId: page.id, ...page.meta, meta: undefined });
    }
  }, [page]);

  const submitForm = async (values, actions) => {
    if (!id) {
      await dispatch(createPage(values));
    } else {
      await dispatch(updatePage(values));
    }
    actions.setSubmitting(false);
    await actions.resetForm();
    if (location.pathname.includes("banner")) {
      history.push("/cms/shop-banners");
    } else {
      history.push("/cms/carousel");
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3} mb={20}>
        <Grid container justifyContent="center" className="h-100">
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={pageData}
              enableReinitialize
              // validationSchema={currentValidation}
              onSubmit={submitForm}
            >
              {({ isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card className="h-100">
                    <SuiBox p={2}>
                      <SuiBox>
                        <SuiBox>
                          <SuiBox lineHeight={0}>
                            <SuiTypography
                              variant="h5"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {id ? "Edit" : "Create"} {title}
                            </SuiTypography>
                          </SuiBox>
                          <SuiBox mt={1.625}>
                            <FieldsWrapper fields={Object.values(formFields)} />
                          </SuiBox>
                        </SuiBox>
                        <SuiBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <SuiButton variant="gradient" buttonColor="light" onClick={handleBack}>
                            back
                          </SuiButton>
                          <SuiButton
                            disabled={isSubmitting}
                            variant="gradient"
                            buttonColor="dark"
                            type="submit"
                          >
                            save
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default NewBanner;
