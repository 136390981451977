export default {
  formId: "edit-item",
  formField: {
    image: {
      name: "featured",
      label: "Image",
      type: "image",
      paired: true,
    },
    images: {
      name: "images",
      label: "Image",
      type: "image-multiple",
      paired: true,
    },
    name: {
      name: "name",
      label: "name",
      type: "text",
    },
    code: {
      name: "code",
      label: "code",
      type: "text",
      disabled: true,
    },
    weight: {
      name: "weight",
      label: "weight",
      type: "number",
    },
    subCategories: {
      name: "subcategories",
      label: "categories",
      type: "category",
      options: [{ label: "Please Select", value: undefined }],
    },
    description: {
      name: "description",
      label: "description",
      type: "html",
    },
    material: {
      name: "material",
      label: "material",
      type: "text",
    },
    language: {
      name: "language",
      label: "language",
      type: "text",
    },
    age: {
      name: "age",
      label: "age",
      type: "text",
    },
    dimensions: {
      name: "dimensions",
      label: "dimensions",
      type: "text",
    },
    design: {
      name: "designer",
      label: "design",
      type: "text",
      multiline: true,
    },
    isbn: {
      name: "isbn",
      label: "ISBN",
      type: "text",
    },
    author: {
      name: "author",
      label: "author",
      type: "text",
    },
    pages: {
      name: "pages",
      label: "pages",
      type: "text",
    },
    illustrator: {
      name: "illustrator",
      label: "illustrator",
      type: "text",
    },
    attributes: {
      name: "attrlabels",
      type: "attributes",
      parentLabel: "attribute",
      parentName: "title",
      childArrayName: "attrvalues",
      childName: "title",
      childLabel: "value",
    },
    group: {
      name: "boxcategories",
      label: "group (box categories)",
      type: "checkboxGroup",
      options: [
        // {name:}
      ],
    },
    shopSlider: {
      name: "shopSlider",
      label: "shop slider",
      type: "checkboxGroup",
      options: [
        // {name:}
      ],
    },
    curatorsChoice: {
      name: "curator",
      label: "curator's choice",
      type: "checkbox",
    },
    onSale: {
      name: "on_sale",
      label: "on sale",
      type: "checkbox",
    },
    itemOfTheMonth: {
      name: "item_of_the_month",
      label: "item of the month",
      type: "checkbox",
    },
    metaTitle: {
      name: "meta_title",
      label: "meta data - title (max 60 characters)",
      type: "text",
    },
    metaDescription: {
      name: "meta_description",
      label: "meta data - description (max 155 characters)",
      type: "text",
    },
  },
};
