/* eslint-disable react/prop-types */

import { DateTimeCell } from "../../../../../components/TableComponents/DefaultCell";
import { OrderActionCell } from "../../../../../components/TableComponents/ActionCell";
import { MemberInfoCompactCell } from "../../../../../components/TableComponents/Shared";
import OrderStatusCell from "../../../../../components/TableComponents/OrderStatusCell";

export default {
  columns: [
    { Header: "id", accessor: "id", width: "50px" },
    {
      Header: "name - email",
      accessor: (e) => ({ name: e.name, email: e.email }),
      Cell: ({ value }) => <MemberInfoCompactCell name={value.name} email={value.email} />,
      width: "200px",
    },
    {
      Header: "event",
      accessor: (e) =>
        e?.order_lines?.data?.[0]?.name ||
        e?.order_lines?.data?.[0]?.options?.ticket?.page?.title ||
        "-",
      width: "200px",
    },
    {
      Header: "quantity",
      accessor: (e) => e?.order_lines?.data?.[0]?.quantity ?? "-",
      width: "40px",
    },
    {
      Header: "status",
      accessor: "status",
      width: "90px",
      Cell: ({ row: { original } }) => <OrderStatusCell item={original} />,
    },
    {
      Header: "order date",
      accessor: "created",
      width: "100px",
      Cell: ({ value }) => <DateTimeCell value={value} />,
    },
    {
      Header: "",
      id: "actions",
      width: "20px",
      Cell: ({ row, state }) => <OrderActionCell item={row.original} state={state} ticket />,
    },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
};

// /* eslint-disable react/prop-types */
// import { MemberInfoCompactCell } from "../../../../../components/TableComponents/Shared";
//
// /**
// =========================================================
// * Soft UI Dashboard PRO React - v2.0.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
//  =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */
//
// export default {
//   columns: [
//     { Header: "id", accessor: "id", width: "50px" },
//     {
//       Header: "name - email",
//       accessor: (e) => ({ name: e.name, email: e.email }),
//       Cell: ({ value }) => <MemberInfoCompactCell name={value.name} email={value.email} />,
//       width: "200px",
//     },
//     // { Header: "email", accessor: "email" },
//     {
//       Header: "event",
//       accessor: (e) =>
//         e?.order_lines?.data?.[0]?.name ||
//         e?.order_lines?.data?.[0]?.options?.ticket?.page?.title ||
//         "-",
//     },
//     { Header: "notes", accessor: "notes" },
//     {
//       Header: "quantity",
//       accessor: (e) => e?.order_lines?.data?.[0]?.quantity ?? "-",
//       width: "60px",
//     },
//     { Header: "price", accessor: "total", width: "60px" },
//   ],
//
//   rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
// };
