/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";

// formik components
// import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// NewUser page components

// NewUser layout schemas for form and form feilds
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CreatePage from "./components/CreatePage";
import Settings from "./components/Settings";

function getSteps() {
  return ["Create Page", "Settings"];
}

function getStepContent(stepIndex, setStepIndex, pageType, setPageType = () => {}) {
  switch (stepIndex) {
    case 0:
      return <CreatePage handleChange={setPageType} setStepIndex={setStepIndex} />;
    case 1:
      return <Settings pageType={pageType} setStepIndex={setStepIndex} />;
    default:
      return null;
  }
}

function NewPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [pageType, setPageType] = useState("");
  const steps = getSteps();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3} mb={20}>
        <Grid container justifyContent="center" className="h-100">
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card className="">
              <SuiBox p={2}>
                <SuiBox>{getStepContent(activeStep, setActiveStep, pageType, setPageType)}</SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default NewPage;
