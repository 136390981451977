/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dataTableData from "./data/dataTableData";
import AddButton from "../../../../../components/addButton";
import { getCarousel, reorderCarousel } from "../../../../../redux/reducers/cmsSlice";
import useToastContext from "../../../../../context/ToastContext";

function CarouselPage() {
  const dispatch = useDispatch();
  const carousel = useSelector((state) => state.cmsReducer.carousel);
  const carouselTotal = useSelector((state) => state.cmsReducer.carouselTotal);
  const carouselTotalPages = useSelector((state) => state.cmsReducer.carouselTotalPages);
  const status = useSelector((state) => state.cmsReducer.status);
  const showToast = useToastContext();
  const [data, setData] = useState(dataTableData);

  useEffect(() => {
    if (carousel) setData((prev) => ({ ...prev, rows: carousel }));
  }, [carousel]);

  const handleUpdate = (params) => {
    dispatch(getCarousel({ ...params }));
  };

  const handleReorder = async (id, index) => {
    try {
      await dispatch(reorderCarousel({ id, index })).unwrap();
      showToast({
        type: "success",
        title: "Banner reordered successfully",
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "There was an error",
        content: e?.message || e,
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Carousel List
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {carouselTotal} items
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <AddButton component={Link} to="/cms/new-slider" />
            </SuiBox>
          </SuiBox>

          <DataTable
            table={data}
            canSearch
            // ={false}
            noPagination
            showTotalEntries={false}
            totalEntries={0}
            fetch={handleUpdate}
            totalPages={carouselTotalPages}
            handleReorderRow={handleReorder}
            loading={status === "loading"}
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default CarouselPage;
