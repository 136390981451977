/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Soft UI Dashboard PRO Material are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import MembersListPage from "./layouts/pages/members/members-list";
import SubscriptionsPage from "./layouts/pages/members/subscriptions";
import EventsListPage from "./layouts/pages/events/events-list";
import TicketsPage from "./layouts/pages/events/tickets";
import ItemsListPage from "./layouts/pages/shop/items-list";
import CategoriesPage from "./layouts/pages/shop/categories";
import OrdersPage from "./layouts/pages/shop/orders";
import ShippingPage from "./layouts/pages/shop/shipping";
import CouponsPage from "./layouts/pages/shop/coupons";
import DonationsPage from "./layouts/pages/shop/donations";
import PagesPage from "./layouts/pages/cms/pages";
import ShopBannersPage from "./layouts/pages/cms/Banners/shop-banners";
import EventPagesPage from "./layouts/pages/events/event-pages";
import Office from "./assets/Office";
import Document from "./assets/Document";
import CustomerSupport from "./assets/CustomerSupport";
import Basket from "./assets/Basket";
import CarouselPage from "./layouts/pages/cms/Banners/carousel";

const routes = [
  {
    type: "collapse",
    name: "Members",
    key: "members",
    icon: <CustomerSupport size="12px" />,
    collapse: [
      {
        name: "Members List",
        key: "members-list",
        route: "/members/members-list",
        component: MembersListPage,
      },
      {
        name: "Subscriptions",
        key: "subscriptions",
        route: "/members/subscriptions",
        component: SubscriptionsPage,
      },
    ],
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "Events List",
        key: "events-list",
        route: "/events/events-list",
        component: EventsListPage,
      },
      {
        name: "Tickets",
        key: "tickets",
        route: "/events/tickets",
        component: TicketsPage,
      },
      {
        name: "Event Pages",
        key: "event-pages",
        route: "/events/event-pages",
        component: EventPagesPage,
      },
    ],
  },
  {
    type: "collapse",
    name: "Shop",
    key: "shop",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "Items List",
        key: "items-list",
        route: "/shop/items-list",
        component: ItemsListPage,
      },
      {
        name: "Categories",
        key: "categories",
        route: "/shop/categories",
        component: CategoriesPage,
      },
      {
        name: "Orders",
        key: "orders",
        route: "/shop/orders",
        component: OrdersPage,
      },
      {
        name: "Shipping",
        key: "shipping",
        route: "/shop/shipping",
        component: ShippingPage,
      },
      {
        name: "Coupons",
        key: "coupons",
        route: "/shop/coupons",
        component: CouponsPage,
      },
      {
        name: "Donations",
        key: "donations",
        route: "/shop/donations",
        component: DonationsPage,
      },
    ],
  },
  {
    type: "collapse",
    name: "CMS",
    key: "cms",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Pages",
        key: "pages",
        route: "/cms/pages",
        component: PagesPage,
      },
      {
        name: "Shop Banners",
        key: "shop-banners",
        route: "/cms/shop-banners",
        component: ShopBannersPage,
      },
      {
        name: "Carousel",
        key: "carousel",
        route: "/cms/carousel",
        component: CarouselPage,
      },
      // {
      //   name: "Widgets",
      //   key: "widgets",
      //   route: "/cms/widgets",
      //   component: WidgetsPage,
      // },
      // {
      //   name: "Guide",
      //   key: "guide",
      //   route: "/cms/guide",
      //   component: GuidePage,
      // },
    ],
  },
];

export default routes;
