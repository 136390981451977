/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import dataTableData from "./data/dataTableData";
import { getSubscriptions } from "../../../../redux/Middleware/MembersThunk";

// const filters = {
//   key: "subscription",
//   options: [
//     { label: "Όλες οι συνδρομές", value: null },
//     { label: "Νέοι έως 35 ετών", value: "Νέοι έως 35 ετών" },
//     { label: "Απλή συνδρομή", value: "Απλή συνδρομή" },
//     { label: "Χορηγός", value: "Χορηγός" },
//     { label: "INTL Young Patron", value: "INTL Young Patron" },
//     { label: "CSC Χορηγός", value: "CSC Χορηγός" },
//     { label: "Διπλή Συνδρομή", value: "Διπλή Συνδρομή" },
//     { label: "Υποστηρικτής", value: "Υποστηρικτής" },
//     { label: "Honorary Member", value: "Honorary Member" },
//     { label: "Χορηγός - Διετής", value: "Χορηγός - Διετής" },
//     { label: "Δωρητής", value: "Δωρητής" },
//     { label: "INTL Young Patron dual", value: "INTL Young Patron dual" },
//     { label: "ΜΚΤ", value: "ΜΚΤ" },
//     { label: "Απλή συνδρομή - Διετής", value: "Απλή συνδρομή - Διετής" },
//     { label: "Νέοι έως 35 ετών - Διετής", value: "Νέοι έως 35 ετών - Διετής" },
//     { label: "Διπλή Συνδρομή - Διετής", value: "Διπλή Συνδρομή - Διετής" },
//     { label: "Υποστηρικτής - Διετής", value: "Υποστηρικτής - Διετής" },
//   ],
// };

function SubscriptionsPage() {
  const dispatch = useDispatch();
  const subscriptionsList = useSelector((state) => state.optionsReducer.subscriptions);
  const subscriptionsData = useSelector((state) => state.membersReducer.subscriptions);
  const subsTotal = useSelector((state) => state.membersReducer.subscriptionsTotal);
  const totalPages = useSelector((state) => state.membersReducer.subscriptionsPagesTotal);
  const status = useSelector((state) => state.membersReducer.status);

  const [data, setData] = useState(dataTableData);

  useEffect(() => {
    if (subscriptionsData) {
      setData((prev) => ({ ...prev, rows: subscriptionsData }));
    }
  }, [subscriptionsData]);

  const filters = useMemo(() => {
    const options = subscriptionsList.map((subscription) => {
      if (subscription.active) {
        filters.options.push({ label: subscription.name, value: subscription.id });
      }
      return subscription;
    });
    return {
      key: "subscription",
      label: "Choose subscription",
      options: [{ label: "Όλες οι συνδρομές", value: null }, ...options],
    };
  }, [subscriptionsList]);

  const handleUpdate = useCallback((params) => {
    dispatch(getSubscriptions(params));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle="Subscriptions" breadcrumbRoutes={["subscriptions"]} />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox p={3} lineHeight={1}>
            <SuiTypography variant="h5" fontWeight="medium">
              Subscriptions List
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              {subsTotal ?? 0} subscriptions
            </SuiTypography>
          </SuiBox>
          <DataTable
            loading={status === "loading"}
            table={data}
            canSearch
            totalPages={totalPages}
            fetch={handleUpdate}
            totalEntries={subsTotal}
            customFilters={filters}
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default SubscriptionsPage;
