/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Tooltip } from "@mui/material";

function ProductCell({ image, name, checked }) {
  return (
    <SuiBox display="flex" alignItems="center">
      <Checkbox defaultChecked={checked} />
      <SuiBox mx={2} width="3.75rem">
        <SuiBox component="img" src={image} alt={name} width="100%" />
      </SuiBox>
      <SuiTypography variant="button" fontWeight="medium">
        {name}
      </SuiTypography>
    </SuiBox>
  );
}

export const ProductThumbnail = ({ img, name }) =>
  img ? (
    <Tooltip
      title={
        <>
          <SuiBox component="img" src={img} alt={name} width="100%" />
        </>
      }
    >
      <SuiBox
        component="img"
        src={img}
        alt={name}
        width="4rem"
        height="4rem"
        sx={{ borderRadius: "100px" }}
      />
    </Tooltip>
  ) : (
    <SuiBox>-</SuiBox>
  );

// Setting default value for the props of ProductCell
ProductCell.defaultProps = {
  checked: false,
};

// Typechecking props for the ProductCell
ProductCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default ProductCell;
