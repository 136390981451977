import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Formik, Form, FieldArray } from "formik";
import { Icon } from "@mui/material";
import WidgetCollapse from "../../Collapse/WidgetCollapse";
import FormField from "../../FormField";
import SuiBox from "../../SuiBox";
import initialValues from "./schema/initialValues";
import form from "./schema/form";
import SuiTypography from "../../SuiTypography";
import AddPublicationModal from "./components/AddPublicationModal";
import SuiButton from "../../SuiButton";
import LanguageToggle from "../../FieldsWrapper/LanguageToggle";

const empty = {
  data: undefined,
  order: undefined,
  type: undefined,
};

// eslint-disable-next-line react/prop-types
const PublicationWidget = ({ widget = empty, onSubmit, onDelete, disabled }) => {
  const { data, order, type } = widget;

  const { formId, formField } = form;

  const { title, menuTitle, bottomLinkText, bottomLinkUrl, columns } = formField;

  const [modal, setModal] = useState(false);
  const [language, setLanguage] = useState("el");

  const handleSubmit = (values, actions) => {
    onSubmit(
      {
        widget: {
          data: values,
          order: order ?? null,
          type: type ?? null,
        },
        widgetId: values.id ?? null,
      },
      actions
    );
  };

  const handleDelete = (values) => {
    onDelete({ data: values, order: order ?? null, type: type ?? null });
  };
  return (
    <Formik
      initialValues={data || initialValues}
      // validationSchema={currentValidation}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, isSubmitting, setFieldValue, setSubmitting }) => (
        <Form id={formId} autoComplete="off">
          <WidgetCollapse
            disabled={disabled}
            title={`Publication Widget${values.title ? ` - ${values.title}` : ""}`}
            onDelete={() => handleDelete(values, setSubmitting)}
          >
            <SuiBox mt={1.625}>
              <LanguageToggle language={language} setLanguage={setLanguage} />

              <Grid container>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={title.type}
                    label={title.label}
                    name={language === "en" ? `${title.name}_en` : title.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={menuTitle.type}
                    label={menuTitle.label}
                    name={language === "en" ? `${menuTitle.name}_en` : menuTitle.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={bottomLinkText.type}
                    label={bottomLinkText.label}
                    name={language === "en" ? `${bottomLinkText.name}_en` : bottomLinkText.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={bottomLinkUrl.type}
                    label={bottomLinkUrl.label}
                    name={language === "en" ? `${bottomLinkUrl.name}_en` : bottomLinkUrl.name}
                  />
                </Grid>
                <FieldArray name="publications">
                  {(arrayHelpers) => (
                    <>
                      <Grid item xs={12} sm={12}>
                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Publications
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox display="flex" flexDirection="column" pl={2}>
                          {values.publications?.map((el, i) => (
                            <SuiBox display="flex" alignItems="center" key={el.id} my={1}>
                              <SuiTypography variant="h6" fontWeight="regular">
                                <li>{el?.page?.title}</li>
                              </SuiTypography>
                              <SuiBox
                                ml={2}
                                sx={{ cursor: "pointer" }}
                                onClick={() => arrayHelpers.remove(i)}
                              >
                                <Icon>delete</Icon>
                              </SuiBox>
                            </SuiBox>
                          ))}
                        </SuiBox>
                      </Grid>
                      <Grid item xs={12} sm={12} mt={1}>
                        <SuiTypography
                          variant="h6"
                          style={{ cursor: "pointer" }}
                          onClick={() => setModal(true)}
                        >
                          + Add Publication
                        </SuiTypography>
                      </Grid>
                      <AddPublicationModal
                        open={modal}
                        setOpen={setModal}
                        initialPublications={values.publications || []}
                        setPublications={(e) => setFieldValue("publications", e)}
                      />
                    </>
                  )}
                </FieldArray>
              </Grid>
              <SuiBox display="flex" justifyContent="flex-start" mt={5}>
                <SuiButton buttonColor="dark" type="submit">
                  Save
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </WidgetCollapse>
        </Form>
      )}
    </Formik>
  );
};

export default PublicationWidget;
