import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  username: yup.string().required("Username is required"),
  email: yup.string().email("Value must be a valid Email").required("Email is required"),
  confirmEmail: yup
    .string()
    .email("Value must be a valid Email")
    .required("Confirmation email is required")
    .test(
      "same as email",
      "Confirmation email must match email",
      (value, ctx) => value === ctx.parent.email
    ),
  password: yup
    .string()
    .min(8, "Password must have minimun of 8 characters")
    .required("Password is required"),
  role: yup.mixed().required("Role is required"),
});
export default schema;
