import axiosInstanceProxy from "./config";

export const getCouponsRequest = ({
  sort = "id",
  order = "desc",
  totalPerPage = 10,
  active = null,
  search = null,
  page = 1,
}) =>
  axiosInstanceProxy.get("/admin/coupons", {
    params: {
      sort,
      order,
      totalPerPage,
      active,
      search,
      page,
    },
  });

export const getUserRequest = (id) => axiosInstanceProxy.get(`/admin/coupons/${id}/show`);

export const createCouponRequest = ({
  amount = 0,
  type = "percentage",
  description = "",
  active = false,
  once = false,
  code = "",
}) =>
  axiosInstanceProxy.post("/admin/coupons/create", {
    amount,
    type,
    description,
    active,
    once,
    code,
  });

export const updateCouponRequest = ({
  id,
  amount = null,
  type = null,
  description = null,
  active = null,
  once = null,
  code,
  quantity,
  has_quantity,
}) =>
  axiosInstanceProxy.post(`/admin/coupons/${id}/update`, {
    amount,
    type: type === "%" ? "percentage" : "amount",
    description,
    active: active?.toLowerCase() === "active",
    once: once?.toLowerCase() === "once",
    has_quantity,
    code,
    quantity,
  });

export const disableCouponRequest = ({ id }) =>
  axiosInstanceProxy.post(`/admin/coupons/${id}/disable`);
