/* eslint-disable react/prop-types */
import React from "react";
import { Card, Modal } from "@mui/material";
import Grid from "@mui/material/Grid";
import SuiBox from "../../../../../../components/SuiBox";
import SuiTypography from "../../../../../../components/SuiTypography";
import SuiButton from "../../../../../../components/SuiButton";

const availableWidgets = [
  "text",
  "sponsors",
  "page",
  "gallery",
  "list",
  "video",
  "bio",
  "publication",
  "subscriptions",
  "ticket",
  "donation",
];

const AddWidgetModal = ({ open, setOpen, handleAddWidget }) => (
  <Modal
    open={open}
    onBackdropClick={() => setOpen(false)}
    onClose={() => setOpen(false)}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflowY: "auto",
    }}
  >
    <Card style={{ width: "40%", maxHeight: "90vh" }}>
      <SuiBox p={4}>
        <SuiTypography
          component="label"
          variant="h3"
          fontWeight="regular"
          textTransform="capitalize"
        >
          choose widget to add
        </SuiTypography>
        <Grid container spacing={3} mt={0.5}>
          {availableWidgets.map((el) => (
            <Grid item xs={12} sm={6} key={`available-widget-${el}`}>
              <SuiBox display="flex" alignItems="center" justifyContent="center">
                <SuiButton style={{ width: "12rem" }} onClick={() => handleAddWidget(el)}>
                  {el} widget
                </SuiButton>
              </SuiBox>
            </Grid>
          ))}
        </Grid>
      </SuiBox>
    </Card>
  </Modal>
);

export default AddWidgetModal;
