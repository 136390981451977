/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { makeStyles } from "@mui/styles";
import colors from "../../assets/theme/base/colors";

export default makeStyles(({ palette, typography, borders, functions }) => {
  const { text, white, dark, inputColors } = palette;
  const { size } = typography;
  const { borderRadius, borderWidth } = borders;
  const { rgba } = functions;
  const { grey } = colors;

  return {
    suiDropzone: {
      display: "flex",
      alignItems: "center",
      border: `${borderWidth[1]} dashed ${inputColors.borderColor.main}`,
      borderRadius: borderRadius.md,
      padding: "20px",
      height: "180px",
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
      backgroundColor: grey[100],
      overflowX: "auto",

      "& .image-container": {
        marginRight: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",

        "&:hover": {
          "& img": {
            opacity: 0.4,
          },
        },
        "& img": {
          borderRadius: 8,
          objectFit: "contain",
          backgroundColor: "white",
          border: `1px solid ${grey[400]}`,
        },

        "& .image-overlay": {
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: 0,
          borderRadius: 8,

          "& span": {
            width: "3em",
            height: "3em",
            "& svg": {
              width: "3em",
              height: "3em",
              color: "rgb(255,255,255)",
            },
          },
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.4)",
            opacity: 1,
          },
        },
      },
      "& .multiple": {
        marginRight: "1rem",
      },

      "& p": {
        color: "gray",
        fontSize: "16px",
      },

      "& .dz-default": {
        margin: "0 auto",
      },

      "& .dz-default .dz-button": {
        color: text.main,
        fontSize: size.sm,
      },

      "& .dz-preview .dz-details": {
        color: dark.main,
        opacity: 1,

        "& .dz-size span, & .dz-filename span": {
          backgroundColor: rgba(white.main, 0.7),
        },
      },

      "& .dz-error-message": {
        display: "none !important",
      },

      "& .dz-remove": {
        color: dark.main,
        textDecoration: "none",

        "&:hover, &:focus": {
          textDecoration: "none !important",
        },
      },
    },
  };
});
