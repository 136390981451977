import { searchEvent } from "../../../../../../redux/reducers/eventsSlice";

export const exhibitionForm = {
  formId: "exhibition-form",
  formField: {
    pageType: {
      name: "pageType",
      label: "Select the kind of page you want to create",
      value: "Exhibition",
    },
    image: {
      name: "featured",
      label: "Image",
      type: "image",
    },
    title: {
      name: "title",
      label: "title",
      type: "text",
    },
    slug: {
      name: "slug",
      label: "slug",
      type: "slug",
      dependentValue: "title",
    },
    description: {
      name: "description",
      label: "description",
      type: "text",
      multiline: true,
    },
    label: {
      name: "label",
      label: "label",
      type: "text",
    },
    dateText: {
      name: "date_text",
      label: "date Text",
      type: "text",
    },
    date: {
      name: "dates",
      label: "dates",
      type: "date",
    },
    type: {
      name: "type",
      label: "type",
      type: "select",
      options: [
        { label: "archaeological", value: "archaeological" },
        { label: "contemporary - modern", value: "contemporary-modern" },
        { label: "Cycladic Café Art Project ", value: "cycladic-cafe-art-project" },
      ],
    },
    showHomepage: {
      name: "homeslider",
      label: "show in Homepage slider",
      type: "checkbox",
    },
    showSuggested: {
      name: "suggested",
      label: "show in Suggested",
      type: "checkbox",
    },
    showShop: {
      name: "shop_banner",
      label: "show in shop banner",
      type: "checkbox",
    },
  },
};

export const publicationForm = {
  formId: "publication-form",
  formField: {
    pageType: {
      name: "pageType",
      value: "Publication",
    },
    image: {
      name: "featured",
      label: "image",
      type: "image",
    },
    title: {
      name: "title",
      label: "title",
      type: "text",
    },
    slug: {
      name: "slug",
      label: "slug",
      type: "slug",
      dependentValue: "title",
    },
    subtitle: {
      name: "subtitle",
      label: "subtitle",
      type: "text",
    },
    label: {
      name: "label",
      label: "label",
      type: "text",
    },

    writers: {
      name: "writers",
      label: "writers",
      type: "text",
      multiline: true,
    },
    description: {
      name: "description",
      label: "description",
      type: "text",
      multiline: true,
    },
    dimensions: {
      name: "dimensions",
      label: "dimensions",
      type: "text",
    },
    pages: {
      name: "pages",
      label: "pages",
      type: "text",
    },
    category: {
      name: "cat",
      label: "category",
      type: "dropdown",
      options: [
        { label: "collections", value: "collections" },
        { label: "archaeological", value: "archaeological" },
        { label: "modern art", value: "modern_art" },
        { label: "educational", value: "educational" },
        { label: "other", value: "other" },
      ],
    },
    other: {
      name: "other",
      label: "other",
      type: "text",
    },
    shopUrl: {
      name: "shop_url",
      label: "shop Url",
      type: "text",
    },
  },
};

export const genericPageForm = {
  formId: "generic-page-form",
  formField: {
    pageType: {
      name: "pageType",
      value: "generic",
    },
    image: {
      name: "featured",
      label: "Image",
      type: "image",
    },
    fullImage: {
      name: "full_image",
      label: "use full-screen image",
      type: "checkbox",
    },
    title: {
      name: "title",
      label: "title",
      type: "text",
    },
    slug: {
      name: "slug",
      label: "slug",
      type: "slug",
      dependentValue: "title",
    },
    subtitle: {
      name: "subtitle",
      label: "subtitle",
      type: "text",
    },
    summary: {
      name: "summary",
      label: "summary",
      type: "text",
      multiline: true,
    },
    label: {
      name: "label_title",
      label: "label",
      type: "text",
    },
    date: {
      name: "dates",
      label: "dates",
      type: "date",
    },
    showHomepage: {
      name: "homeslider",
      label: "show in Homepage slider",
      type: "checkbox",
    },
    showSuggested: {
      name: "suggested",
      label: "show in Suggested",
      type: "checkbox",
    },
    showShop: {
      name: "shop_banner",
      label: "show in shop banners",
      type: "checkbox",
    },
    news: {
      name: "news",
      label: "show in homepage news",
      type: "checkbox",
    },
    newsFriends: {
      name: "news_friends",
      label: "show in friends' news",
      type: "checkbox",
    },
    newsFriendsPublishDate: {
      name: "news_friends_publish_date",
      label: "friends' news date",
      type: "date",
      dependant: "news_friends",
      paired: true,
      options: {
        mode: "single",
        enableTime: true,
        altFormat: "F j, Y - G:i K",
      },
    },
    newsPatron: {
      name: "news_patron",
      label: "show in patrons' news",
      type: "checkbox",
    },
    newsPatronPublishDate: {
      name: "news_patron_publish_date",
      label: "young patrons' news date",
      type: "date",
      dependant: "news_patron",
      paired: true,
      options: {
        mode: "single",
        enableTime: true,
        altFormat: "F j, Y - G:i K",
      },
    },

    newsYoungFriends: {
      name: "news_young_friends",
      label: "show in young friends' news",
      type: "checkbox",
    },
    newsYoungFriendsPublishDate: {
      name: "news_young_friends_publish_date",
      label: "young friends' news date",
      dependant: "news_young_friends",
      paired: true,
      type: "date",
      options: {
        mode: "single",
        enableTime: true,
        altFormat: "F j, Y - G:i K",
      },
    },
    blog: {
      name: "blog",
      label: "show in blog",
      type: "checkbox",
    },
    blogPublishDate: {
      name: "blog_publish_date",
      label: "blog date",
      type: "date",
      dependant: "blog",
      paired: true,
      options: {
        mode: "single",
        enableTime: true,
        altFormat: "F j, Y - G:i K",
      },
    },
  },
};

export const exhibitForm = {
  formId: "exhibit-form",
  formField: {
    pageType: {
      name: "pageType",
      value: "exhibit",
    },
    image: {
      name: "featured",
      label: "Image",
      type: "image",
    },
    category: {
      name: "exhibit_cat",
      label: "category",
      type: "select",
      options: [
        { label: "Cycladic Culture", value: "cycladic-culture" },
        { label: "Ancient Greek Art", value: "ancient-greek-art" },
        { label: "Cypriotic Culture", value: "cypriotic-culture" },
      ],
    },
    title: {
      name: "title",
      label: "title",
      type: "text",
    },
    slug: {
      name: "slug",
      label: "slug",
      type: "slug",
      dependentValue: "title",
    },
    collectionNumber: {
      name: "collection_number",
      label: "collection number",
      type: "text",
    },
    description: {
      name: "description",
      label: "description",
      type: "text",
      multiline: true,
    },
    creator: {
      name: "creator",
      label: "creator",
      type: "text",
    },
    dimensions: {
      name: "dimensions",
      label: "dimensions",
      type: "text",
    },
    kind: {
      name: "kind",
      label: "kind",
      type: "dropdown",
      options: [],
    },
    period: {
      name: "period",
      label: "period",
      type: "dropdown",
      options: [],
    },
    culture: {
      name: "culture",
      label: "culture",
      type: "dropdown",
      options: [],
    },
    material: {
      name: "material",
      label: "material",
      type: "dropdown",
      options: [],
    },
    dateText: {
      name: "date_text",
      label: "date Text",
      type: "text",
    },
    dateFrom: {
      name: "date_from",
      label: "date From",
      type: "number",
      paired: true,
    },
    dateFromEra: {
      name: "date_from_era",
      label: "date from era",
      type: "dropdown",
      paired: true,

      options: [
        { value: "bc", label: "b.c." },
        { value: "ad", label: "a.d." },
      ],
    },
    dateTo: {
      name: "date_to",
      label: "date to",
      type: "number",
      placeholder: "",
      errorMsg: "",
      paired: true,
    },
    dateToEra: {
      name: "date_to_era",
      label: "date to era",
      type: "dropdown",
      paired: true,
      options: [
        { value: "bc", label: "b.c." },
        { value: "ad", label: "a.d." },
      ],
    },
    provenance: {
      name: "provenance",
      label: "provenance",
      type: "text",
    },
    exhibitionGallery: {
      name: "exhibition_gallery",
      label: "exhibition Galerry",
      type: "text",
    },
    participations: {
      name: "participations",
      label: "participations",
      type: "participations",
    },
  },
};

export const eventForm = {
  formId: "event-form",
  formField: {
    pageType: {
      name: "pageType",
      value: "pevent",
    },
    image: {
      name: "featured",
      label: "Image",
      type: "image",
    },
    fullImage: {
      name: "full_image",
      label: "use fullscreen image",
      type: "checkbox",
    },
    title: {
      name: "title",
      label: "title",
      type: "text",
    },
    slug: {
      name: "slug",
      label: "slug",
      type: "slug",
      dependentValue: "title",
    },
    smallDescription: {
      name: "description",
      label: "small description",
      type: "textarea",
      multiline: true,
    },
    label: {
      name: "label",
      label: "label",
      type: "text",
    },
    dateText: {
      name: "date_text",
      label: "date text",
      type: "text",
    },
    date: {
      name: "date",
      label: "select date",
      type: "date",
    },
    events: {
      name: "events",
      label: "events",
      type: "searchDropdown",
      isMulti: true,
      getOptions: (params) => searchEvent(params),
    },
    eventType: {
      name: "event_type",
      label: "event type",
      type: "select",
      options: [
        { value: "events", label: "events" },
        { value: "lectures", label: "lectures" },
        { value: "workshops", label: "workshops" },
        { value: "colloquia", label: "colloquia" },
      ],
    },
    showHomepage: {
      name: "homeslider",
      label: "show in Homepage slider",
      type: "checkbox",
    },
    showSuggested: {
      name: "suggested",
      label: "show in Suggested",
      type: "checkbox",
    },
  },
};
