/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useCallback, memo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useRowSelect,
  useFilters,
  useRowState,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiPagination from "components/SuiPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Skeleton } from "@mui/material";
import { isEqual } from "loadsh";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IndeterminateCheckbox } from "../../../components/TableComponents/IdCell";
import DefaultCell from "../../../components/TableComponents/DefaultCell";

function DataTable({
  customFilters,
  entriesPerPage,
  canSearch,
  showTotalEntries,
  noShorting,
  table,
  pagination,
  isSorted,
  noEndBorder,
  noPagination,
  fetch,
  totalPages,
  totalEntries,
  loading,
  setSelected,
  handleUpdateRow,
  handleSaveRow,
  handleReorderRow,
  couponFilters,
}) {
  const { defaultValue, entries } = entriesPerPage;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        globalFilter: { search: undefined, customFilters: undefined },
        pageCount: totalPages,
        customFiltersOptions: customFilters,
      },
      manualPagination: true,
      pageCount: totalPages,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      defaultCanShort: true,
      autoResetSortBy: false,
      autoResetFilters: false,
      manualFilters: true,
      manualGlobalFilter: true,
      autoResetRowState: false,
      handleUpdateRow,
      handleSaveRow,
    },
    useRowState,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      setSelected
        ? hooks.visibleColumns.push((prevColumns) => [
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              // Header: {},
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              width: "3%",
              canSort: false,
              Cell: ({ row }) =>
                !row.original.disabled ? (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ) : (
                  <DefaultCell value="" />
                ),
            },
            ...prevColumns,
          ])
        : undefined;
    }
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    selectedFlatRows,
    setRowState,
    setFilters,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  } = tableInstance;
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  useEffect(() => {
    if (setSelected) {
      setSelected(selectedFlatRows);
    }
  }, [setSelected, selectedFlatRows]);

  useEffect(() => {
    fetch({
      page: pageIndex + 1,
      totalPerPage: pageSize,
      search: globalFilter?.search,
      [customFilters.key]: globalFilter?.customFilters,
      sort: sortBy?.[0]?.id ?? undefined,
      order: sortBy?.[0]?.desc === false ? "asc" : "desc",
      ...(globalFilter.couponFilter && { coupon_id: globalFilter.couponFilter }),
    });
  }, [pageIndex, pageSize, globalFilter, sortBy, customFilters]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);
  // Render the paginations
  const renderPagination = useMemo(
    () =>
      pageOptions.map((option) => (
        <SuiPagination
          item
          key={option}
          onClick={() => gotoPage(Number(option))}
          active={pageIndex === option}
        >
          {option + 1}
        </SuiPagination>
      )),
    [pageOptions, pageIndex, gotoPage]
  );

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = useMemo(
    () => pageOptions.map((option) => option + 1),
    [pageOptions]
  );

  // Setting value for the pagination input
  const handleInputPaginationValue = (e) => {
    gotoPage(Number(e.target.value - 1));
  };

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter({ ...globalFilter, search: value || undefined });
    gotoPage(0);
  }, 600);

  // A function that sets the sorted value for the table
  const setSortedValue = useCallback(
    (column) => {
      let sortedValue;
      if (!column.canSort) {
        return false;
      }
      if (isSorted && column.isSorted) {
        sortedValue = column.isSortedDesc ? "desc" : "asc";
      } else if (isSorted) {
        sortedValue = "none";
      } else {
        sortedValue = false;
      }
      return sortedValue;
    },
    [isSorted]
  );

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  const entriesEnd = useMemo(() => {
    if (pageIndex === 0) {
      return pageSize;
    }
    if (pageIndex === pageOptions.length - 1) {
      return rows.length;
    }
    return pageSize * (pageIndex + 1);
  }, [pageOptions, pageSize, pageIndex]);

  const handleDragEnd = async (result) => {
    const { draggableId, destination, source } = result;
    if (!destination || destination?.index === source?.index) return;
    handleReorderRow(draggableId, destination.index + 1);
  };

  return (
    <TableContainer className="shadow-none">
      {entriesPerPage || canSearch || customFilters.length > 0 ? (
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && !noPagination && (
            <SuiBox display="flex" alignItems="center">
              <SuiSelect
                defaultValue={{ value: defaultValue, label: defaultValue }}
                options={entries.map((entry) => ({ value: entry, label: entry }))}
                onChange={setEntriesPerPage}
                size="small"
              />
              <SuiTypography variant="caption" textColor="secondary">
                &nbsp;&nbsp;entries per page
              </SuiTypography>
            </SuiBox>
          )}
          <SuiBox display="flex" alignItems="center">
            {couponFilters?.length > 0 && (
              <SuiBox mr={2} width="15rem" pr={2}>
                <SuiSelect
                  defaultValue={{ value: null, label: "Select Coupon" }}
                  options={couponFilters?.map((el) => ({ value: el.id, label: el.code }))}
                  onChange={(e) => setGlobalFilter({ ...globalFilter, couponFilter: e.value })}
                  size="medium"
                />
              </SuiBox>
            )}
            {customFilters?.options?.length > 0 && (
              <SuiBox mr={2} width="15rem" pr={2}>
                <SuiSelect
                  defaultValue={{ value: "None", label: customFilters?.label ?? "Select" }}
                  options={customFilters?.options?.map((entry) =>
                    typeof entry === "string" ? { value: entry, label: entry } : entry
                  )}
                  onChange={(e) => setGlobalFilter({ ...globalFilter, customFilters: e.value })}
                  size="medium"
                />
              </SuiBox>
            )}
            {canSearch && (
              <SuiBox width="12rem" ml="auto">
                <SuiInput
                  placeholder="Search..."
                  onChange={(e) => {
                    onSearchChange(e.target.value);
                  }}
                />
              </SuiBox>
            )}
          </SuiBox>
        </SuiBox>
      ) : null}
      <Table {...getTableProps()}>
        <SuiBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i, arr) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={noShorting ? false : setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </SuiBox>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table-body">
            {(providedP, snapshotP) => (
              <TableBody
                {...getTableBodyProps()}
                ref={providedP.innerRef}
                {...providedP.droppableProps}
              >
                {page.map((row, key) => {
                  prepareRow(row);
                  const draggableId =
                    loading || !data?.[0]?.id?.toString()
                      ? key.toString()
                      : row.original?.id?.toString();
                  return (
                    <Draggable
                      isDragDisabled={!!globalFilter.search || !handleReorderRow}
                      draggableId={draggableId}
                      key={row.original?.id || key}
                      index={row.index}
                    >
                      {(provided, snapshot) => (
                        <TableRow
                          {...row.getRowProps()}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          {row.cells.map((cell, i, arr) => (
                            <DataTableBodyCell
                              maxWidth={cell?.column?.width ?? undefined}
                              noBorder={noEndBorder && rows.length - 1 === key}
                              align={cell.column.align ? cell.column.align : "left"}
                              isDragging={snapshot.isDragging}
                              {...cell.getCellProps()}
                            >
                              {loading ? (
                                <Skeleton width="100%" />
                              ) : (
                                cell.render("Cell", {
                                  dragHandleProps: provided.dragHandleProps,
                                  isSomethingDragging: snapshot.isDraggingOver,
                                })
                              )}
                            </DataTableBodyCell>
                          ))}
                        </TableRow>
                      )}
                    </Draggable>
                  );
                })}
                {providedP.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>

      <SuiBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <SuiBox mb={{ xs: 3, sm: 0 }}>
            <SuiTypography variant="button" textColor="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {totalEntries} entries
            </SuiTypography>
          </SuiBox>
        )}
        {!noPagination && pageOptions.length > 1 && (
          <SuiPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <SuiPagination item onClick={() => previousPage()}>
                <Icon className=" bold">chevron_left</Icon>
              </SuiPagination>
            )}
            {renderPagination.length > 6 ? (
              <SuiBox width="5rem" mx={1}>
                <SuiInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </SuiBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <SuiPagination item onClick={() => nextPage()}>
                <Icon className=" font-bold">chevron_right</Icon>
              </SuiPagination>
            )}
          </SuiPagination>
        )}
      </SuiBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  setCustomFilters: () => {},
  customFilters: {},
  editable: false,
  noPagination: false,
  noShorting: false,
  setSelected: undefined,
  handleUpdateRow: undefined,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  setCustomFilters: PropTypes.func,
  customFilters: PropTypes.shape({
    key: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  editable: PropTypes.bool,
  noPagination: PropTypes.bool,
  noShorting: PropTypes.bool,
  fetch: PropTypes.func.isRequired,
  totalPages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  totalEntries: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  setSelected: PropTypes.func,
  handleUpdateRow: PropTypes.func,
};

DataTable.whyDidYouRender = true;
// customFilters,
//   entriesPerPage,
//   canSearch,
//   showTotalEntries,
//   noShorting,
//   table,
//   pagination,
//   isSorted,
//   noEndBorder,
//   noPagination,
//   fetch,
//   totalPages,
//   totalEntries,
//   loading,
//   setSelected,
//   handleUpdateRow,
//   handleSaveRow,
const areEqual = (p, v) =>
  isEqual(p.table, v.table) &&
  isEqual(p.customFilters, v.customFilters) &&
  isEqual(p.couponFilters, v.couponFilters) &&
  isEqual(p.entriesPerPage, v.entriesPerPage) &&
  isEqual(p.pagination, v.pagination) &&
  isEqual(p.loading, v.loading) &&
  isEqual(p.totalPages, v.totalPages) &&
  isEqual(p.totalEntries, v.totalEntries);

export default memo(DataTable, areEqual);
