/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import React from "react";
import SuiSelect from "../SuiSelect";

function FormField({ label, children, ...rest }) {
  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      {children || <SuiInput {...rest} />}
    </SuiBox>
  );
}

export const DropdownField = ({ options, setOption, label, ...rest }) => (
  <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
      <SuiTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}
      </SuiTypography>
    </SuiBox>
    <SuiSelect
      defaultValue={{ label: "all", value: "" }}
      options={options}
      onChange={setOption}
      size="small"
      {...rest}
    />
  </SuiBox>
);

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: " ",
  children: null,
};

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
  children: PropTypes.element,
};

DropdownField.defaultProps = {
  label: " ",
};

DropdownField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOption: PropTypes.func.isRequired,
};

export default FormField;
