/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import SuiBox from "../../../SuiBox";
import SuiTypography from "../../../SuiTypography";
import ModalGallery from "./ModalGallery";
import SuiButton from "../../../SuiButton";

// eslint-disable-next-line react/prop-types
const Gallery = ({ item, value = [] }) => {
  const [modal, setModal] = useState(false);

  return (
    <SuiBox mb={1.5}>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
          sx={{
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {/* eslint-disable-next-line react/prop-types */}
          {item.label} {item.galleryIndex + 1}
        </SuiTypography>
      </SuiBox>
      <Divider sx={{ width: "100%", height: "1px", color: "lightgray", opacity: 1 }} />
      <Grid container spacing={1}>
        {value?.images?.map((el) => (
          <Grid item xs={6} sm={2}>
            <img src={el.image} width="100%" height="auto" alt="pageImage" />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ width: "100%", color: "lightgray", opacity: 1 }} />
      <SuiButton buttonColor="dark" onClick={() => setModal(true)}>
        Edit Gallery
      </SuiButton>
      <ModalGallery
        open={modal}
        setOpen={setModal}
        values={value}
        galleryIndex={item.galleryIndex}
      />
    </SuiBox>
  );
};
export default Gallery;
