/* eslint-disable react/prop-types */
import React from "react";
import TextWidget from "../../../../../../components/widgets/TextWidget";
import PublicationWidget from "../../../../../../components/widgets/PublicationsWidget";
import GalleryWidget from "../../../../../../components/widgets/GalleryWidget";
import VideoWidget from "../../../../../../components/widgets/VideoWidget";
import SponsorsWidget from "../../../../../../components/widgets/SponsorsWidget";
import TicketWidget from "../../../../../../components/widgets/TicketWidget";
import SubscriptionsWidget from "../../../../../../components/widgets/SubscriptionsWidget";
import PageWidget from "../../../../../../components/widgets/PageWidget";
import ListWidget from "../../../../../../components/widgets/ListWidget";
import BioWidget from "../../../../../../components/widgets/BioWidget";
import DonationWidget from "../../../../../../components/widgets/DonationWidget";

export const renderWidgets = (props) => {
  switch (props.widget.type) {
    case "text":
      return <TextWidget {...props} />;
    case "publication":
      return <PublicationWidget {...props} />;
    case "gallery":
      return <GalleryWidget {...props} />;
    case "video":
      return <VideoWidget {...props} />;
    case "sponsors":
    case "sponsor":
      return <SponsorsWidget {...props} />;
    case "ticket":
      return <TicketWidget {...props} />;
    case "subscriptions":
      return <SubscriptionsWidget {...props} />;
    case "page":
      return <PageWidget {...props} />;
    case "list":
      return <ListWidget {...props} />;
    case "bio":
      return <BioWidget {...props} />;
    case "donation":
      return <DonationWidget {...props} />;
    default:
      return <></>;
  }
};
