import React, { useEffect, useState } from "react";
import { Card, Grid, Modal } from "@mui/material";
import { Formik, Form, FieldArray } from "formik";
import SuiBox from "../../../../../components/SuiBox";
import FormField from "../../../../../components/FormField";
import SuiButton from "../../../../../components/SuiButton";
import SuiTypography from "../../../../../components/SuiTypography";
import initialValues from "./form/formField";
import validations from "./form/validations";

const AddShippingModal = ({ open = false, setOpen, handleSubmit }) => {
  const handleSubmitForm = async (values, actions) => {
    actions.setSubmitting(true);
    await handleSubmit(values);
    actions.setSubmitting(false);
    actions.resetForm();
    setOpen(false);
  };

  const handleClose = (resetForm) => {
    resetForm();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onBackdropClick={() => setOpen(false)}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <Grid item xs={10} md={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            resetForm,
            setFieldError,
            setErrors,
          }) => (
            <Form id="newShippingItem" autoComplete="off">
              <Card className="h-100">
                <SuiBox p={2}>
                  <SuiTypography variant="h5" textTransform="capitalize">
                    add charge
                  </SuiTypography>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      type="number"
                      label="weight threshold"
                      name="weight_threshold"
                      value={values.weight_threshold}
                      onChange={(e) => {
                        setFieldError("weight_threshold", undefined);
                        setFieldValue("weight_threshold", e);
                      }}
                    />
                  </Grid>
                  <FieldArray
                    name="shipping_costs"
                    render={(arrayHelpers) => (
                      <Grid container spacing={2}>
                        {values?.shipping_costs?.map((el, i) => (
                          <Grid item xs={12} sm={3}>
                            <FormField
                              label={el.label}
                              type="number"
                              name="cost"
                              value={el.cost}
                              error={errors?.shipping_costs?.[i]?.cost}
                              onChange={(e) => {
                                setErrors({});
                                setFieldValue(`shipping_costs[${i}].cost`, e);
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  />
                </SuiBox>
                <SuiBox p={2} display="flex" justifyContent="flex-end">
                  <SuiBox>
                    <SuiButton onClick={() => handleClose(resetForm)} buttonColor="secondary">
                      Cancel
                    </SuiButton>
                  </SuiBox>
                  <SuiBox ml={2}>
                    <SuiButton type="submit" disabled={isSubmitting} buttonColor="dark">
                      {!isSubmitting ? "Create" : " Creating..."}
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </Card>
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default AddShippingModal;
