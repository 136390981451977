/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import dataTableData from "./data/dataTableData";
import { getMembers } from "../../../../redux/Middleware/MembersThunk";

const filters = {
  key: "status",
  options: [
    { label: "all", value: null },
    { label: "active", value: "active" },
    { label: "expired", value: "expired" },
  ],
};

const MembersListPage = () => {
  const dispatch = useDispatch();
  const membersData = useSelector((state) => state.membersReducer.members);
  const membersTotal = useSelector((state) => state.membersReducer.membersTotal);
  const totalPages = useSelector((state) => state.membersReducer.membersPagesTotal);
  const status = useSelector((state) => state.membersReducer.status);
  const [data, setData] = useState(dataTableData);

  useEffect(() => {
    if (membersData) {
      setData((prev) => ({ ...prev, rows: membersData }));
    }
  }, [membersData]);

  const handleUpdate = useCallback((params) => {
    dispatch(getMembers(params));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle="Members" breadcrumbRoutes={["members-list"]} />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" alignItems="center" justifyContent="flex-start">
            <SuiBox>
              <SuiBox p={3} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Members List
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  {membersTotal ?? 0} members
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </SuiBox>

          <DataTable
            loading={status === "loading"}
            table={data}
            canSearch
            totalPages={totalPages}
            totalEntries={membersTotal}
            fetch={handleUpdate}
            customFilters={filters}
          />
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
};

export default MembersListPage;
