import axiosInstanceProxy from "./config";

export const getPagesRequest = ({ sort, order, totalPerPage, type, search, page }) =>
  axiosInstanceProxy.get("/admin/cms/pages", {
    params: {
      type,
      sort,
      order,
      totalPerPage,
      search,
      page,
    },
  });

export const getPageRequest = ({ id }) => axiosInstanceProxy.get(`/admin/cms/pages/${id}/show`);

export const createPageRequest = (data) => axiosInstanceProxy.post(`/admin/cms/pages/create`, data);

export const updatePageRequest = ({ id, data }) =>
  axiosInstanceProxy.post(`/admin/cms/pages/${id}/update`, { ...data });

export const deletePageRequest = ({ id }) =>
  axiosInstanceProxy.post(`/admin/cms/pages/${id}/destroy`);

export const statusPageRequest = ({ id, status }) =>
  axiosInstanceProxy.post(`/admin/cms/pages/${id}/status`, { active: status });

export const slugifyPageRequest = ({ id }) =>
  axiosInstanceProxy.post(`/admin/cms/pages/${id}/slugify`);

export const getBannersRequest = ({ type = "shop", totalPerPage = 50, search }) =>
  axiosInstanceProxy.get(`/admin/cms/banners`, { params: { type, totalPerPage, search } });

export const getCarouselRequest = ({ type = "home", totalPerPage = 50, search }) =>
  axiosInstanceProxy.get(`/admin/cms/banners`, { params: { type, totalPerPage, search } });

export const orderBannersRequest = ({ id, index }) =>
  axiosInstanceProxy.post(`/admin/cms/banners/${id}/order`, { type: "shop", pos: index });

export const orderCarouselRequest = ({ id, index }) =>
  axiosInstanceProxy.post(`/admin/cms/banners/${id}/order`, { type: "home", pos: index });

export const createTicketRequest = (data) => axiosInstanceProxy.post(`/admin/tickets/create`, data);

export const updateTicketRequest = (data) => axiosInstanceProxy.post(`/admin/tickets/update`, data);

export const slugifyRequest = (data) =>
  axiosInstanceProxy.post(`/admin/cms/pages/slugify`, { title: data });
