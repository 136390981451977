export default {
  formId: "subscriptions-widget",
  formField: {
    subscription: {
      name: "subscription",
      label: "subscription",
      type: "dropdown",
      options: [],
    },
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    description: {
      name: "description",
      label: "description",
      type: "html",
    },
  },
};
