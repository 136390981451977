import checkout from "./form";

const {
  formField: { column, collapsable, title, menuTitle, bottomLinkText, bottomLinkUrl },
} = checkout;

export default {
  [column.name]: false,
  [collapsable.name]: false,
  [title.name]: "",
  [`${title.name}_en`]: "",
  [menuTitle.name]: "",
  [`${menuTitle.name}_en`]: "",
  text0: "",
  text1: "",
  galleries: [
    {
      label: "",
      description: "",
      images: [],
    },
    {
      label: "",
      description: "",
      images: [],
    },
  ],
  [bottomLinkText.name]: "",
  [`${bottomLinkText.name}_en`]: "",
  [bottomLinkUrl.name]: "",
  [`${bottomLinkUrl.name}_en`]: "",
};
