import React from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";

// eslint-disable-next-line react/prop-types
const LanguageToggle = ({ language = "el", setLanguage }) => {
  const handleChange = (e, v) => setLanguage(v);

  return (
    <Grid item xs={12} mb={2}>
      <Box maxWidth="400px">
        <Tabs textColor="primary" value={language} onChange={handleChange} sx={{ height: "40px" }}>
          <Tab label="Greek" value="el" />
          <Tab label="English" value="en" />
        </Tabs>
      </Box>
      {/* <ToggleButtonGroup */}
      {/*  size="small" */}
      {/*  color="info" */}
      {/*  value={language} */}
      {/*  exclusive */}
      {/*  onChange={handleChange} */}
      {/* > */}
      {/*  <ToggleButton value="el">Greek</ToggleButton> */}
      {/*  <ToggleButton value="en">English</ToggleButton> */}
      {/* </ToggleButtonGroup> */}
    </Grid>
  );
};

export default LanguageToggle;
