export default {
  formId: "page-widget",
  formField: {
    columns: {
      name: "columns",
      label: "",
      type: "select",
      options: [
        { label: "3 columns", value: "3" },
        { label: "4 columns", value: "4" },
      ],
    },

    title: {
      name: "title",
      label: "Title",
      type: "text",
    },
    menuTitle: {
      name: "menu_title",
      label: "Menu Title",
      type: "text",
    },
    pages: {
      name: "pages",
      type: "widgetSpecial",
      widget: "page",
    },
    bottomLinkText: {
      name: "bottom_link_text",
      label: "bottom_link_text",
      type: "text",
    },
    bottomLinkUrl: {
      name: "bottom_link_url",
      label: "bottom_link_url",
      type: "text",
    },
  },
};
