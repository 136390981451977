/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { DateCell, DateTimeCell } from "../../../../../components/TableComponents/DefaultCell";
import StatusCell from "../../../../../components/TableComponents/StatusCell";
import OrderStatusCell from "../../../../../components/TableComponents/OrderStatusCell";
import SubscriptionInfoCell from "../../../../../components/TableComponents/SubscriptionInfoCell";
import {
  MemberCodeCell,
  MemberInfoCompactCell,
  SubscriptionCompactCell,
} from "../../../../../components/TableComponents/Shared";

export default {
  columns: [
    { Header: "order No", accessor: "order_id", width: 70 },
    {
      Header: "Created",
      accessor: "created",
      width: 80,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <DateTimeCell value={value} />,
    },
    {
      Header: "Member Code",
      accessor: "code",
      width: 80,
      Cell: ({ value }) => <MemberCodeCell value={value} />,
    },
    {
      Header: "Member",
      width: 300,
      Cell: ({ row }) => (
        <MemberInfoCompactCell name={row.original.name} email={row.original.email} />
      ),
    },
    {
      Header: "type",
      accessor: "sub",
      Cell: ({ row }) => (
        <SubscriptionCompactCell name={row.original.sub} code={row.original.sub_code} />
      ),
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ row }) => <OrderStatusCell item={row.original} />,
    },
    { Header: "total", accessor: "total" },
    {
      Header: "Info",
      disableSortBy: true,
      width: 80,
      Cell: ({ row }) => <SubscriptionInfoCell gift={row.original.gift} dual={row.original.dual} />,
    },
  ],
  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
};
