import checkout from "./form";

const {
  formField: { avatar, name, username, email, confirmEmail, password, role, description },
} = checkout;

export default {
  [avatar.name]: "",
  [name.name]: "",
  [username.name]: "",
  [email.name]: "",
  [confirmEmail.name]: "",
  [password.name]: "",
  [role.name]: "",
  [description.name]: "",
};
