/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, debounce, Grid, Modal } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SuiBox from "../../../SuiBox";
import FormField, { DropdownField } from "../../../SimpleFormField";
import { clearPublications, getPublications } from "../../../../redux/reducers/cmsSlice";
import SuiTypography from "../../../SuiTypography";
import SuiButton from "../../../SuiButton";

const options = [
  { label: "all", value: "" },
  { label: "collections", value: "collections" },
  { label: "archaeological", value: "archaeological" },
  { label: "modern art", value: "modern_art" },
  { label: "educational", value: "educational" },
  { label: "other", value: "other" },
];

const AddPublicationModal = ({ open, setOpen, initialPublications = [], setPublications }) => {
  const initialLoad = useRef(true);
  const dispatch = useDispatch();
  const publications = useSelector((state) => state.cmsReducer.publications);
  const [option, setOption] = useState("");
  const [search, setSearch] = useState("");
  const [available, setAvailable] = useState([]);
  const [selected, setSelected] = useState(
    initialPublications?.map((el) => ({ ...el, ...el.page, page: null }))
  );

  const getSearch = useCallback(
    debounce((args) => dispatch(getPublications({ search: args })), 1000),
    []
  );

  useEffect(() => {
    if (initialLoad.current === true) {
      initialLoad.current = false;
      return;
    }
    if (search) {
      getSearch(search);
    }
  }, [search]);

  useEffect(() => {
    if (open) {
      setSelected(initialPublications?.map((el) => ({ ...el, ...el.page, page: null })));
    }
  }, [open, initialPublications]);

  useEffect(() => {
    setAvailable(publications.filter((el) => !selected.some((el2) => el2.id === el.id)));
  }, [publications, selected]);

  const clearModalForm = () => {
    setSearch("");
    dispatch(clearPublications());
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPublications(selected);
    clearModalForm();
  };

  const handleClose = () => {
    clearModalForm();
  };

  return (
    <Modal
      open={open}
      onBackdropClick={() => setOpen(false)}
      onClose={() => setOpen(false)}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ width: "60%", height: "500px", overflowY: "auto" }}>
        <SuiBox
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8}>
              <FormField
                label="Search for publication"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={8} sm={4}>
              <DropdownField
                defaultValue={{ label: "all", value: "" }}
                options={options}
                setOption={setOption}
                size="small"
              />
            </Grid>
          </Grid>
          <SuiBox>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <SuiBox display="flex" flexDirection="column" justifyContent="flex-end">
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block" overflow="auto">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Choose publications
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox
                    px={1}
                    height="250px"
                    overflow="auto"
                    border="1px solid"
                    borderColor="lightgray"
                    borderRadius="8px"
                  >
                    {available?.map((el) => (
                      <SuiBox
                        key={el.id}
                        sx={{
                          cursor: "pointer",
                          borderBottom: "1px solid #00000030",
                          "&:hover": { backgroundColor: "#00000010" },
                        }}
                        paddingX={1}
                        onClick={() => setSelected((prev) => [...prev, el])}
                      >
                        <SuiTypography
                          style={{ cursor: "pointer" }}
                          component="label"
                          variant="caption"
                          textTransform="capitalize"
                        >
                          {el.title}
                        </SuiTypography>
                      </SuiBox>
                    ))}
                  </SuiBox>
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Chosen publications
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox
                    px={1}
                    height="250px"
                    overflow="auto"
                    border="1px solid"
                    borderColor="lightgray"
                    borderRadius="8px"
                  >
                    {selected.map((el) => (
                      <SuiBox
                        key={el.id}
                        sx={{
                          cursor: "pointer",
                          borderBottom: "1px solid #00000030",
                          "&:hover": { backgroundColor: "#00000010" },
                        }}
                        paddingX={1}
                        pl={1}
                        onClick={() => setSelected((prev) => prev.filter((e) => e.id !== el.id))}
                      >
                        <SuiTypography
                          sx={{ cursor: "pointer" }}
                          component="label"
                          variant="caption"
                          textTransform="capitalize"
                        >
                          {el.title}
                        </SuiTypography>
                      </SuiBox>
                    ))}
                  </SuiBox>
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
          <SuiBox display="flex" justifyContent="space-between">
            <SuiButton buttonColor="light" onClick={handleClose}>
              Cancel
            </SuiButton>
            <SuiButton buttonColor="dark" onClick={handleSubmit}>
              Save
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </Card>
    </Modal>
  );
};

AddPublicationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AddPublicationModal;
