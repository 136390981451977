// export function resolve(path, obj) {
//   return path.split(".").reduce((prev, curr) => (prev ? prev[curr] : null), obj || self);
// }
//
// export function resolve2(path, obj) {
//   for (var i = 0, path = path.split("."), len = path.length; i < len; i++) {
//     obj = obj[path[i]];
//   }
//   return obj;
// }

import { omit } from "loadsh/object";

export function getFieldFromPath(path, object) {
  try {
    const regex = /(?<=\[)(?!")[^\]]+|(?<=\[")[^"]+|[^."[\]]+/g;
    return path.match(regex).reduce((res, prop) => res[prop], object);
  } catch {
    return undefined;
  }
}

export const groupByKey = (list, key) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export function groupItemBy(array, property) {
  const hash = {};
  const props = property.split(".");
  for (let i = 0; i < array.length; i++) {
    const key = props.reduce((acc, prop) => acc && acc[prop], array[i]);
    if (!hash[key]) hash[key] = [];
    hash[key].push(array[i]);
  }
  const res = {};
  Object.entries(hash).forEach(([key, value]) => {
    res[key] = groupByKey(value, "weight_threshold");
  });
  return res;
}

export const handleReadShippingData = (d) => {
  const initialData = [...d];
  const res = [];
  initialData.forEach((e, i) => {
    if (i === 0 || !res.some((el) => el.weight_threshold === e.weight_threshold)) {
      res.push({ weight_threshold: e.weight_threshold });
    }
    const index = res.findIndex((el) => el.weight_threshold === e.weight_threshold);
    res[index][`zone${e.zone.data.code}`] = e.cost;
    res[index].zoneId = e.zone.data.id;
  });
  return res;
};

export const loadingMiddleware = (store) => (next) => (action) => {
  next(action);

  console.log(action);
};

export const transformCreatePagePayload = (data) => {
  switch (data.kind) {
    case "exhibit":
      return {
        type: data.kind,
        title: data.title,
        title_en: data.title_en,
        slug: data.slug,
        featured: data.featured,
        featured_en: data.featured_en,
        suggested: data.suggested,
        homeslider: data.homeslider,
        shop_banner: data.shop_banner,
        widgets: [],
        meta: {},
      };
    case "exhibition":
      return {
        type: data.kind,
        title: data.title,
        title_en: data.title,
        slug: data.slug,
        featured: data.featured,
        featured_en: data.featured_en,
        suggested: data.suggested,
        homeslider: data.homeslider,
        shop_banner: data.shop_banner,
        widgets: [],
        meta: {},
      };
    case "publication":
      return {
        type: data.kind,
        title: data.title,
        title_en: data.title_en,
        slug: data.slug,
        featured: data.featured,
        featured_en: data.featured_en,
        suggested: data.suggested,
        homeslider: data.homeslider,
        shop_banner: data.shop_banner,
        widgets: [],
        meta: {},
      };
    case "generic":
      return {
        type: data.kind,
        title: data.title,
        title_en: data.title_en,
        slug: data.slug,
        featured: data.featured,
        featured_en: data.featured_en,
        suggested: data.suggested,
        homeslider: data.homeslider,
        shop_banner: data.shop_banner,
        widgets: [],
        meta: {},
      };
    default:
      return {};
  }
};

export function toTitleCase(string) {
  if (string) {
    return string.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }
  return string;
}

Object.unflatten = function (data) {
  if (Object(data) !== data || Array.isArray(data)) return data;
  const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g;
  const resultholder = {};
  for (const p in data) {
    let cur = resultholder;
    let prop = "";
    var m;
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[""] || resultholder;
};

Object.flatten = function (data) {
  const result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], `${prop}[${i}]`);
      if (l == 0) result[prop] = [];
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}.${p}` : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};

export const clearObjectKeys = (data, whitelist = ["widgets"]) => {
  const o = Object.flatten(data);
  const r = Object.fromEntries(
    Object.entries(o).map(([k, v]) => {
      if (whitelist.some((e) => k.includes(e))) {
        return [k, v];
      }
      return [k.replaceAll(".data", ""), v];
    })
  );
  const temp = Object.unflatten(r);
  return temp;
};

export const addPageMeta = (data) => {
  if (data.meta) {
    return data;
  }
  const initial = {
    title: data.title,
    title_en: data.title_en,
    slug: data.slug,
    featured: data.featured,
    featured_en: data.featured_en,
    suggested: data.suggested,
    homeslider: data.homeslider,
    shop_banner: data.shop_banner,
    soevent_id: data.soEventId,
    events: data.events?.map((e) => e.id),
    widgets: data.widgets ?? [],
    showPage: data.showPage ?? undefined,
  };
  if (data.pageId) {
    Object.assign(initial, {
      pageId: data.pageId,
      static: data.static,
      active: data.active,
      created: data.created,
      updated: data.updated,
    });
  }
  const meta = omit(data, Object.keys({ ...initial, pageType: 0 }));
  const payload = {
    ...initial,
    meta: { ...meta, suggested: data.suggested },
    type: data.pageType,
    id: data.pageId,
  };
  delete payload.pageId;
  return payload;
};
