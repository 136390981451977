/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Settings from "../../new-page/components/Settings";
import SuiBox from "../../../../../../components/SuiBox";
import SuiTypography from "../../../../../../components/SuiTypography";
import pxToRem from "../../../../../../assets/theme/functions/pxToRem";
import SuiButton from "../../../../../../components/SuiButton";

const pageInfo = (item) => {
  switch (item.type) {
    case "exhibition":
      return [
        { label: "page type: ", value: item.type },
        { label: " Small description: ", value: item.meta.description },
        { label: "Label: ", value: item.meta?.label },
        { label: "Date: ", value: item.meta?.date_text },
        { label: "Exhibition type: ", value: item.meta?.type },
      ];
    case "pevent":
      return [
        { label: "page type: ", value: item.type },
        { label: " Small description: ", value: item.meta.description },
        { label: "Label: ", value: item.meta?.label },
      ];
    case "publication":
      return [
        { label: "page type: ", value: item.type },
        { label: "Label: ", value: item.meta?.label },
        { label: "writers: ", value: item.meta?.writers },
        { label: "description: ", value: item.meta.description },
        { label: "dimensions: ", value: item.meta.dimensions },
        { label: "pages: ", value: item.meta.pages },
        { label: "other: ", value: item.meta.other },
        { label: "category: ", value: item.meta.category },
      ];
    case "generic":
      return [
        { label: "page type: ", value: item.type },
        { label: "Label: ", value: item.meta?.label },
        { label: "subtitle: ", value: item.meta?.subtitle },
        { label: "summary: ", value: item.meta.summary },
      ];
    case "exhibit":
      return [
        { label: "page type: ", value: item.type },
        { label: "collection number: ", value: item.meta?.collection_number },
        { label: " Small description: ", value: item.meta.description },
        { label: "culture: ", value: item.meta?.culture },
        { label: "kind: ", value: item.meta?.kind },
        { label: "period: ", value: item.meta?.period },
        { label: "material: ", value: item.meta?.material },
        { label: "dimensions: ", value: item.meta?.dimensions },
        { label: "creator: ", value: item.meta?.label },
        {
          label: "from: ",
          value: `${item.meta?.date_from} ${item.meta?.date_from_era}`,
        },
        { label: "to: ", value: `${item.meta?.date_to} ${item.meta?.date_to_era}` },
        { label: "date label: ", value: item.meta?.date_text },
        // {
        //   label: "participations: ",
        //   value: item.meta?.participations?.map((el) => el.title) ? "",
        // },
      ];
    default:
      return [];
  }
};

const EditPageComponent = ({ item, children }) => {
  // const [item, setItem] = useState(clearObjectKeys(data));
  const [editMode, setEditMode] = useState(false);
  // const init = useRef(true);

  // useEffect(() => {
  //   setItem(clearObjectKeys(data));
  // }, []);

  const handleEdit = () => {
    setEditMode(true);
  };

  if (editMode) {
    const temp = { ...item, pageType: item.type, pageId: item.id, ...item.meta };
    if (temp.pageType === "pevent") {
      temp.event_type = item.meta.type;
    }
    if (temp.pageType === "exhibition" || temp.pageType === "generic") {
      temp.dates = temp.dates?.map((el) => el.date);
    }
    delete temp.meta;
    return (
      <SuiBox pr={5}>
        <Settings pageType={item.type} edit={temp} setStepIndex={setEditMode} />
      </SuiBox>
    );
  }

  return (
    <>
      <SuiBox display="flex" flexDirection="row" alignItems="center">
        <SuiBox display="flex" flexDirection="column" maxWidth="70%">
          <SuiTypography component="label" fontWeight="bold" textTransform="capitalize">
            {item?.title}
          </SuiTypography>
          <SuiBox mb={4}>
            <SuiButton buttonColor="info" onClick={handleEdit}>
              edit
            </SuiButton>
          </SuiBox>
          <SuiTypography component="label" variant="h6">
            URL:{" "}
            <Link
              to={{ pathname: `${process.env.REACT_APP_EXTERNAL_URL}/page/${item?.slug}` }}
              target="_blank"
            >
              {`${process.env.REACT_APP_EXTERNAL_URL}/page/${item?.slug}`}
            </Link>
          </SuiTypography>
          {pageInfo(item)?.map((el) => (
            <React.Fragment key={el.label}>
              <SuiTypography
                component="label"
                variant="h6"
                textTransform="capitalize"
                fontWeight="regular"
              >
                <span style={{ fontWeight: "500" }}>{el.label}</span> {el.value}
              </SuiTypography>
            </React.Fragment>
          ))}
        </SuiBox>
        <SuiBox
          ml={pxToRem(100)}
          width={pxToRem(200)}
          height={pxToRem(200)}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {item?.featured && item?.featured !== "" && (
            <img src={item?.featured} width="100%" height="auto%" alt="pageImage" />
          )}
        </SuiBox>
      </SuiBox>
      {children}
    </>
  );
};

export default EditPageComponent;
