/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { value } from "loadsh/seq";
import { ButtonsActionCell } from "../../../../../components/TableComponents/ActionCell";
import { CheckboxCell, EditableCell } from "../../../../../components/TableComponents/DefaultCell";

export default {
  columns: [
    {
      Header: "coupon code",
      accessor: "code",
      Cell: ({ row, value, column, handleUpdateRow }) => (
        <EditableCell
          row={row}
          initialValue={value}
          column={column}
          updateMyData={handleUpdateRow}
        />
      ),
    },
    {
      Header: "amount",
      accessor: "amount",
      width: "70px",
      Cell: ({ row, value, column, handleUpdateRow }) => (
        <EditableCell
          row={row}
          initialValue={value}
          column={column}
          updateMyData={handleUpdateRow}
          type="number"
        />
      ),
    },
    {
      Header: "",
      accessor: "type",
      width: "70px",
      Cell: ({ row, value, column, handleUpdateRow }) => (
        <EditableCell
          row={row}
          initialValue={value}
          column={column}
          updateMyData={handleUpdateRow}
          type="dropdown"
          options={[
            { label: "%", value: "%" },
            { label: "€", value: "€" },
          ]}
          styleProps={{ fontWeight: "bold", variant: "subtitle2" }}
        />
      ),
    },
    // {
    //   Header: "",
    //   accessor: "has_quantity",
    //   width: "24px",
    //   disableSortBy: true,
    //   Cell: ({ value, row, column, handleUpdateRow }) => (
    //     <CheckboxCell value={value} row={row} column={column} updateValue={handleUpdateRow} />
    //   ),
    // },
    {
      Header: "Quantity",
      columns: [
        {
          accessor: "has_quantity",
          width: "24px",
          disableSortBy: true,
          Cell: ({ value, row, column, handleUpdateRow }) => (
            <CheckboxCell value={value} row={row} column={column} updateValue={handleUpdateRow} />
          ),
        },
        {
          accessor: "quantity",
          width: "100px",
          Cell: ({ row, value, column, handleUpdateRow }) => (
            <EditableCell
              row={row}
              initialValue={value}
              column={column}
              updateMyData={handleUpdateRow}
              type="number"
            />
          ),
        },
      ],
      // accessor: "quantity",
      // width: "100px",
      // Cell: ({ row, value, column, handleUpdateRow }) => (
      //   <EditableCell
      //     row={row}
      //     initialValue={value}
      //     column={column}
      //     updateMyData={handleUpdateRow}
      //     type="number"
      //   />
      // ),
    },
    {
      Header: "status",
      accessor: "active",
      width: "100px",
      Cell: ({ row, value, column, handleUpdateRow }) => (
        <EditableCell
          row={row}
          initialValue={value}
          column={column}
          updateMyData={handleUpdateRow}
          type="dropdown"
          options={[
            { label: "inactive", value: "inactive" },
            { label: "active", value: "active" },
          ]}
          styleProps={{ textColor: value === "Active" ? "success" : "error" }}
        />
      ),
    },
    {
      Header: "usage",
      accessor: "once",
      width: "100px",
      Cell: ({ row, value, column, handleUpdateRow }) => (
        <EditableCell
          row={row}
          initialValue={value}
          column={column}
          updateMyData={handleUpdateRow}
          type="dropdown"
          options={[
            { label: "unlimited", value: "unlimited" },
            { label: "once", value: "once" },
          ]}
          styleProps={{ textColor: value?.toLowerCase() === "unlimited" ? "success" : "warning" }}
        />
      ),
    },
    {
      Header: "description",
      accessor: "description",
      Cell: ({ row, value, column, handleUpdateRow }) => (
        <EditableCell
          row={row}
          initialValue={value}
          column={column}
          updateMyData={handleUpdateRow}
        />
      ),
    },
    {
      Header: "",
      width: "120px",
      id: "actions",
      Cell: ({ handleSaveRow, row, state }) => (
        <ButtonsActionCell row={row} handleSaveRow={handleSaveRow} state={state} />
      ),
    },
  ],

  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
};
