/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Settings page components

// Data
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import initialValues from "./schemas/initialValues";
import SuiButton from "../../../../components/SuiButton";
import FormField, { DropdownFormField, TextAreaFormField } from "../../../../components/FormField";
import form from "./schemas/form";
import FormDropzone from "../../../../components/FormDropzone";
import { createUser, getUser, updateUser } from "../../../../redux/reducers/usersSlice";
import schema from "./schemas/validation";
import useToastContext from "../../../../context/ToastContext";

const roles = [
  { label: "Super Admin", value: "super_admin" },
  { label: "Admin", value: "admin" },
  { label: "Editor", value: "editor" },
  { label: "Members Admin", value: "members_admin" },
  { label: "Shop Admin", value: "shop_admin" },
  { label: "Events Admin", value: "events_admin" },
  { label: "User", value: "user" },
];

function UserPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const showToast = useToastContext();

  const user = useSelector((state) => state.authReducer.user);
  const [data, setData] = useState({});
  const [isNewUser, setIsNewUser] = useState(false);
  const [error, setError] = useState("");
  const { formId, formField } = form;

  useEffect(() => {
    if (location.pathname.includes("profile") && user) {
      setData(user);
    } else if (location.pathname.includes("edit-user")) {
      const id = location.pathname.replace("/edit-user/", "");
      dispatch(getUser(id))
        .unwrap()
        .then((res) => setData(res.data.data));
    } else {
      setData(initialValues);
      setIsNewUser(true);
    }
  }, [location, history, user]);

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      if (isNewUser) {
        await dispatch(createUser(values)).unwrap();
      } else {
        await dispatch(updateUser(values)).unwrap();
      }
      showToast({
        type: "success",
        title: "User saved successfully!",
      });
      history.push("/users");
    } catch (e) {
      setError(e.message || e);
    } finally {
      actions.setSubmitting(false);
    }

    // history.push()
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="basic-info" className="overflow-visible">
        <SuiBox p={3}>
          <SuiTypography variant="h5">{isNewUser ? "New User" : "User Info"}</SuiTypography>
        </SuiBox>
        <Formik
          enableReinitialize
          initialValues={data}
          onSubmit={onSubmit}
          validationSchema={isNewUser ? schema : undefined}
        >
          {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
            <Form id={formId}>
              <SuiBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Grid item md={6}>
                      <FormDropzone
                        value={values?.avatar}
                        getImageUrl={(e) => setFieldValue("avatar", e)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField label="name" name={formField?.name?.name} placeholder="Name" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="username"
                      placeholder="Username"
                      name={formField?.username?.name}
                      disabled={!isNewUser}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="email"
                      placeholder="example@email.com"
                      inputProps={{ type: "email" }}
                      name={formField?.email?.name}
                      disabled={!isNewUser}
                    />
                  </Grid>
                  {isNewUser ? (
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="confirmation email"
                        placeholder="example@email.com"
                        inputProps={{ type: "email" }}
                        name={formField?.confirmEmail?.name}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="password"
                      placeholder="Password"
                      name={formField?.password?.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DropdownFormField
                      // isDisabled={!isNewUser}
                      options={roles}
                      setValue={(e) => setFieldValue("role", e.value)}
                      label="role"
                      name={formField?.role?.name}
                      value={values.role}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextAreaFormField label="description" name={formField?.description?.name} />
                  </Grid>
                </Grid>
                <SuiTypography component="div" variant="caption" textColor="error">
                  {error}
                </SuiTypography>
                <SuiBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                  <SuiButton
                    disabled={isSubmitting}
                    variant="gradient"
                    buttonColor="dark"
                    onClick={handleSubmit}
                  >
                    save
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
}

export default UserPage;
